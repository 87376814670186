import React, { useState, useEffect } from "react";
import { fetchPrescriptions, fetchPrescriptionRequests, generateDownloadLink } from "../services/api";
import { formatDate, filterAndSortData } from "../utils/helpers";
import { Link } from "react-router-dom";
import AdvancedPrescriptionsTable from "../components/Tables/AdvancedPrescriptionsTable";
import AdvancedPrescriptionRequestsTable from "../components/Tables/AdvancedPrescriptionRequestsTable";

const Prescriptions = ({ token }) => {
  const [activeTab, setActiveTab] = useState("prescriptions");
  const [prescriptions, setPrescriptions] = useState([]);
  const [prescriptionRequests, setPrescriptionRequests] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortField, setSortField] = useState("date");
  const [sortOrder, setSortOrder] = useState("desc");

  useEffect(() => {
    if (activeTab === "prescriptions") {
      const getPrescriptions = async () => {
        try {
          const response = await fetchPrescriptions(token);
          setPrescriptions(response.data);
        } catch (err) {
          console.error(err);
          setError("Daten konnten nicht abgerufen werden.");
        } finally {
          setIsLoading(false);
        }
      };
      getPrescriptions();
    }
  }, [token, activeTab]);


  useEffect(() => {
    if (activeTab === "prescription-requests") {
      const getPrescriptionRequests = async () => {
        try {
          const response = await fetchPrescriptionRequests(token);
          setPrescriptionRequests(response.data.prescriptionRequests);
        } catch (err) {
          console.error(err);
          setError("Daten konnten nicht abgerufen werden.");
        } finally {
          setIsLoading(false);
        }
      };
      getPrescriptionRequests();
    }
  }, [token, activeTab]);


  const handleDownload = async (path) => {
    try {
      const downloadLink = await generateDownloadLink(path, token);
      window.open(downloadLink, "_blank");
    } catch (err) {
      console.error("Download error:", err.message);
      alert("Fehler beim Herunterladen.");
    }
  };

  const filteredAndSortedPrescriptions = () => {
    return filterAndSortData({
      data: { prescriptions },
      type: "prescriptions",
      searchQuery,
      sortField,
      sortOrder,
      formatDate,
    });
  };

  const renderSkeleton = (rows, columns) =>
    Array.from({ length: rows }).map((_, rowIndex) => (
      <tr key={rowIndex}>
        {Array.from({ length: columns }).map((_, colIndex) => (
          <td key={colIndex} className="px-6 py-4 whitespace-nowrap">
            <div className="h-4 bg-gray-300 rounded animate-pulse"></div>
          </td>
        ))}
      </tr>
    ));

  if (error) return <div className="text-red-600">{error}</div>;

  return (
    <div className="content-container">
      <div className="page-title my-6 grid gap-3 md:flex md:justify-between md:items-center">
        <div>
          <h2 className="text-xl font-semibold text-gray-800">Ihre Rezeptübersicht</h2>
          <p className="text-gray-500 text-sm">Verwalten Sie Ihre Rezepte und fragen Sie Folgerezepte an.</p>
        </div>
        <div>
          <Link
              to="/session/new"
              className="py-2 px-3 inline-flex items-center gap-x-2 text-xs font-medium rounded border border-transparent bg-gray-800 text-white shadow-sm hover:bg-gray-700 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-700 dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
            >
            <svg
              className="shrink-0 size-4"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M5 12h14" />
              <path d="M12 5v14" />
            </svg>
            Rezept anfragen
          </Link>
        </div>
      </div>

      {/* Tab-Navigation */}
      <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700 -mx-14 mb-6">
        <ul className="px-14 flex flex-wrap -mb-px">
          <li className="me-2">
            <button
              className={`inline-block p-4 border-b-2 rounded-t-lg ${
                activeTab === "prescriptions"
                  ? "text-gray-800 border-gray-800"
                  : "border-transparent hover:text-gray-600 hover:border-gray-300"
              }`}
              onClick={() => setActiveTab("prescriptions")}
            >
              Verlauf
            </button>
          </li>
          <li className="me-2">
            <button
              className={`inline-block p-4 border-b-2 rounded-t-lg ${
                activeTab === "prescription-requests"
                  ? "text-gray-800 border-gray-800"
                  : "border-transparent hover:text-gray-600 hover:border-gray-300"
              }`}
              onClick={() => setActiveTab("prescription-requests")}
            >
              Rezeptanfragen
            </button>
          </li>
        </ul>
      </div>

      {/* Inhalt der Tabs */}
      {activeTab === "prescriptions" && (
        <AdvancedPrescriptionsTable
          isLoading={isLoading}
          prescriptions={prescriptions}
          renderSkeleton={renderSkeleton}
          formatDate={formatDate}
          handleDownload={handleDownload}
        />
      )}
      {activeTab === "prescription-requests" && (
        <AdvancedPrescriptionRequestsTable
          isLoading={isLoading}
          prescriptionRequests={prescriptionRequests}
          renderSkeleton={renderSkeleton}
          formatDate={formatDate}
        />
      )}
    </div>
  );
};

export default Prescriptions;