import React, { useState, useEffect } from "react";
import { fetchSessions, fetchPatientDetails } from "../services/api";
import { filterAndSortData, formatDate } from "../utils/helpers";
import { sessionTypeMap } from "../utils/sessionTypeMap";

import { Link } from "react-router-dom";
import AdvancedSessionsTable from "../components/Tables/AdvancedSessionsTable";
import AdvancedUpcomingSessionsTable from "../components/Tables/AdvancedUpcomingSessionsTable";
import SessionRequestForm from "../components/Forms/SessionRequestForm";

const Sessions = ({ token }) => {
  const [activeTab, setActiveTab] = useState("sessions");
  const [sessions, setSessions] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortField, setSortField] = useState("date");
  const [sortOrder, setSortOrder] = useState("desc");
  const [patientDetails, setPatientDetails] = useState({});
  const [activeModal, setActiveModal] = useState(null);

  useEffect(() => {
    if (activeTab === "sessions") {
      const getSessions = async () => {
        try {
          const response = await fetchSessions(token);
          setSessions(response.data);
        } catch (err) {
          console.error(err);
          setError("Daten konnten nicht abgerufen werden.");
        } finally {
          setIsLoading(false);
        }
      };
      getSessions();
    }
  }, [token, activeTab]);

  const openModal = (modalType) => {
    setActiveModal(modalType);
  };

  const closeModal = () => {
    setActiveModal(null);
  };

  const handleFormSubmit = (formData) => {
    console.log("Formular abgeschickt:", formData);
    closeModal();
  };

  useEffect(() => {
    const loadPatientDetails = async () => {
      try {
        setIsLoading(true);
        const response = await fetchPatientDetails();
        if (response && response.data) {
          setPatientDetails(response.data); // Hier sicherstellen, dass `setPatientDetails` richtig gesetzt wird
        }
      } catch (error) {
        console.error("Fehler beim Laden der Patientendetails:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadPatientDetails();
  }, []);

  const filteredAndSortedSessions = () => {
    return filterAndSortData({
      data: { sessions },
      type: "sessions",
      searchQuery,
      sortField,
      sortOrder,
      formatDate,
    });
  };

  const renderSkeleton = (rows, columns) =>
    Array.from({ length: rows }).map((_, rowIndex) => (
      <tr key={rowIndex}>
        {Array.from({ length: columns }).map((_, colIndex) => (
          <td key={colIndex} className="px-6 py-4 whitespace-nowrap">
            <div className="h-4 bg-gray-300 rounded animate-pulse"></div>
          </td>
        ))}
      </tr>
    ));

  if (error) return <div className="text-red-600">{error}</div>;

  return (
    <div className="content-container">
      <div className="page-title my-6 grid gap-3 md:flex md:justify-between md:items-center">
        <div>
          <h2 className="text-xl font-semibold text-gray-800">Ihre Terminübersicht</h2>
          <p className="text-gray-500 text-sm">Hier finden Sie alle stattgehabten Sprechstunden und Ihre zukünftigen Termine.</p>
        </div>
        <div>
            <button
              onClick={() => openModal("session")}
              className="py-2 px-3 inline-flex items-center gap-x-2 text-xs font-medium rounded border border-transparent bg-gray-800 text-white shadow-sm hover:bg-gray-700 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-700 dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
            >
              <svg
                className="shrink-0 size-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M5 12h14" />
                <path d="M12 5v14" />
              </svg>
              Termin buchen
            </button>
          </div>
      </div>

      {/* Tab-Navigation */}
      <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700 -mx-14 mb-6">
        <ul className="px-14 flex flex-wrap -mb-px">
          <li className="me-2">
            <button
              className={`inline-block p-4 border-b-2 rounded-t-lg ${
                activeTab === "sessions"
                  ? "text-gray-800 border-gray-800"
                  : "border-transparent hover:text-gray-600 hover:border-gray-300"
              }`}
              onClick={() => setActiveTab("sessions")}
            >
              Verlauf
            </button>
          </li>
          <li className="me-2">
            <button
              className={`inline-block p-4 border-b-2 rounded-t-lg ${
                activeTab === "bookings"
                  ? "text-gray-800 border-gray-800"
                  : "border-transparent hover:text-gray-600 hover:border-gray-300"
              }`}
              onClick={() => setActiveTab("bookings")}
            >
              Terminbuchungen
            </button>
          </li>
        </ul>
      </div>



    {activeModal === "session" && (
      <div className="fixed flex items-center justify-center bg-black bg-opacity-50 z-[100] overflow-y-auto overflow-x-hidden top-0 right-0 left-0 w-full md:inset-0 h-[calc(100%-1rem)] h-full">
        <div className="relative p-4 w-full max-w-4xl max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h2 className="text-xl font-semibold text-gray-900">Terminbuchung</h2>
              <button
                onClick={closeModal}
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
              </button>
            </div>
            <div className="p-4 md:p-5 space-y-4">
              <SessionRequestForm
                    onClose={closeModal} // Ändere handleClose zu closeModal
                    onSubmit={handleFormSubmit}
                    apiKey="3zELzqNcaTPnthtcFyDQrb2oZicgVjSVrw4X82bjnP7BB97e"
                    patientDetails={{
                      fullName: patientDetails?.fullName || "Unbekannt", // Falls fullName fehlt, Standardwert "Unbekannt"
                      email: patientDetails?.email || "Unbekannt", // Falls email fehlt, Standardwert "Unbekannt"
                    }} // E-Mail wird an das Formular übergeben
                  />
            </div>
          </div>
        </div>
      </div>
    )}

      {/* Inhalt der Tabs */}
      {activeTab === "sessions" ? (
        <AdvancedSessionsTable
          isLoading={isLoading}
          sessions={sessions}
          formatDate={formatDate}
          sessionTypeMap={sessionTypeMap}
          searchQuery={searchQuery}
          sortField={sortField}
          sortOrder={sortOrder}
          setSortField={setSortField}
          setSortOrder={setSortOrder}
          filteredAndSortedSessions={filteredAndSortedSessions}
          renderSkeleton={renderSkeleton}
        />
      ) : (
        <AdvancedUpcomingSessionsTable
          isLoading={isLoading}
          formatDate={formatDate}
          sessionTypeMap={sessionTypeMap}
          searchQuery={searchQuery}
          sortField={sortField}
          sortOrder={sortOrder}
          setSortField={setSortField}
          setSortOrder={setSortOrder}
          renderSkeleton={renderSkeleton}
          clientEmail={patientDetails.email}
        />
      )}
    </div>

  );
};
export default Sessions;