import React from "react";
import { Link } from "react-router-dom";

const InvoicesTable = ({
  isLoading,
  invoices,
  renderSkeleton,
  formatDate,
  generateInvoiceNumber,
  extractAmount,
  handleDownload,
}) => {

  // Limit the number of displayed entries
  const limitedInvoices = invoices.slice(0, 4);

  return (

<div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden">
  <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-neutral-700">
    <div>
      <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200 inline-flex items-center gap-x-2">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"#000000"} fill={"none"}>
            <path d="M12.5 22L10.7273 22C7.46607 22 5.83546 22 4.70307 21.2022C4.37862 20.9736 4.09058 20.7025 3.8477 20.3971C3 19.3313 3 17.7966 3 14.7273L3 12.1818C3 9.21865 3 7.73706 3.46894 6.55375C4.22282 4.65142 5.81714 3.15088 7.83836 2.44135C9.09563 2 10.6698 2 13.8182 2C15.6173 2 16.5168 2 17.2352 2.2522C18.3902 2.65765 19.3013 3.5151 19.732 4.60214C20 5.27832 20 6.12494 20 7.81818L20 10.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3 12C3 10.159 4.49238 8.66667 6.33333 8.66667C6.99912 8.66667 7.78404 8.78333 8.43137 8.60988C9.00652 8.45576 9.45576 8.00652 9.60988 7.43136C9.78333 6.78404 9.66667 5.99912 9.66667 5.33333C9.66667 3.49238 11.1591 2 13 2" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M21 20.4923C20.5216 21.3957 19.6512 22 18.6568 22C17.147 22 15.9231 20.6071 15.9231 18.8889V17.1111C15.9231 15.3929 17.147 14 18.6568 14C19.6512 14 20.5216 14.6043 21 15.5077M15 18H18.9231" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
        </svg>
        Rechnungen
      </h2>
      <p className="text-sm text-gray-600 dark:text-neutral-400">
        Ihre letzten Rechnungen
      </p>
    </div>

    <div>
      <div className="inline-flex gap-x-2">
        <Link
          to="/invoices"
          className="py-2 px-3 inline-flex items-center gap-x-2 text-xs font-medium rounded border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
        >
          Alle anzeigen
        </Link>
      </div>
    </div>
  </div>
  <table className="min-w-full divide-y divide-gray-200">
    <thead className="bg-gray-50">
      <tr>
        <th scope="col" className="px-6 py-3 text-start">
          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800">Rechnungs-Nr.</span>
        </th>
        <th scope="col" className="px-6 py-3 text-start">
          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800">Datum</span>
        </th>
        <th scope="col" className="px-6 py-3 text-start">
          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800">Betrag</span>
        </th>
        <th scope="col" className="px-6 py-3 text-start">
          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800">Fällig</span>
        </th>
        <th scope="col" className="px-6 py-3 text-start">
          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800">Status</span>
        </th>                
        <th scope="col" className="px-6 py-3 text-end">
          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800"></span>
        </th>
      </tr>
    </thead>
    <tbody className="divide-y divide-gray-200">
      {isLoading ? (
        renderSkeleton(3, 5)
      ) : limitedInvoices.length > 0 ? (
        limitedInvoices.map((item, index) => {
          const invoiceDateStr = formatDate(item.name);
          const [day, month, year] = invoiceDateStr.split(".");
          const invoiceDate = new Date(`${year}-${month}-${day}`);
          const dueDate = new Date(invoiceDate);
          dueDate.setDate(dueDate.getDate() + 14); // Calculate due date
          const formattedDueDate = dueDate.toLocaleDateString();

          return (
        <tr key={index}>
          <td className="px-6 py-2 align-middle whitespace-nowrap">
            <span className="text-sm text-gray-600">{generateInvoiceNumber(item.name)}</span>
          </td>
          <td className="px-6 py-2 align-middle whitespace-nowrap">
            <span className="text-sm text-gray-600">{formatDate(item.name)}</span>
          </td>
          <td className="px-6 py-2 align-middle whitespace-nowrap">
            <span className="text-sm text-gray-600">{extractAmount(item.name)}</span>
          </td>
          <td className="px-6 py-2 align-middle whitespace-nowrap">
            <span className="text-sm text-gray-600">{formattedDueDate}</span>
          </td>
          <td className="px-6 py-2 align-middle whitespace-nowrap">
            <span
              className={`py-1 px-2 inline-flex items-center gap-x-2 text-xs font-medium rounded-full ${
                item.status === "Unbezahlt" ? "bg-gray-100 text-red-800" : "bg-gray-100 text-primary-800"
              }`}
            >
              <div
                className={`flex items-center justify-center w-3 h-3 p-0.5 rounded-full ${
                  item.status === "Unbezahlt" ? "bg-red-800" : "bg-primary-800"
                }`}
              >
                {item.status === "Unbezahlt" ? (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#ffffff"} fill={"none"}>
                    <path d="M19.0005 4.99988L5.00049 18.9999M5.00049 4.99988L19.0005 18.9999" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#ffffff"} fill={"none"}>
                    <path d="M5 14L8.5 17.5L19 6.5" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                )}
              </div>
              {item.status}
            </span>
          </td>
          <td className="px-6 py-2 align-middle whitespace-nowrap">
            <button
              onClick={() => handleDownload(item.path)}
              className="py-1 px-2 align-middle inline-flex items-center gap-x-2 text-xs font-medium rounded border border-gray-200 bg-white text-gray-800 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
              >
              <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 24 24" width={20} height={20} color={"currentColor"} fill={"none"}>
                  <path d="M12 14.5L12 4.5M12 14.5C11.2998 14.5 9.99153 12.5057 9.5 12M12 14.5C12.7002 14.5 14.0085 12.5057 14.5 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M20 16.5C20 18.982 19.482 19.5 17 19.5H7C4.518 19.5 4 18.982 4 16.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          </td>
        </tr>
        );
      })
      ) : (
        <tr>
          <td colSpan="6" className="text-center p-2">
            <p className="text-sm text-gray-600 dark:text-neutral-400">Keine Rechnungen gefunden.</p>
          </td>
        </tr>
      )}
    </tbody>
  </table>
</div>


  );
};

export default InvoicesTable;