import React, { useState, useEffect } from "react";
import { fetchInvoices, generateDownloadLink } from "../services/api";
import { formatDate, extractAmount, generateInvoiceNumber, filterAndSortData } from "../utils/helpers";
import AdvancedInvoicesTable from "../components/Tables/AdvancedInvoicesTable";
import InvoiceModal from "../components/Modals/InvoiceModal";
import PaymentModal from "../components/Modals/PaymentModal"; // Import the new modal

const Invoices = ({ token }) => {
  const [invoices, setInvoices] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false); // State for payment modal
  const [selectedPaymentInvoice, setSelectedPaymentInvoice] = useState(null); // Invoice for payment modal
  
  useEffect(() => {
    const getInvoices = async () => {
      try {
        const response = await fetchInvoices(token);
        setInvoices(response.data.invoices);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };
    getInvoices();
  }, [token]);

  const handleDownload = async (path) => {
    try {
      const downloadLink = await generateDownloadLink(path, token);
      window.open(downloadLink, "_blank");
    } catch (err) {
      console.error("Download error:", err.message);
      alert("Fehler beim Herunterladen.");
    }
  };

  const renderSkeleton = (rows, columns) => (
    Array.from({ length: rows }).map((_, rowIndex) => (
      <tr key={rowIndex}>
        {Array.from({ length: columns }).map((_, colIndex) => (
          <td key={colIndex} className="px-6 py-4 whitespace-nowrap">
            <div className="h-4 bg-gray-300 rounded animate-pulse"></div>
          </td>
        ))}
      </tr>
    ))
  );

  return (

  <div className="content-container">
  
    <div className="page-title my-6">
      <h2 className="text-xl font-semibold text-gray-800">Ihre Rechnungsübersicht</h2>
      <p className="text-gray-500 text-sm">Behalten Sie den Überblick über alle Rechnungen und Zahlungen.</p>
    </div>

      <AdvancedInvoicesTable
        isLoading={isLoading}
        invoices={invoices}
        renderSkeleton={renderSkeleton}
        formatDate={formatDate}
        extractAmount={extractAmount}
        generateInvoiceNumber={generateInvoiceNumber}
        handleDownload={handleDownload}
        setSelectedInvoice={setSelectedInvoice}
        setSelectedPaymentInvoice={setSelectedPaymentInvoice} // Pass setter for payment modal
        setIsPaymentModalOpen={setIsPaymentModalOpen} // Pass setter for payment modal visibility
      />
      {selectedInvoice && (
        <InvoiceModal
          invoice={selectedInvoice}
          onClose={() => setSelectedInvoice(null)}
        />
      )}
      {isPaymentModalOpen && selectedPaymentInvoice && (
        <PaymentModal
          invoice={selectedPaymentInvoice}
          isOpen={isPaymentModalOpen}
          onClose={() => {
            setSelectedPaymentInvoice(null);
            setIsPaymentModalOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default Invoices;