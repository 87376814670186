import React from "react";
import { Link } from "react-router-dom";

const SessionsTable = ({
  isLoading,
  sessions,
  renderSkeleton,
  formatDate,
  sessionTypeMap,
}) => {

  // Limit the number of displayed entries
  const limitedSessions = sessions.slice(0, 4);

  return (
   
      <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden">
        <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-neutral-700">
          <div>
            <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200 inline-flex items-center gap-x-2">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
                  <path d="M16 2V5M6 2V5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M20 13V11.5C20 7.72876 20 5.84315 18.8284 4.67157C17.6569 3.5 15.7712 3.5 12 3.5H10C6.22876 3.5 4.34315 3.5 3.17157 4.67157C2 5.84315 2 7.72876 2 11.5V14C2 17.7712 2 19.6569 3.17157 20.8284C4.34315 22 6.22876 22 10 22H11.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M14 19.5C14 19.5 15.3485 20.0067 16 22C16 22 19.1765 17 22 16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M2.5 8.5H19.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              Termine
            </h2>
            <p className="text-sm text-gray-600 dark:text-neutral-400">
              Ihre letzten Sprechstunden
            </p>
          </div>

          <div>
            <div className="inline-flex gap-x-2">
              <Link
                to="/sessions"
                className="py-2 px-3 inline-flex items-center gap-x-2 text-xs font-medium rounded border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
              >
                Alle anzeigen
              </Link>
            </div>
          </div>
        </div>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-start">
                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800">Datum</span>
              </th>
              <th scope="col" className="px-6 py-3 text-start">
                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800">Termin</span>
              </th>
              <th scope="col" className="px-6 py-3 text-start">
                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800">Status</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
              {isLoading ? (
                renderSkeleton(3, 3)
              ) : limitedSessions.length > 0 ? (
                limitedSessions.map((item, index) => (
                <tr key={index}>
                  <td className="px-6 py-2 align-middle whitespace-nowrap">
                    <span className="text-sm text-gray-600">{formatDate(item.name)}</span>
                  </td>
                  <td className="px-6 py-2 align-middle whitespace-nowrap">
                    <span className="text-sm text-gray-600">
                      {sessionTypeMap[item.name.split(" ")[1]] || item.name.split(" ")[1]}
                    </span>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    <span
                      className={`py-1 px-2 inline-flex items-center gap-x-2 text-xs font-medium rounded-full ${
                        item.name.split(" ")[1] === "AUS" ? "bg-gray-100 text-red-800" : "bg-gray-100 text-primary-800"
                      }`}
                    >
                      <div
                        className={`flex items-center justify-center w-3 h-3 p-0.5 rounded-full ${
                          item.name.split(" ")[1] === "AUS" ? "bg-red-800" : "bg-primary-800"
                        }`}
                      >
                        {item.name.split(" ")[1] === "AUS" ? (
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#ffffff"} fill={"none"}>
                              <path d="M19.0005 4.99988L5.00049 18.9999M5.00049 4.99988L19.0005 18.9999" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        ) : (
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#ffffff"} fill={"none"}>
                              <path d="M5 14L8.5 17.5L19 6.5" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        )}
                      </div>
                      {item.name.split(" ")[1] === "AUS" ? "Nicht erfolgt" : "Erfolgt"}
                    </span>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3" className="text-center p-2">
                  <p className="text-sm text-gray-600 dark:text-neutral-400">Keine Termine gefunden.</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>


  );
};

export default SessionsTable;