// Dateien: src/pages/Files.js

import React, { useEffect, useState } from "react";
import { fetchPatientFiles, uploadPatientFile } from "../services/api";
import UploadModal from "../components/Modals/UploadModal";

// Hilfsfunktion zur Datumsformatierung
const formatDate = (dateString) => {
  if (!dateString) return "Unbekannt";

  const date = new Date(dateString);
  if (isNaN(date)) return "Unbekannt";

  const optionsDate = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };
  const optionsTime = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };

  const formattedDate = date.toLocaleDateString('de-DE', optionsDate);
  const formattedTime = date.toLocaleTimeString('de-DE', optionsTime);

  return `${formattedDate}, ${formattedTime} Uhr`;
};

const Files = () => {
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  // Funktion zum Abrufen der Dateien
  const fetchFiles = async () => {
    try {
      setIsLoading(true);
      const response = await fetchPatientFiles();
      setFiles(response.data.patientFiles || []);
    } catch (err) {
      setError("Fehler beim Abrufen der Dateien.");
    } finally {
      setIsLoading(false);
    }
  };

  // Dateien einmalig beim Initialisieren laden
  useEffect(() => {
    fetchFiles(); // Initiales Laden
  }, []);

  // Upload-Funktion
  const handleUpload = async (formData) => {
    try {
      await uploadPatientFile(formData); // Datei hochladen
      fetchFiles(); // Dateien neu laden
    } catch (err) {
      console.error("Fehler beim Hochladen der Datei:", err);
    }
  };

  // Render-Funktion für Skelettzeilen
  const renderSkeleton = (rows, columns) => {
    return Array.from({ length: rows }).map((_, rowIndex) => (
      <tr key={rowIndex} className="animate-pulse">
        {Array.from({ length: columns }).map((_, colIndex) => (
          <td key={colIndex} className="px-6 py-3">
            <div className="h-4 bg-gray-200 rounded w-full"></div>
          </td>
        ))}
      </tr>
    ));
  };

  return (
    <div className="content-container">
      <div className="page-title my-6 grid gap-3 md:flex md:justify-between md:items-center">
        <div>
          <h2 className="text-xl font-semibold text-gray-800">Ihre Dateien</h2>
          <p className="text-gray-500 text-sm">
            Hier können Sie alle relevanten Dateien einsehen und neue hochladen.
          </p>
        </div>
        <div>
          <button
            onClick={() => setModalOpen(true)}
            className="py-2 px-3 inline-flex items-center gap-x-2 text-xs font-medium rounded border border-transparent bg-gray-800 text-white shadow-sm hover:bg-gray-700 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-700 dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
          >
            <svg
              className="shrink-0 size-4"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M5 12h14" />
              <path d="M12 5v14" />
            </svg>
            Datei hochladen
          </button>
        </div>
      </div>

      <UploadModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onUpload={handleUpload}
      />

      <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-x-hidden">
        <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200">
          <div>
            <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200 inline-flex items-center gap-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
                <path d="M16.2627 10.5H7.73725C5.15571 10.5 3.86494 10.5 3.27143 11.3526C2.67793 12.2052 3.11904 13.4258 4.00126 15.867L5.08545 18.867C5.54545 20.1398 5.77545 20.7763 6.2889 21.1381C6.80235 21.5 7.47538 21.5 8.82143 21.5H15.1786C16.5246 21.5 17.1976 21.5 17.7111 21.1381C18.2245 20.7763 18.4545 20.1398 18.9146 18.867L19.9987 15.867C20.881 13.4258 21.3221 12.2052 20.7286 11.3526C20.1351 10.5 18.8443 10.5 16.2627 10.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="square" />
                <path d="M19 8C19 7.53406 19 7.30109 18.9239 7.11732C18.8224 6.87229 18.6277 6.67761 18.3827 6.57612C18.1989 6.5 17.9659 6.5 17.5 6.5H6.5C6.03406 6.5 5.80109 6.5 5.61732 6.57612C5.37229 6.67761 5.17761 6.87229 5.07612 7.11732C5 7.30109 5 7.53406 5 8" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M16.5 4C16.5 3.53406 16.5 3.30109 16.4239 3.11732C16.3224 2.87229 16.1277 2.67761 15.8827 2.57612C15.6989 2.5 15.4659 2.5 15 2.5H9C8.53406 2.5 8.30109 2.5 8.11732 2.57612C7.87229 2.67761 7.67761 2.87229 7.57612 3.11732C7.5 3.30109 7.5 3.53406 7.5 4" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
              Dateien
            </h2>
            <p className="text-sm text-gray-600">Alle hochgeladenen Dateien</p>
          </div>
        </div>

        {/* Table */}
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-start">
                  <span className="text-xs font-semibold uppercase tracking-wide text-gray-800">
                    Dateiname
                  </span>
                </th>
                <th className="px-6 py-3 text-start">
                  <span className="text-xs font-semibold uppercase tracking-wide text-gray-800">
                    Typ
                  </span>
                </th>
                <th className="px-6 py-3 text-start">
                  <span className="text-xs font-semibold uppercase tracking-wide text-gray-800">
                    Größe
                  </span>
                </th>
                <th className="px-6 py-3 text-start">
                  <span className="text-xs font-semibold uppercase tracking-wide text-gray-800">
                    Hochgeladen am
                  </span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {isLoading
                ? renderSkeleton(3, 4) // Zeige 3 Zeilen mit 4 Spalten als Skeleton
                : files.length > 0
                ? files.map((file, index) => (
                    <tr key={index}>
                      <td className="px-6 py-3 text-sm text-gray-800">
                        {file.name || "Kein Name"}
                      </td>
                      <td className="px-6 py-3 text-sm text-gray-800">
                        {file.type === "BE"
                          ? "Befund"
                          : file.type === "UN"
                          ? "Einverständniserklärung"
                          : file.type === "AN"
                          ? "Behandlungsanfrage"
                          : "Unbekannt"}
                      </td>
                      <td className="px-6 py-3 text-sm text-gray-800">
                        {file.size
                          ? file.size < 1024
                            ? file.size + " Bytes"
                            : file.size < 1024 * 1024
                            ? (file.size / 1024).toFixed(2) + " KB"
                            : (file.size / (1024 * 1024)).toFixed(2) + " MB"
                          : "Unbekannt"}
                      </td>
                      <td className="px-6 py-3 text-sm text-gray-800">
                        {formatDate(file.uploadedAt) || "Unbekannt"}
                      </td>
                    </tr>
                  ))
                : !error && (
                  <tr>
                    <td colSpan="4" className="text-center p-2">
                      <div className="max-w-sm w-full min-h-[250px] flex flex-col items-center justify-center mx-auto px-6 py-4">
                        <div className="flex justify-center items-center size-[46px] bg-gray-100 rounded-lg dark:bg-neutral-800">
                          <svg className="shrink-0 size-6 text-gray-600 dark:text-neutral-400" xmlns="http://www.w3.org/2000/svg" width="24" height="24" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="transparent" viewBox="0 0 24 24">
                            <path d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z" stroke="currentColor" strokeWidth="1.5" />
                            <path d="M12.2422 17V12C12.2422 11.5286 12.2422 11.2929 12.0957 11.1464C11.9493 11 11.7136 11 11.2422 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M11.992 8H12.001" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </div>

                        <h2 className="mt-5 font-semibold text-lg text-gray-800 dark:text-white">Keine Dateien gefunden</h2>
                        <p className="mt-2 text-sm text-gray-600 dark:text-neutral-400">Wir konnten keine Dateien finden, die den Suchkriterien entsprechen.</p>

                        <div className="mt-5 flex flex-col sm:flex-row gap-2">
                          <button
                            type="button"
                            className="py-2 px-3 inline-flex items-center gap-x-2 text-xs font-medium rounded border border-transparent bg-gray-800 text-white shadow-sm hover:bg-gray-700 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-700 dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                            onClick={() => setModalOpen("UploadModal")}
                          >
                            <svg className="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                              <path d="M5 12h14"/><path d="M12 5v14"/>
                            </svg>
                            Datei hochladen
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                  )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Files;