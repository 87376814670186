import React, { useState } from "react";
import { Link } from "react-router-dom";
import { extractAmount, formatDate, generateInvoiceNumber, filterAndSortData } from "../../utils/helpers";

const ITEMS_PER_PAGE = 10;

const AdvancedInvoicesTable = ({
  isLoading,
  invoices,
  renderSkeleton,
  formatDate,
  extractAmount,
  generateInvoiceNumber,
  handleDownload,
  setSelectedInvoice,
  setSelectedPaymentInvoice, // Add setter for payment modal
  setIsPaymentModalOpen, // Add setter for payment modal visibility
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [sortField, setSortField] = useState("date");
  const [sortOrder, setSortOrder] = useState("desc");
  const [currentPage, setCurrentPage] = useState(1); // Aktuelle Seite

  const filteredAndSortedInvoices = () => {
    return filterAndSortData({
      data: { invoices },
      type: "invoices",
      searchQuery,
      sortField,
      sortOrder,
      formatDate,
      extractAmount,
      generateInvoiceNumber,
    });
  };

  const filteredInvoices = filteredAndSortedInvoices(); // Nur gefilterte Daten
  const totalItems = filteredInvoices.length; // Anzahl gefilterter Einträge
  const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE); // Seiten basierend auf gefilterten Einträgen

  // Pagination
  const paginatedInvoices = filteredInvoices.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset auf Seite 1 bei neuer Suche
  };

  return (
    <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-x-hidden">
      <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-neutral-700">
        <div>
          <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200 inline-flex items-center gap-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"#000000"} fill={"none"}>
                <path d="M12.5 22L10.7273 22C7.46607 22 5.83546 22 4.70307 21.2022C4.37862 20.9736 4.09058 20.7025 3.8477 20.3971C3 19.3313 3 17.7966 3 14.7273L3 12.1818C3 9.21865 3 7.73706 3.46894 6.55375C4.22282 4.65142 5.81714 3.15088 7.83836 2.44135C9.09563 2 10.6698 2 13.8182 2C15.6173 2 16.5168 2 17.2352 2.2522C18.3902 2.65765 19.3013 3.5151 19.732 4.60214C20 5.27832 20 6.12494 20 7.81818L20 10.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M3 12C3 10.159 4.49238 8.66667 6.33333 8.66667C6.99912 8.66667 7.78404 8.78333 8.43137 8.60988C9.00652 8.45576 9.45576 8.00652 9.60988 7.43136C9.78333 6.78404 9.66667 5.99912 9.66667 5.33333C9.66667 3.49238 11.1591 2 13 2" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M21 20.4923C20.5216 21.3957 19.6512 22 18.6568 22C17.147 22 15.9231 20.6071 15.9231 18.8889V17.1111C15.9231 15.3929 17.147 14 18.6568 14C19.6512 14 20.5216 14.6043 21 15.5077M15 18H18.9231" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
            </svg>
            Rechnungen
          </h2>
          <p className="text-sm text-gray-600 dark:text-neutral-400">
            Ihre letzten Rechnungen
          </p>
        </div>
        <div className="inline-flex gap-x-2">
          <div id="table-search" className="relative inline-flex items-center overflow-hidden text-xs rounded-md border border-gray-200 bg-white text-gray-800">
            <div className="text-gray-400 absolute inset-y-0 start-0 flex items-center ps-2 pointer-events-none">
              <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
                  <path d="M17.5 17.5L22 22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
              </svg>
            </div>
            <input
              type="text"
              placeholder="Suche"
              className="pr-3 pl-8 h-full text-sm"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            {/* Spalte: Rechnung */}
            <th
              className="px-6 py-3 text-start cursor-pointer"
              onClick={() => {
                setSortField("invoice");
                setSortOrder(sortOrder === "desc" ? "asc" : "desc");
              }}
            >
              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800">
                Rechnung
                {sortField === "invoice" && (sortOrder === "desc" ? " ↓" : " ↑")}
              </span>
            </th>

            {/* Spalte: Datum */}
            <th
              className="px-6 py-3 text-start cursor-pointer"
              onClick={() => {
                setSortField("date");
                setSortOrder(sortOrder === "desc" ? "asc" : "desc");
              }}
            >
              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800">
                Datum
                {sortField === "date" && (sortOrder === "desc" ? " ↓" : " ↑")}
              </span>
            </th>

            {/* Spalte: Betrag */}
            <th
              className="px-6 py-3 text-start cursor-pointer"
              onClick={() => {
                setSortField("amount");
                setSortOrder(sortOrder === "desc" ? "asc" : "desc");
              }}
            >
              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800">
                Betrag
                {sortField === "amount" && (sortOrder === "desc" ? " ↓" : " ↑")}
              </span>
            </th>

            {/* Spalte: Status */}
            <th
              className="px-6 py-3 text-start cursor-pointer"
              onClick={() => {
                setSortField("status");
                setSortOrder(sortOrder === "desc" ? "asc" : "desc");
              }}
            >
              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800">
                Status
                {sortField === "status" && (sortOrder === "desc" ? " ↓" : " ↑")}
              </span>
            </th>

            {/* Spalte: Fällig */}
            <th
              className="px-6 py-3 text-start cursor-pointer"
              onClick={() => {
                setSortField("due");
                setSortOrder(sortOrder === "desc" ? "asc" : "desc");
              }}
            >
              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800">
                Fällig
                {sortField === "due" && (sortOrder === "desc" ? " ↓" : " ↑")}
              </span>
            </th>
            <th className="px-6 py-3 text-end"></th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {isLoading ? (
            renderSkeleton(3, 6)
          ) : paginatedInvoices.length > 0 ? (
            paginatedInvoices.map((item, index) => {
              const invoiceDateStr = formatDate(item.name);
              const [day, month, year] = invoiceDateStr.split(".");
              const invoiceDate = new Date(`${year}-${month}-${day}`);
              const dueDate = new Date(invoiceDate);
              dueDate.setDate(dueDate.getDate() + 14);
              const formattedDueDate = dueDate.toLocaleDateString();

                      return (
                        <tr key={index}>
                          {/* Rechnungsnummer */}
                          <td className="px-6 py-2 whitespace-nowrap">
                            <button
                              onClick={() => setSelectedInvoice(item)}
                              className="text-sm font-semibold text-gray-800"
                            >
                              {generateInvoiceNumber(item.name)}
                            </button>
                          </td>

                          {/* Datum */}
                          <td className="px-6 py-2 whitespace-nowrap">
                            <span className="text-sm text-gray-600">{invoiceDateStr}</span>
                          </td>

                          {/* Betrag */}
                          <td className="px-6 py-2 whitespace-nowrap">
                            <span className="text-sm text-gray-600">{extractAmount(item.name)}</span>
                          </td>

                          {/* Status */}
                          <td className="px-6 py-2 align-middle whitespace-nowrap">
                            <span
                              className={`py-1 px-2 inline-flex items-center gap-x-2 text-xs font-medium rounded-full ${
                                item.status === "Unbezahlt" ? "bg-gray-100 text-red-800" : "bg-gray-100 text-primary-800"
                              }`}
                            >
                              <div
                                className={`flex items-center justify-center w-3 h-3 p-0.5 rounded-full ${
                                  item.status === "Unbezahlt" ? "bg-red-800" : "bg-primary-800"
                                }`}
                              >
                                {item.status === "Unbezahlt" ? (
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#ffffff"} fill={"none"}>
                                    <path d="M19.0005 4.99988L5.00049 18.9999M5.00049 4.99988L19.0005 18.9999" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                  </svg>
                                ) : (
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#ffffff"} fill={"none"}>
                                    <path d="M5 14L8.5 17.5L19 6.5" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                  </svg>
                                )}
                              </div>
                              {item.status}
                            </span>
                          </td>

                          {/* Fällig */}
                          <td className="px-6 py-2 whitespace-nowrap">
                            <span className="text-sm text-gray-600">{formattedDueDate}</span>
                          </td>

                          {/* Aktionen */}
                          <td className="px-6 py-2 whitespace-nowrap text-right">


                            {item.status === "Unbezahlt" && (
                              <button
                                onClick={() => {
                                  setSelectedPaymentInvoice(item); // Set selected invoice
                                  setIsPaymentModalOpen(true); // Open modal
                                }}
                                className="py-1 px-2 align-middle inline-flex items-center gap-x-2 text-xs font-medium rounded border border-gray-800 bg-gray-800 text-white hover:bg-gray-700 hover:border-gray-700 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 24 24" width={20} height={20} color={"currentColor"} fill={"none"}>
                                  <path d="M14 18C18.4183 18 22 14.4183 22 10C22 5.58172 18.4183 2 14 2C9.58172 2 6 5.58172 6 10C6 14.4183 9.58172 18 14 18Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                                  <path d="M3.15657 11C2.42523 12.1176 2 13.4535 2 14.8888C2 18.8162 5.18378 22 9.11116 22C10.5465 22 11.8824 21.5748 13 20.8434" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                                  <path d="M17 12.4923C16.5216 13.3957 15.6512 14 14.6568 14C13.147 14 11.9231 12.6071 11.9231 10.8889V9.11111C11.9231 7.39289 13.147 6 14.6568 6C15.6512 6 16.5216 6.60426 17 7.50774M11 10H14.9231" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                                </svg>
                                Bezahlen
                              </button>
                            )}
                          
                            <button
                              onClick={() => handleDownload(item.path)}
                              className="ml-2 py-1 px-2 align-middle inline-flex items-center gap-x-2 text-xs font-medium rounded border border-gray-200 bg-white text-gray-800 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                              >
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 24 24" width={20} height={20} color={"currentColor"} fill={"none"}>
                                <path d="M12 14.5L12 4.5M12 14.5C11.2998 14.5 9.99153 12.5057 9.5 12M12 14.5C12.7002 14.5 14.0085 12.5057 14.5 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M20 16.5C20 18.982 19.482 19.5 17 19.5H7C4.518 19.5 4 18.982 4 16.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>Herunterladen
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center p-2">
                        <div className="max-w-sm w-full min-h-[250px] flex flex-col items-center justify-center mx-auto px-6 py-4">
                          <div className="flex justify-center items-center size-[46px] bg-gray-100 rounded-lg dark:bg-neutral-800">
                            <svg className="shrink-0 size-6 text-gray-600 dark:text-neutral-400" xmlns="http://www.w3.org/2000/svg" width="24" height="24" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="transparent" viewBox="0 0 24 24">
                              <path d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z" stroke="currentColor" strokeWidth="1.5" />
                              <path d="M12.2422 17V12C12.2422 11.5286 12.2422 11.2929 12.0957 11.1464C11.9493 11 11.7136 11 11.2422 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M11.992 8H12.001" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </div>

                          <h2 className="mt-5 font-semibold text-lg text-gray-800 dark:text-white">Keine Rechnungen gefunden</h2>
                          <p className="mt-2 text-sm text-gray-600 dark:text-neutral-400">Wir konnten keine Rechnungen finden, die den Suchkriterien entsprechen.</p>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>


              {/* Pagination */}
              <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-4 sm:px-6">
                <div className="flex flex-1 items-center justify-between">
                  <div>
                    <p className="text-sm text-gray-700">
                      Zeige{" "}
                      <span className="font-medium">
                        {(currentPage - 1) * ITEMS_PER_PAGE + 1}
                      </span>{" "}
                      bis{" "}
                      <span className="font-medium">
                        {Math.min(currentPage * ITEMS_PER_PAGE, totalItems)}
                      </span>{" "}
                      von <span className="font-medium">{totalItems}</span> Einträgen
                    </p>
                  </div>
                  <div>
                    <nav
                      aria-label="Pagination"
                      className="isolate inline-flex -space-x-px rounded-md"
                    >
                      <button
                        onClick={() => handlePageChange(1)}
                        className="relative inline-flex items-center rounded-l-md px-1.5 py-1.5 text-gray-800 bg-white ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:z-20 focus:outline-offset-0"
                        disabled={currentPage === 1}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
                            <path d="M15 6C15 6 9.00001 10.4189 9 12C8.99999 13.5812 15 18 15 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </button>
                      {[...Array(totalPages)].map((_, index) => (
                        <button
                          key={index}
                          onClick={() => handlePageChange(index + 1)}
                          className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                            currentPage === index + 1
                              ? "bg-gray-800 text-white ring-1 ring-inset ring-gray-300"
                              : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          }`}
                        >
                          {index + 1}
                        </button>
                      ))}
                      <button
                        onClick={() => handlePageChange(totalPages)}
                        className="relative inline-flex items-center rounded-r-md px-1.5 py-1.5 text-gray-800 bg-white ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:z-20 focus:outline-offset-0"
                        disabled={currentPage === totalPages}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
                          <path d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      </button>
                    </nav>
                  </div>
                </div>
              </div>


            </div>
          );
        };

export default AdvancedInvoicesTable;