import React, { useEffect } from "react";
import { formatDate, extractAmount, generateInvoiceNumber } from "../../utils/helpers";

const InvoiceModal = ({ invoice, onClose }) => {
  if (!invoice) return null;

  const invoiceDateStr = formatDate(invoice.name);
  const [day, month, year] = invoiceDateStr.split(".");
  const invoiceDate = new Date(`${year}-${month}-${day}`);
  const dueDate = new Date(invoiceDate);
  dueDate.setDate(dueDate.getDate() + 14);
  const formattedDueDate = dueDate.toLocaleDateString();

  const handleOverlayClick = (event) => {
    // Überprüfe, ob der Klick auf das Overlay (nicht das Modal) war
    if (event.target === event.currentTarget) {
      onClose(); // Schließt das Modal, wenn außerhalb des Modal-Inhalts geklickt wird
    }
  };

  return (
    <>
      {/* Overlay - Klick darauf schließt das Modal */}
      <div
        id="invoice-modal"
        className="modal-overlay fixed top-0 left-0 z-[80] w-full h-full bg-black bg-opacity-50"
        role="dialog"
        aria-labelledby="invoice-modal-label"
        onClick={handleOverlayClick} // Overlay-Klick-Handler
      >
        <div className="modal-overlay-open:mt-7 modal-overlay-open:opacity-100 modal-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto">
          <div className="relative flex flex-col bg-white shadow-lg rounded-xl pointer-events-auto dark:bg-neutral-800">
            {/* Header Section */}
            <div className="relative overflow-hidden min-h-32 bg-gray-800 text-center rounded-t-xl">
              {/* Close Button */}
              <div className="absolute top-2 right-2">
                <button
                  type="button"
                  onClick={onClose} // Schließen des Modals durch Klick auf den Button
                  className="flex justify-center items-center size-7 text-sm font-semibold rounded-full border border-transparent text-white/70 hover:bg-white/10 focus:outline-none focus:bg-white/10 disabled:opacity-50 disabled:pointer-events-none"
                  aria-label="Close"
                >
                  <span className="sr-only">Close</span>
                  <svg
                    className="shrink-0 size-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M18 6L6 18" />
                    <path d="m6 6 12 12" />
                  </svg>
                </button>
              </div>

              {/* SVG Background Element */}
              <figure className="absolute inset-x-0 bottom-0">
                <svg
                  preserveAspectRatio="none"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 1920 100.1"
                >
                  <path
                    fill="currentColor"
                    className="fill-white dark:fill-neutral-800"
                    d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
                  />
                </svg>
              </figure>
            </div>

            {/* Body Section */}
            <div className="p-4 sm:p-7 overflow-y-auto">
              <div className="text-center">
                <h3 id="invoice-modal-label" className="text-lg font-semibold text-gray-800 dark:text-neutral-200">
                  Rechnung-Details
                </h3>
                <p className="text-sm text-gray-500 dark:text-neutral-500">
                  Rechnung #{generateInvoiceNumber(invoice.name)}
                </p>
              </div>

              {/* Rechnungsdetails Grid */}
              <div className="mt-5 sm:mt-10 grid grid-cols-2 sm:grid-cols-3 gap-5">
                <div>
                  <span className="block text-xs uppercase text-gray-500 dark:text-neutral-500">Betrag</span>
                  <span className="block text-sm font-medium text-gray-800 dark:text-neutral-200">
                    {extractAmount(invoice.name)}
                  </span>
                </div>
                <div>
                  <span className="block text-xs uppercase text-gray-500 dark:text-neutral-500">Status</span>
                  <span className="block text-sm font-medium text-gray-800 dark:text-neutral-200">{invoice.status}</span>
                </div>
                <div>
                  <span className="block text-xs uppercase text-gray-500 dark:text-neutral-500">Fällig</span>
                  <span className="block text-sm font-medium text-gray-800 dark:text-neutral-200">
                    {(() => {
                      const invoiceDateStr = formatDate(invoice.name);
                      const [day, month, year] = invoiceDateStr.split('.');
                      const invoiceDate = new Date(`${year}-${month}-${day}`);
                      const dueDate = new Date(invoiceDate);
                      dueDate.setDate(dueDate.getDate() + 14);
                      return dueDate.toLocaleDateString();
                    })()}
                  </span>
                </div>
              </div>

              {/* Zusammenfassung */}
              <div className="mt-5 sm:mt-10">
                <h4 className="text-xs font-semibold uppercase text-gray-800 dark:text-neutral-200">Zusammenfassung</h4>
                <ul className="mt-3 flex flex-col">
                  <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-neutral-700 dark:text-neutral-200">
                    <div className="flex items-center justify-between w-full">
                      <span>Rechnungsbetrag</span>
                      <span>{extractAmount(invoice.name)}</span>
                    </div>
                  </li>
                  <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm font-semibold bg-gray-50 border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-200">
                    <div className="flex items-center justify-between w-full">
                      <span>Bezahlt</span>
                      <span>{invoice.status === "Unbezahlt" ? "0 €" : extractAmount(invoice.name)}</span>
                    </div>
                  </li>
                </ul>
              </div>

              {/* Buttons */}
              <div className="mt-5 flex justify-end gap-x-2">
                <a
                  href="#"
                  className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                >
                  Herunterladen
                </a>
                <a
                  href="#"
                  className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 disabled:opacity-50 disabled:pointer-events-none"
                >
                  Drucken
                </a>
              </div>

              {/* Kontaktinformationen */}
              <div className="mt-5 sm:mt-10">
                <p className="text-sm text-gray-500 dark:text-neutral-500">
                  Bei Fragen kontaktieren Sie uns bitte per Mail an{" "}
                  <a
                    className="inline-flex items-center gap-x-1.5 text-gray-800 decoration-2 hover:underline focus:outline-none focus:underline font-medium dark:text-gray-500"
                    href="#"
                    target="_parent"
                  >
                    info@evocan.de
                  </a>{" "}
                  oder rufen Sie uns an unter{" "}
                  <a
                    className="inline-flex items-center gap-x-1.5 text-gray-800 decoration-2 hover:underline focus:outline-none focus:underline font-medium dark:text-gray-500"
                    href="tel:+4915167591982"
                  >
                    +49 1516 7591982
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceModal;