import React, { useEffect, useState } from "react";
import { fetchAccountDetails } from "../services/api";

const Account = () => {
  const [accountDetails, setAccountDetails] = useState({});
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const detailsResponse = await fetchAccountDetails();
        setAccountDetails(detailsResponse.data || {});
      } catch (err) {
        setError("Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const renderSkeleton = (rows, columns) => (
    Array.from({ length: rows }).map((_, rowIndex) => (
      <div key={rowIndex} className="grid grid-cols-1 sm:grid-cols-1 gap-4">
        {Array.from({ length: columns }).map((_, colIndex) => (
          <div key={colIndex} className="h-4 bg-gray-300 rounded animate-pulse mb-4"></div>
        ))}
      </div>
    ))
  );

  return (
    <div className="content-container">
      <div className="page-title my-6">
        <h2 className="text-xl font-semibold text-gray-800">Ihre Daten</h2>
        <p className="text-gray-500 text-sm">Ihre hinterlegten Informationen und Kontaktdaten.</p>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Persönliche Daten */}
        <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden">
          <div className="px-6 py-4 border-b border-gray-200">
            <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200 inline-flex items-center gap-x-2">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
                  <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="currentColor" strokeWidth="1.5" />
                  <path d="M11 7L17 7" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                  <path d="M7 7L8 7" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                  <path d="M7 12L8 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                  <path d="M7 17L8 17" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                  <path d="M11 12L17 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                  <path d="M11 17L17 17" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
              </svg>
              Persönliche Daten
            </h2>
            <p className="text-sm text-gray-600">Angaben zu Ihrer Person</p>
          </div>
          <div className="px-6 py-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
            {[
              { label: "Vorname", value: accountDetails.firstName },
              { label: "Nachname", value: accountDetails.lastName },
              { label: "Geburtsdatum", value: accountDetails.birthDate },
              { label: "Krankenkasse", value: accountDetails.insurance },
            ].map((field, index) => (
              <div key={index}>
                <p className="text-sm font-medium text-gray-800">{field.label}</p>
                <p className="text-gray-800">
                  {isLoading ? (
                    <div className="h-4 bg-gray-300 rounded animate-pulse mt-2"></div>
                  ) : (
                    field.value || "Keine Daten hinterlegt"
                  )}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Kontaktdaten */}
        <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden">
          <div className="px-6 py-4 border-b border-gray-200">
            <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200 inline-flex items-center gap-x-2">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
                  <path d="M4 10C4 6.22876 4 4.34315 5.17157 3.17157C6.34315 2 8.22876 2 12 2H13.5C17.2712 2 19.1569 2 20.3284 3.17157C21.5 4.34315 21.5 6.22876 21.5 10V14C21.5 17.7712 21.5 19.6569 20.3284 20.8284C19.1569 22 17.2712 22 13.5 22H12C8.22876 22 6.34315 22 5.17157 20.8284C4 19.6569 4 17.7712 4 14V10Z" stroke="currentColor" strokeWidth="1.5" />
                  <path d="M9.79993 11.9741C9.37332 11.2302 9.16733 10.6228 9.04313 10.007C8.85943 9.09641 9.27981 8.20686 9.97622 7.63926C10.2706 7.39937 10.608 7.48133 10.782 7.79358L11.1749 8.49851C11.4864 9.05725 11.6421 9.33663 11.6112 9.63282C11.5803 9.929 11.3703 10.1702 10.9503 10.6527L9.79993 11.9741ZM9.79993 11.9741C10.6634 13.4797 12.0185 14.8356 13.5259 15.7001M13.5259 15.7001C14.2698 16.1267 14.8772 16.3327 15.493 16.4569C16.4036 16.6406 17.2931 16.2202 17.8607 15.5238C18.1006 15.2294 18.0187 14.892 17.7064 14.718L17.0015 14.3251C16.4427 14.0136 16.1634 13.8579 15.8672 13.8888C15.571 13.9197 15.3298 14.1297 14.8473 14.5497L13.5259 15.7001Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
                  <path d="M5 6L2.5 6M5 12L2.5 12M5 18H2.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              Kontaktdaten
            </h2>
            <p className="text-sm text-gray-600">Angaben zu Ihrer Erreichbarkeit</p>
          </div>
          <div className="px-6 py-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
            {[
              { label: "E-Mail-Adresse", value: accountDetails.email },
              { label: "Telefonnummer", value: accountDetails.phone },
              { label: "Straße und Hausnummer", value: accountDetails.address },
              { label: "Postleitzahl", value: accountDetails.postalCode },
              { label: "Ort", value: accountDetails.city },
            ].map((field, index) => (
              <div key={index}>
                <p className="text-sm font-medium text-gray-800">{field.label}</p>
                <p className="text-gray-800">
                  {isLoading ? (
                    <div className="h-4 bg-gray-300 rounded animate-pulse mt-2"></div>
                  ) : (
                    field.value || "Keine Daten hinterlegt"
                  )}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Aktuelle Präparate */}
      <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden mt-6">
        <div className="px-6 py-4 border-b border-gray-200">
          <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200 inline-flex items-center gap-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
              <path d="M18 10C18 10 12 14 12 21" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M9.34882 11.1825C7.73784 12.3891 5.44323 12.26 3.9785 10.7953C1.55484 8.37164 2.03957 3.03957 2.03957 3.03957C2.03957 3.03957 7.37164 2.55484 9.7953 4.9785C10.7548 5.93803 11.1412 7.25369 10.9543 8.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M14.9638 12.8175C13.644 11.3832 13.6797 9.14983 15.0708 7.75867C17.2252 5.6043 21.9648 6.03517 21.9648 6.03517C21.9648 6.03517 22.3957 10.7748 20.2413 12.9292C19.4877 13.6828 18.487 14.0386 17.5 13.9967" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M6 7C6 7 12 12 12 21" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            Aktuelle Präparate
          </h2>
          <p className="text-sm text-gray-600">Informationen zu Ihren aktuellen Präparaten</p>
        </div>
        <div className="px-6 py-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
          {isLoading
            ? renderSkeleton(1, 1)
            : ["prescription1", "prescription2", "prescription3"]
                .map((key, index) => ({
                  label: `${index + 1}. Verschreibung`,
                  value: accountDetails[key],
                }))
                .filter((item) => item.value)
                .map((field, index) => (
                  <div key={index}>
                    <p className="text-sm font-medium text-gray-800">{field.label}</p>
                    <p className="text-gray-800">{field.value}</p>
                  </div>
                ))}
          {!isLoading &&
            !accountDetails.prescription1 &&
            !accountDetails.prescription2 &&
            !accountDetails.prescription3 && (
              <div>
                <p className="text-gray-800">Aktuell keine Präparate verdordnet.</p>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default Account;