// components/Layout/Footer.js

import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer
      id="footer"
      className="p-6"
    >

      <div className="content-container flex justify-between items-center">

        {/* Links - Links */}
        <div className="text-sm text-gray-500 dark:text-neutral-400">
          <p>&copy; {new Date().getFullYear()} EvoCan. Alle Rechte vorbehalten.</p>
        </div>

        {/* Links - Rechts */}
        <div className="flex space-x-6">
          <Link
            to="/help"
            className="text-sm text-gray-500 hover:text-gray-900 dark:text-neutral-400 dark:hover:text-primary-400"
          >
            Hilfe
          </Link>
          <Link
            to="/about"
            className="text-sm text-gray-500 hover:text-gray-900 dark:text-neutral-400 dark:hover:text-primary-400"
          >
            Über uns
          </Link>
          <Link
            to="/contact"
            className="text-sm text-gray-500 hover:text-gray-900 dark:text-neutral-400 dark:hover:text-primary-400"
          >
            Kontakt
          </Link>
        </div>
      </div>
        
    </footer>
  );
};

export default Footer;