import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { filterAndSortData } from "../../utils/helpers";
import { useOutsideClick } from "../../utils/useOutsideClick";

const ITEMS_PER_PAGE = 10;

const AdvancedSessionsTable = ({
  isLoading,
  sessions,
  formatDate,
  sessionTypeMap,
  renderSkeleton,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [sortField, setSortField] = useState("date");
  const [sortOrder, setSortOrder] = useState("desc");
  const [filterType, setFilterType] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  // Hook to close dropdown on outside click
  useOutsideClick(dropdownRef, buttonRef, () => setIsDropdownOpen(false));

  // Get unique session types present in the table
  const getSessionTypes = () => {
    const uniqueTypes = new Set(
      sessions.map((session) => session.name.split(" ")[1])
    );
    return Array.from(uniqueTypes).filter(Boolean);
  };

  const filteredAndSortedSessions = () => {
    return filterAndSortData({
      data: { sessions },
      type: "sessions",
      searchQuery,
      sortField,
      sortOrder,
      filterType,
      formatDate,
    }).filter((session) =>
      !filterType || session.name.split(" ")[1] === filterType
    );
  };

  const filteredSessions = filteredAndSortedSessions();
  const totalItems = filteredSessions.length;
  const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE);

  const paginatedSessions = filteredSessions.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to first page on new search
  };

  return (
    <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-x-hidden">
      <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-neutral-700">
        <div>
          <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200 inline-flex items-center gap-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
                <path d="M16 2V5M6 2V5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M20 13V11.5C20 7.72876 20 5.84315 18.8284 4.67157C17.6569 3.5 15.7712 3.5 12 3.5H10C6.22876 3.5 4.34315 3.5 3.17157 4.67157C2 5.84315 2 7.72876 2 11.5V14C2 17.7712 2 19.6569 3.17157 20.8284C4.34315 22 6.22876 22 10 22H11.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M14 19.5C14 19.5 15.3485 20.0067 16 22C16 22 19.1765 17 22 16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M2.5 8.5H19.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            Vergangene Termine
          </h2>
          <p className="text-sm text-gray-600 dark:text-neutral-400">
            Ihre stattgehabten Sprechstunden
          </p>
        </div>
      
        <div className="inline-flex gap-x-2">
          <div>
            <button
              ref={buttonRef}
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="py-2 px-3 inline-flex items-center gap-x-2 text-xs font-medium rounded border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
            >
              Filter: {filterType || "Alle Termine"}
            </button>
              {isDropdownOpen && (
                <div
                  ref={dropdownRef}
                  className="absolute z-10 bg-white shadow-lg rounded-md mt-2 overflow-hidden w-60 text-xs rounded border border-gray-200 bg-white text-gray-800 shadow-sm"
                >
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-800 hover:bg-gray-100"
                    onClick={() => {
                      setFilterType("");
                      setIsDropdownOpen(false);
                    }}
                  >
                    Alle Termine
                  </a>
                  {getSessionTypes().map((typeKey) => (
                    <a
                      href="#"
                      key={typeKey}
                      className="block px-4 py-2 text-sm text-gray-800 hover:bg-gray-100"
                      onClick={() => {
                        setFilterType(typeKey);
                        setIsDropdownOpen(false);
                      }}
                    >
                      {sessionTypeMap[typeKey] || typeKey}
                    </a>
                    ))}
                </div>
              )}     
          </div>
          <div id="table-search" className="relative inline-flex items-center overflow-hidden text-xs rounded-md border border-gray-200 bg-white text-gray-800">
            <div className="text-gray-400 absolute inset-y-0 start-0 flex items-center ps-2 pointer-events-none">
              <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
                  <path d="M17.5 17.5L22 22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
              </svg>
            </div>
            <input
              type="text"
              placeholder="Suche"
              className="pr-3 pl-8 h-full text-sm"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              {/* Datum-Spalte */}
              <th
                className="px-6 py-3 text-start cursor-pointer"
                onClick={() => {
                  setSortField("date");
                  setSortOrder(sortOrder === "desc" ? "asc" : "desc");
                }}
              >
                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800">
                  Datum
                  {sortField === "date" && (sortOrder === "desc" ? " ↓" : " ↑")}
                </span>
              </th>

              {/* Typ-Spalte */}
              <th
                className="px-6 py-3 text-start cursor-pointer"
                onClick={() => {
                  setSortField("type");
                  setSortOrder(sortOrder === "desc" ? "asc" : "desc");
                }}
              >
                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800">
                  Termin
                  {sortField === "type" && (sortOrder === "desc" ? " ↓" : " ↑")}
                </span>
              </th>
              <th className="px-6 py-3 text-start">
                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800">
                  Status
                </span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {isLoading ? (
              renderSkeleton(3, 3)
            ) : paginatedSessions.length > 0 ? (
              paginatedSessions.map((item, index) => (
                <tr key={index}>
                  <td className="px-6 py-3 whitespace-nowrap">
                    <span className="text-sm text-gray-600">
                      {formatDate(item.name)}
                    </span>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    <span className="text-sm text-gray-600">
                      {sessionTypeMap[item.name.split(" ")[1]] || item.name.split(" ")[1]}
                    </span>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    <span
                      className={`py-1 px-2 inline-flex items-center gap-x-2 text-xs font-medium rounded-full ${
                        item.name.split(" ")[1] === "AUS" ? "bg-gray-100 text-red-800" : "bg-gray-100 text-primary-800"
                      }`}
                    >
                      <div
                        className={`flex items-center justify-center w-3 h-3 p-0.5 rounded-full ${
                          item.name.split(" ")[1] === "AUS" ? "bg-red-800" : "bg-primary-800"
                        }`}
                      >
                        {item.name.split(" ")[1] === "AUS" ? (
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#ffffff"} fill={"none"}>
                              <path d="M19.0005 4.99988L5.00049 18.9999M5.00049 4.99988L19.0005 18.9999" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        ) : (
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#ffffff"} fill={"none"}>
                              <path d="M5 14L8.5 17.5L19 6.5" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        )}
                      </div>
                      {item.name.split(" ")[1] === "AUS" ? "Nicht erfolgt" : "Erfolgt"}
                    </span>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3" className="text-center p-2">
                  <div className="max-w-sm w-full min-h-[250px] flex flex-col items-center justify-center mx-auto px-6 py-4">
                    <div className="flex justify-center items-center size-[46px] bg-gray-100 rounded-lg dark:bg-neutral-800">
                      <svg className="shrink-0 size-6 text-gray-600 dark:text-neutral-400" xmlns="http://www.w3.org/2000/svg" width="24" height="24" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="transparent" viewBox="0 0 24 24">
                        <path d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z" stroke="currentColor" strokeWidth="1.5" />
                        <path d="M12.2422 17V12C12.2422 11.5286 12.2422 11.2929 12.0957 11.1464C11.9493 11 11.7136 11 11.2422 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M11.992 8H12.001" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </div>

                    <h2 className="mt-5 font-semibold text-lg text-gray-800 dark:text-white">Keine Termine gefunden</h2>
                    <p className="mt-2 text-sm text-gray-600 dark:text-neutral-400">Wir konnten keine Termine finden, die den Suchkriterien entsprechen.</p>

                    <div className="mt-5 flex flex-col sm:flex-row gap-2">
                      <button
                        type="button"
                        className="py-2 px-3 inline-flex items-center gap-x-2 text-xs font-medium rounded border border-transparent bg-gray-800 text-white shadow-sm hover:bg-gray-700 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-700 dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                        onClick={() => alert("Termin buchen")}
                      >
                        <svg className="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                          <path d="M5 12h14"/><path d="M12 5v14"/>
                        </svg>
                        Termin buchen
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-4 sm:px-6">
        <div className="flex flex-1 items-center justify-between">
          <div>
            <p className="text-sm text-gray-700">
              Zeige{" "}
              <span className="font-medium">
                {(currentPage - 1) * ITEMS_PER_PAGE + 1}
              </span>{" "}
              bis{" "}
              <span className="font-medium">
                {Math.min(currentPage * ITEMS_PER_PAGE, totalItems)}
              </span>{" "}
              von <span className="font-medium">{totalItems}</span> Einträgen
            </p>
          </div>
          <div>
            <nav
              aria-label="Pagination"
              className="isolate inline-flex -space-x-px rounded-md"
            >
              <button
                onClick={() => handlePageChange(1)}
                className="relative inline-flex items-center rounded-l-md px-1.5 py-1.5 text-gray-800 bg-white ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:z-20 focus:outline-offset-0"
                disabled={currentPage === 1}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
                    <path d="M15 6C15 6 9.00001 10.4189 9 12C8.99999 13.5812 15 18 15 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </button>
              {[...Array(totalPages)].map((_, index) => (
                <button
                  key={index}
                  onClick={() => handlePageChange(index + 1)}
                  className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                    currentPage === index + 1
                      ? "bg-gray-800 text-white ring-1 ring-inset ring-gray-300"
                      : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  }`}
                >
                  {index + 1}
                </button>
              ))}
              <button
                onClick={() => handlePageChange(totalPages)}
                className="relative inline-flex items-center rounded-r-md px-1.5 py-1.5 text-gray-800 bg-white ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:z-20 focus:outline-offset-0"
                disabled={currentPage === totalPages}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
                  <path d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              </button>
            </nav>
          </div>
        </div>
      </div>


    </div>
  );
};


export default AdvancedSessionsTable;