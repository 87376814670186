import React, { useState } from "react";
import { Alert, Button, Typography } from "@material-tailwind/react";
import PaymentModal from "../Modals/PaymentModal"; // Adjust the path if needed
import { Link } from "react-router-dom";

const UnpaidInvoicesCard = ({ invoices, generateInvoiceNumber, formatDate, extractAmount }) => {
  const [isAlertOpen, setAlertOpen] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  // Filter unpaid invoices
  const unpaidInvoices = invoices.filter((invoice) => invoice.status === "Unbezahlt");

  if (!unpaidInvoices.length) {
    return null; // No unpaid invoices, don't render the alert
  }

  // Get the last unpaid invoice
  const lastInvoice = unpaidInvoices[unpaidInvoices.length - 1];
  const invoiceDateStr = formatDate(lastInvoice.name);
  const [day, month, year] = invoiceDateStr.split(".");
  const invoiceDate = new Date(`${year}-${month}-${day}`);
  const dueDate = new Date(invoiceDate);
  dueDate.setDate(dueDate.getDate() + 14); // Calculate due date
  const formattedDueDate = dueDate.toLocaleDateString();

  const handleModalOpen = () => {
    setSelectedInvoice(lastInvoice);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setSelectedInvoice(null);
    setModalOpen(false);
  };

  return (
    <>
      {isAlertOpen && (
        <Alert
          open={isAlertOpen}
          className="border-[#FBCA16] bg-[#FEFCE8] text-md font-medium"
          variant="outlined"
          color="yellow"
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="#FACC14"
              className="h-6 w-6"
            >
              <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="#FACC14" stroke-width="1.5"></path><path d="M12.2422 17V12C12.2422 11.5286 12.2422 11.2929 12.0957 11.1464C11.9493 11 11.7136 11 11.2422 11" stroke="#FACC14" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M11.992 8H12.001" stroke="#FACC14" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
          }
          onClose={() => setAlertOpen(false)}
        >
          <Typography variant="h5" color="yellow" className="text-md text-[#854D0F]">
            Neue Rechnung
          </Typography>
          <Typography color="yellow" className="mt-2 font-normal text-[#A16207] text-sm">
            Es liegt eine unbezahlte Rechnung vor über {extractAmount(lastInvoice.name)}. <Link onClick={handleModalOpen} className="font-semibold underline">Bitte begleichen Sie diese</Link> bis spätestens {formattedDueDate}.
          </Typography>
        </Alert>
      )}

      {/* Payment Modal */}
      {isModalOpen && (
        <PaymentModal
          invoice={selectedInvoice}
          isOpen={isModalOpen}
          onClose={handleModalClose}
        />
      )}
    </>
  );
};

export default UnpaidInvoicesCard;