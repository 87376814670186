import React from "react";
import { Link } from "react-router-dom";

const PrescriptionRequestCard = ({ sessions = [], isLoading }) => {
  // Sicherstellen, dass sessions ein Array ist
  const validSessions = Array.isArray(sessions) ? sessions : [];

  // Helper-Funktion: Berechne die nächste VSS
  const calculateNextVSS = () => {
    const vssSessions = validSessions
      .filter((session) => {
        const parts = session.name.split(" ");
        return parts[1] === "VSS"; // Nur reguläre VSS
      })
      .map((session) => {
        const dateStr = session.name.split(" ")[0];
        const year = `20${dateStr.slice(0, 2)}`;
        const month = dateStr.slice(2, 4) - 1; // Monat beginnt bei 0
        const day = dateStr.slice(4, 6);
        return new Date(year, month, day);
      })
      .sort((a, b) => b - a); // Absteigend sortieren

    return vssSessions[0] || null; // Letzte reguläre VSS oder null
  };

  // Ladeanzeige
  if (isLoading) {
    return (
      <div className="bg-white border border-gray-200 rounded-xl shadow-sm p-4">
        <h3 className="text-lg font-semibold text-gray-800 inline-flex items-center gap-x-2">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
              <path d="M19.7502 11V10C19.7502 6.22876 19.7502 4.34315 18.5786 3.17157C17.407 2 15.5214 2 11.7502 2H10.7503C6.97907 2 5.09346 2 3.92189 3.17156C2.75032 4.34312 2.7503 6.22872 2.75027 9.99993L2.75024 14C2.7502 17.7712 2.75019 19.6568 3.92172 20.8284C5.09329 21.9999 6.97897 22 10.7502 22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M7.25024 7H15.2502M7.25024 12H15.2502" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
              <path d="M13.2502 20.8268V22H14.4236C14.833 22 15.0377 22 15.2217 21.9238C15.4058 21.8475 15.5505 21.7028 15.84 21.4134L20.6636 16.5894C20.9366 16.3164 21.0731 16.1799 21.1461 16.0327C21.285 15.7525 21.285 15.4236 21.1461 15.1434C21.0731 14.9961 20.9366 14.8596 20.6636 14.5866C20.3905 14.3136 20.254 14.1771 20.1067 14.1041C19.8265 13.9653 19.4975 13.9653 19.2173 14.1041C19.0701 14.1771 18.9335 14.3136 18.6605 14.5866L18.6605 14.5866L13.8369 19.4106C13.5474 19.7 13.4027 19.8447 13.3265 20.0287C13.2502 20.2128 13.2502 20.4174 13.2502 20.8268Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
          </svg>
          Rezeptanfrage
        </h3>
        <div className="mt-2">
          <div className="h-3 bg-gray-300 rounded animate-pulse w-full"></div>
          <div className="h-3 bg-gray-300 rounded animate-pulse w-2/3 mt-2"></div>
          <div className="h-7 bg-gray-300 rounded animate-pulse w-1/3 mt-2"></div>
        </div>
      </div>
    );
  }

  // Berechne: Ist eine VSS fällig?
  const lastVSS = calculateNextVSS();
  const today = new Date();
  let isVSSPending = false;

  if (lastVSS) {
    const nextVSS = new Date(lastVSS.getTime() + 90 * 24 * 60 * 60 * 1000); // 90 Tage nach letzter VSS
    const daysUntilNextVSS = Math.ceil((nextVSS - today) / (1000 * 60 * 60 * 24));
    isVSSPending = daysUntilNextVSS <= 7; // Fällig, wenn <= 0 Tage
  }

  return (
    <div className="bg-white border border-gray-200 rounded-xl shadow-sm p-4">
      <h3 className="text-lg font-semibold text-gray-800 inline-flex items-center gap-x-2">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
              <path d="M19.7502 11V10C19.7502 6.22876 19.7502 4.34315 18.5786 3.17157C17.407 2 15.5214 2 11.7502 2H10.7503C6.97907 2 5.09346 2 3.92189 3.17156C2.75032 4.34312 2.7503 6.22872 2.75027 9.99993L2.75024 14C2.7502 17.7712 2.75019 19.6568 3.92172 20.8284C5.09329 21.9999 6.97897 22 10.7502 22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M7.25024 7H15.2502M7.25024 12H15.2502" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
              <path d="M13.2502 20.8268V22H14.4236C14.833 22 15.0377 22 15.2217 21.9238C15.4058 21.8475 15.5505 21.7028 15.84 21.4134L20.6636 16.5894C20.9366 16.3164 21.0731 16.1799 21.1461 16.0327C21.285 15.7525 21.285 15.4236 21.1461 15.1434C21.0731 14.9961 20.9366 14.8596 20.6636 14.5866C20.3905 14.3136 20.254 14.1771 20.1067 14.1041C19.8265 13.9653 19.4975 13.9653 19.2173 14.1041C19.0701 14.1771 18.9335 14.3136 18.6605 14.5866L18.6605 14.5866L13.8369 19.4106C13.5474 19.7 13.4027 19.8447 13.3265 20.0287C13.2502 20.2128 13.2502 20.4174 13.2502 20.8268Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
          </svg>
        Rezeptanfrage
      </h3>
      <p
        className={`text-sm mt-2 ${
          isVSSPending ? "text-red-600" : "text-gray-600"
        }`}
      >
        {isVSSPending
          ? "Bitte buchen Sie eine reguläre Videosprechstunde, um ein Rezept zu erhalten."
          : "Benötigen Sie ein neues Rezept? Stellen Sie hier einfach Ihre Rezeptanfrage."}
      </p>
      <Link
        to="/requests"
        className={`py-2 px-3 mt-3 inline-flex items-center gap-x-2 text-xs font-medium rounded border border-transparent shadow-sm focus:outline-none focus:bg-gray-700 dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800 ${
          isVSSPending
            ? "shadow-transparent disabled pointer-events-none bg-gray-300 text-gray-600"
            : "bg-gray-800 text-white hover:bg-gray-700"
        }`}
        disabled={isVSSPending}
      >
        <svg className="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M5 12h14"/><path d="M12 5v14"/></svg>
        Rezept anfordern
      </Link>
    </div>
  );
};

export default PrescriptionRequestCard;
