// components/Layout/Header.js

import React from "react";
import { Link, useLocation } from "react-router-dom";

const Header = ({ onToggleSidebar }) => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  return (

<>

    <header id="header" className="header">
      <div className="sticky top-0 inset-x-0 z-20 bg-white border-y px-6 sm:px-8 md:px-10 lg:hidden">
        <div className="flex items-center py-2">

          {/* Menu toggle */}
          <button
            type="button"
            onClick={onToggleSidebar}
            className="size-8 flex justify-center items-center gap-x-2 border border-gray-200 text-gray-800 hover:text-gray-500 rounded-lg focus:outline-none focus:text-gray-500"
            aria-haspopup="dialog"
            aria-expanded="false"
            aria-controls="sidebar"
          >
            <span className="sr-only">Toggle Navigation</span>
            <svg className="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M4 5L20 5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M4 12L20 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M4 19L20 19" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>

          {/* Breadcrumb Navigation 
          <ol className="flex items-center space-x-2 ml-4">
            {pathnames.map((value, index) => {
              const to = `/${pathnames.slice(0, index + 1).join("/")}`;
              const isLast = index === pathnames.length - 1;

              return (
                <li key={to} className="flex items-center">
                  {!isLast ? (
                    <Link
                      to={to}
                      className="text-sm text-gray-800 hover:text-gray-500"
                    >
                      {value.charAt(0).toUpperCase() + value.slice(1)}
                    </Link>
                  ) : (
                    <span className="text-sm font-semibold text-gray-800">
                      {value.charAt(0).toUpperCase() + value.slice(1)}
                    </span>
                  )}
                  {!isLast && (
                    <svg
                      className="w-4 h-4 mx-2 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  )}
                </li>
              );
            })}
          </ol>    */}


        </div>
      </div>     
    </header>

    </>
  );
};

export default Header;