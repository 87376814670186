import React, { useState, useEffect } from "react";
import { fetchOnboardingDocuments } from "../../services/api";
import { Link, useNavigate } from "react-router-dom";

const OnboardingProgressCard = ({ isLoading }) => {
  const [documents, setDocuments] = useState({
    AN: false,
    BE: false,
    UN: false,
  });
  const [isDataLoading, setIsDataLoading] = useState(true);
  const navigate = useNavigate();

  const requiredDocuments = [
    { code: "AN", name: "Behandlungsanfrage", link: "/requests" },
    { code: "BE", name: "Vorbefunde", link: "/files" },
    { code: "UN", name: "Einverständniserklärung", link: "/files" },
  ];

  useEffect(() => {
    const loadDocuments = async () => {
      try {
        const response = await fetchOnboardingDocuments();
        const data = Array.isArray(response?.data?.onboardingDocuments)
          ? response.data.onboardingDocuments
          : [];

        // Prüfe, ob die Namen die erwarteten Typen enthalten
        setDocuments({
          AN: data.some((doc) => /\bAN\b/.test(doc.name)),
          BE: data.some((doc) => /\bBE\b/.test(doc.name)),
          UN: data.some((doc) => /\bUN\b/.test(doc.name)),
        });
      } catch (error) {
        console.error("Fehler beim Abrufen der Dokumente:", error);
      } finally {
        setIsDataLoading(false);
      }
    };

    if (!isLoading) {
      loadDocuments();
    }
  }, [isLoading]);

  const renderIcon = (exists) => (
    <div
      className={`flex items-center justify-center w-5 h-5 p-1 rounded-full ${
        exists ? "bg-primary-800" : "bg-red-800"
      }`}
    >
      {exists ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width={16}
          height={16}
          color={"#ffffff"}
          fill={"none"}
        >
          <path
            d="M5 14L8.5 17.5L19 6.5"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width={16}
          height={16}
          color={"#ffffff"}
          fill={"none"}
        >
          <path
            d="M19.0005 4.99988L5.00049 18.9999M5.00049 4.99988L19.0005 18.9999"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </div>
  );

  const renderText = (exists, title, successText, errorText) => (
    <div>
      <h4 className="text-sm font-medium text-gray-800">{title}</h4>
      <p className="text-sm text-gray-600">{exists ? successText : errorText}</p>
    </div>
  );

  const progressPercentage =
    (Object.values(documents).filter(Boolean).length /
      requiredDocuments.length) *
    100;

  if (isLoading || isDataLoading) {
    return (
      <div className="bg-white border border-gray-200 rounded-xl shadow-sm p-4">
      <h3 className="text-lg font-semibold text-gray-800 inline-flex items-center gap-x-2">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"#000000"} fill={"none"}>
          <path d="M22 16C21.4102 15.3932 19.8403 13 19 13C18.1597 13 16.5898 15.3932 16 16M19 14L19 21" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M15.0035 21H12.0027C7.28739 21 4.92973 21 3.46487 19.5355C2 18.0711 2 15.714 2 11V7.94427C2 6.1278 2 5.21956 2.38042 4.53806C2.6516 4.05227 3.05255 3.65142 3.53848 3.38032C4.22017 3 5.12865 3 6.94562 3C8.10968 3 8.69172 3 9.20122 3.19101C10.3645 3.62712 10.8442 4.68358 11.3691 5.73313L12.0027 7M8.00163 7H16.754C18.8613 7 19.9149 7 20.6718 7.50559C20.9995 7.72447 21.2808 8.00572 21.4997 8.33329C21.8937 8.92282 21.9808 9.69244 22 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
        </svg>
        Onboarding Fortschritt
      </h3>
        <div className="h-3 my-5 bg-gray-300 animate-pulse rounded-full w-full"></div>
        <div className="mt-5 space-y-4">
          {[...Array(3)].map((_, index) => (
            <div key={index} className="flex items-start gap-x-4">
              <div className="h-6 w-7 bg-gray-300 animate-pulse rounded-full"></div>
              <div className="flex flex-col space-y-2 w-full">
                <div className="h-3 bg-gray-300 animate-pulse rounded w-1/4"></div>
                <div className="h-3 bg-gray-300 animate-pulse rounded w-2/3"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  const allDocumentsSubmitted = Object.values(documents).every(Boolean);

  return (
    <div className="bg-white border border-gray-200 rounded-xl shadow-sm p-4">
      <h3 className="text-lg font-semibold text-gray-800 inline-flex items-center gap-x-2">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"#000000"} fill={"none"}>
          <path d="M22 16C21.4102 15.3932 19.8403 13 19 13C18.1597 13 16.5898 15.3932 16 16M19 14L19 21" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M15.0035 21H12.0027C7.28739 21 4.92973 21 3.46487 19.5355C2 18.0711 2 15.714 2 11V7.94427C2 6.1278 2 5.21956 2.38042 4.53806C2.6516 4.05227 3.05255 3.65142 3.53848 3.38032C4.22017 3 5.12865 3 6.94562 3C8.10968 3 8.69172 3 9.20122 3.19101C10.3645 3.62712 10.8442 4.68358 11.3691 5.73313L12.0027 7M8.00163 7H16.754C18.8613 7 19.9149 7 20.6718 7.50559C20.9995 7.72447 21.2808 8.00572 21.4997 8.33329C21.8937 8.92282 21.9808 9.69244 22 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
        </svg>
        Onboarding Fortschritt
      </h3>

      {/* Fortschrittsbalken */}
      <div className="my-5">
        {/* Ersetzen Sie dies durch Ihre Fortschrittsbalken-Komponente */}
        <div className="w-full bg-gray-200 rounded-full h-2.5">
          <div
            className="bg-primary-800 h-2.5 rounded-full"
            style={{ width: `${progressPercentage}%` }}
          ></div>
        </div>
      </div>

      {!allDocumentsSubmitted ? (
      <ul className="mt-5 space-y-4">
        {requiredDocuments.map((doc) => {
          const exists = documents[doc.code];

          const successText =
            doc.code === "AN"
              ? "Erfolgreich eingereicht und geprüft."
              : doc.code === "BE"
              ? "Erfolgreich hochgeladen und akzeptiert."
              : "Erfolgreich eingereicht und bestätigt.";

          const errorText = (
            <>
              Noch ausstehend. Bitte{" "}
              <Link className="font-semibold underline" to={doc.link}>
                {doc.code === "AN"
                  ? "anfragen"
                  : doc.code === "BE"
                  ? "hochladen"
                  : "unterzeichnen"}
              </Link>{" "}
              {doc.code === "AN"
                ? "um die Behandlung zu starten."
                : doc.code === "BE"
                ? "um fortzufahren."
                : "um die Behandlung fortzusetzen."}
            </>
          );

          return (
            <li key={doc.code} className="flex items-start gap-x-4">
              {renderIcon(exists)}
              {renderText(exists, doc.name, successText, errorText)}
            </li>
          );
        })}
      </ul>

      ) : (

        <div className="mt-6 text-center">
          <h4 className="text-sm font-medium text-green-600">
            Alles erledigt! Jetzt können Sie einen Termin für das Erstgespräch buchen.
          </h4>
          <Link
            to="/appointments"
            className="py-2 px-3 mt-3 inline-flex items-center gap-x-2 text-xs font-medium rounded border border-transparent bg-gray-800 text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
          >
            <svg
              className="shrink-0 size-4"
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M5 12h14" />
              <path d="M12 5v14" />
            </svg>
            Termin buchen
          </Link>
        </div>

      )}
    </div>
  );
};

export default OnboardingProgressCard;