import axios from "axios";

// Base API configuration
const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "/",
  withCredentials: true,
});

// Flag to prevent multiple logouts
let isLoggingOut = false;

// Funktion zum Entfernen des Tokens und Weiterleiten
const handleExpiredToken = () => {
  if (!isLoggingOut) {
    isLoggingOut = true;
    localStorage.removeItem("token");
    window.location.href = "/login"; // Weiterleitung zur Login-Seite
  }
};

// Request Interceptor: Fügt das Token automatisch hinzu
API.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

API.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401 && error.response?.data?.message === "Token expired") {
      const refreshToken = localStorage.getItem("refreshToken");
      if (refreshToken) {
        try {
          const response = await API.post("/auth/refresh-token", { refreshToken });
          const { accessToken } = response.data;
          localStorage.setItem("token", accessToken);
          error.config.headers["Authorization"] = `Bearer ${accessToken}`;
          return API.request(error.config);
        } catch (refreshError) {
          console.error("Failed to refresh token:", refreshError.message);
          localStorage.removeItem("token");
          localStorage.removeItem("refreshToken");
          window.location.href = "/login";
        }
      } else {
        console.error("Refresh token not found. Logging out...");
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        window.location.href = "/login";
      }
    }
    return Promise.reject(error);
  }
);

// Authentifizierungsrouten
export const login = (credentials) => API.post("/auth/login", credentials);
export const register = (credentials) => API.post("/auth/register", credentials);
export const requestPasswordReset = (email) => API.post("/auth/password-reset-request", { email });
export const resetPassword = (token, newPassword) => API.post("/auth/password-reset", { token, newPassword });

// Patient-Datenrouten
export const fetchPatientDetails = () => API.get("/patient/details");
export const fetchAccountDetails = () => API.get("/patient/account-details");
export const fetchPrescriptions = () => API.get("/patient/prescriptions");
export const fetchPrescriptionRequests = () => API.get("/patient/prescription-requests");
export const fetchInvoices = () => API.get("/patient/invoices");
export const fetchSessions = () => API.get("/patient/sessions");
export const fetchOnboardingDocuments = () => API.get("/patient/onboarding-documents");
export const fetchPatientFiles = () => API.get("/patient/files");
export const fetchNextSession = () => API.get("/patient/next-session");

// Generiere Download-Link
export const generateDownloadLink = async (path) => {
  try {
    const response = await API.get(`/patient/generate-download-link`, {
      params: { path },
    });
    return response.data.downloadLink;
  } catch (err) {
    console.error("Error generating download link:", err);
    throw new Error(err.response?.data?.message || "Failed to generate download link.");
  }
};

// Patienten-Datei upload
export const uploadPatientFile = async (formData) => {
  const response = await API.post("/patient/files/upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  
  return response.data;
};