import React, { useState, useEffect } from "react";
import {
  Input,
  Select,
  Option,
  Textarea,
  Button,
  Typography,
  Checkbox,
  Alert,
  Spinner,
} from "@material-tailwind/react";

const steps = [
  "Ihre Erkrankung",
  "Aktuelle Beschwerden",
  "Beeinträchtigung im Alltag",
  "Bisherige Therapie",
  "Aktuelle Medikation",
  "Vorerfahrung mit Cannabis",
  "Persönliche Daten",
  "Kontaktdaten",
  "Versorgunsstatus",
  "Gesundheitsstatus",
  "Abschluss",
];

const requiredFieldsPerStep = [
  // Schritt 1
  [
    "mainComplaint", 
    "diagnosisCode", 
    "complaintStartDate", 
    "diagnosisConfirmed",
    "therapyEntryLocation", 
    "alreadyCannabisPatient", 
    "sourceOfReference"
  ], 
  // Schritt 2
  [
    "averageComplaintStrength", 
    "maxComplaintStrength", 
    "tolerableComplaintStrength", 
    "complaintFrequency"
  ], 
  // Schritt 3
  [
    "complaintImpactMood", 
    "complaintImpactAppetite", 
    "complaintImpactWork", 
    "complaintImpactFocus", 
    "complaintImpactJoy", 
    "complaintImpactSexuality", 
    "complaintImpactResilience", 
    "complaintImpactSleep"
  ], 
  // Schritt 4
  [
    "previousDoctors", 
    "previousTreatments"
  ], 
  // Schritt 5
  [
    "currentMedications", 
    "previousMedications",
  ], 
  // Schritt 6
  [
    "previousCannabisUse", 
  ], 
  // Schritt 7
  [
    "gender", 
    "birthDate",
    "birthPlace",
    "idNumber",
  ], 
  // Schritt 8
  [
    "phone", 
    "street", 
    "houseNumber", 
    "postalCode", 
    "city", 
    "country"
  ], 
  // Schritt 9
  [
    "insuranceType", 
    "insuranceProvider", 
    "underLegalCustody", 
    "reducedEarningCapacity", 
    "severeDisability", 
    "appliedForPension", 
    "longTermSickLeave"
  ], 
  // Schritt 10
  [
    "height", 
    "weight",
    "knownAllergies",
    "smoker", 
    "drugUseLast5Years", 
    "alcoholFrequency"
  ], 
  // Schritt 11 (Abschluss)
  [
    "agreementCompleteAndHonest", 
    "agreementPrivacyPolicy", 
    "agreementCostInfo"
  ]
];

const TreatmentRequestForm = ({ onSubmit, patientDetails, onClose }) => {
  const [step, setStep] = useState(-1);
  const [formData, setFormData] = useState({
    mainComplaint: "",
    additionalComplaints: "",
    diagnosisCode: "",
    complaintStartDate: "",
    diagnosisConfirmed: "",
    hasAdditionalComplaints: "",
    additionalComplaints: "",
    therapyEntryLocation: "",
    alreadyCannabisPatient: "",
    sourceOfReference: "",
    averageComplaintStrength: "",
    maxComplaintStrength: "",
    tolerableComplaintStrength: "",
    complaintFrequency: "",
    complaintImpactMood: "",
    complaintImpactAppetite: "",
    complaintImpactWork: "",
    complaintImpactFocus: "",
    complaintImpactJoy: "",
    complaintImpactSexuality: "",
    complaintImpactResilience: "",
    complaintImpactSleep: "",
    previousDoctors: "",
    previousTreatments: "",
    currentMedications: "",
    currentMedicationsDetails: "",
    currentMedicationEffectiveness: "",
    currentMedicationSideEffects: "",
    previousMedications: "",
    previousMedicationsDetails: "",
    previousMedicationEffectiveness: "",
    previousMedicationSideEffects: "",
    previousCannabisUse: "",
    cannabisEffectiveness: "",
    recommendedCannabisTherapy: "",
    prescribingDoctor: "",
    lastPharmacy: "",
    lastPrescriptionDate: "",
    firstName: patientDetails.firstName || "",
    lastName: patientDetails.lastName || "",
    gender: "",
    birthDate: "",
    birthPlace: "",
    idNumber: "",
    insuranceType: "",
    insuranceProvider: "",
    underLegalCustody: "",
    email: patientDetails.email || "",
    phone: "",
    street: "",
    houseNumber: "",
    postalCode: "",
    city: "",
    country: "",
    reducedEarningCapacity: "",
    severeDisability: "",
    appliedForPension: "",
    longTermSickLeave: "",
    height: "",
    weight: "",
    knownAllergies: "",
    smoker: "",
    drugUseLast5Years: "",
    alcoholFrequency: "",
    emailHash: patientDetails.emailHash || "",
    agreementCompleteAndHonest: false,
    agreementPrivacyPolicy: false,
    agreementCostInfo: false,
  });
  const [successMessageVisible, setSuccessMessageVisible] = useState(false);
  const [errors, setErrors] = useState({});
  const [touchedFields, setTouchedFields] = useState({});
  const [isLoading, setIsLoading] = useState(false); // Spinner-Steuerung

  const validateField = (field, value) => {
    let error = "";

    // Validierungslogik
    if (requiredFieldsPerStep[step]?.includes(field)) {
      if (!value) {
        error = "Dieses Feld ist erforderlich.";
      }
    }

    // Aktualisiere Fehlerstatus nur bei Änderungen
    if (errors[field] !== error) {
      setErrors((prev) => ({ ...prev, [field]: error }));
    }

    return !error; // true = gültig, false = ungültig
  };

  const handleInputChange = (field, value) => {
    setFormData((prevData) => {
      if (prevData[field] === value) return prevData;
      return { ...prevData, [field]: value };
    });

    if (touchedFields[field]) {
      validateField(field, value);
    }
  };

  const handleFieldBlur = (field) => {
    // Markiere das Feld als "berührt"
    setTouchedFields((prev) => ({ ...prev, [field]: true }));

    // Führe die Validierung durch
    validateField(field, formData[field]);
  };

const isStepValid = (step) => {
  const fieldsToValidate = requiredFieldsPerStep[step] || [];
  const allFieldsValid = fieldsToValidate.every((field) => {
    const value = formData[field];
    let error = "";
    if (!value) {
      error = "Dieses Feld ist erforderlich.";
    }
    if (field.includes("Strength") && (value < 0 || value > 10)) {
      error = "Der Wert muss zwischen 0 und 10 liegen.";
    }
    return !error; // true = gültig
  });

  // Zusätzliche Logik für den letzten Schritt
  if (step === steps.length - 1) {
    const agreementFields = [
      "agreementCompleteAndHonest",
      "agreementPrivacyPolicy",
      "agreementCostInfo",
    ];
    const allCheckboxesChecked = agreementFields.every(
      (field) => formData[field] === true
    );
    return allFieldsValid && allCheckboxesChecked;
  }

  return allFieldsValid;
};

  const validateUpToCurrentStep = () => {
    let isValid = true;
    for (let i = 0; i <= step; i++) {
      if (!isStepValid(i)) {
        isValid = false;
        validateStepFields(i); // Fehler für alle Felder dieses Schritts setzen
      }
    }
    return isValid;
  };

  const nextStep = () => {
    if (validateUpToCurrentStep()) {
      setStep((prev) => prev + 1);
    } else {
      alert("Bitte füllen Sie alle erforderlichen Felder korrekt aus.");
    }
  };

  const prevStep = () => {
    setStep((prev) => {
      const newStep = prev > 0 ? prev - 1 : prev;
      validateStepFields(newStep); // Validierung für den Zielschritt durchführen
      return newStep;
    });
  };

  const validateStepFields = (stepIndex) => {
    const fieldsToValidate = requiredFieldsPerStep[stepIndex] || [];
    fieldsToValidate.forEach((field) => {
      validateField(field, formData[field]);
    });
  };

  useEffect(() => {
    if (patientDetails?.email) {
      setFormData((prevData) => ({
        ...prevData,
        email: patientDetails.email,
        firstName: patientDetails.firstName,
        lastName: patientDetails.lastName,
        emailHash: patientDetails.emailHash
      }));
    }
  }, [patientDetails]);

  useEffect(() => {
    if (step >= 0) {
      const fieldsToValidate = requiredFieldsPerStep[step] || [];
      const updatedErrors = {};
      let hasErrorChanged = false;

      fieldsToValidate.forEach((field) => {
        const value = formData[field];
        let error = "";
        if (!value) {
          error = "Dieses Feld ist erforderlich.";
        }
        if (field.includes("Strength") && (value < 0 || value > 10)) {
          error = "Der Wert muss zwischen 0 und 10 liegen.";
        }
        if (errors[field] !== error) {
          hasErrorChanged = true;
          updatedErrors[field] = error;
        }
      });

      if (hasErrorChanged) {
        setErrors((prevErrors) => ({ ...prevErrors, ...updatedErrors }));
      }
    }
  }, [step, formData, requiredFieldsPerStep]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const webhookUrl = "https://flow.scheffold-prei.de/webhook/treatmentRequest";
      const response = await fetch(webhookUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...formData }), // Stelle sicher, dass `email` im `formData` enthalten ist
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log(responseData.message);
        setSuccessMessageVisible(true);
      } else {
        throw new Error("Fehler beim Senden des Formulars.");
      }
    } catch (error) {
      console.error("Fehler:", error);
      alert("Ein unerwarteter Fehler ist aufgetreten.");
    } finally {
      setIsLoading(false);
    }
  };

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <div>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-500 mb-6">
              Im Folgenden geht es um Ihre Beschwerden und die medizinische Vorgeschichte. Bitte geben Sie
              stets alle bestehenden Erkrankungen an, auch wenn Sie sich wegen einer anderen Haupterkrankung
              vorstellen.
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
              {/* Haupterkrankung */}
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                  Haupterkrankung
                </label>
                <Select
                  label="Bitte auswählen"
                  value={formData.mainComplaint}
                  error={!formData?.mainComplaint} // Fehlerzustand bei leerem Wert
                  success={!!formData?.mainComplaint} // Erfolgszustand bei gültigem Wert
                  className="shadow-sm sm:text-sm/6"
                  onChange={(value) => handleInputChange("mainComplaint", value)}
                >
                  <Option value="Schmerzen">Schmerzen</Option>
                  <Option value="Schlafstörungen">Schlafstörungen</Option>
                  <Option value="Migräne">Migräne</Option>
                  <Option value="AD(H)S">AD(H)S</Option>
                  <Option value="Depressionen">Depressionen</Option>
                  <Option value="Andere">Andere</Option>
                </Select>
              </div>

              {/* Bezeichnung (ICD-Code) */}
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                  Bezeichnung (ggf. ICD-Code)
                </label>
                <Input
                  variant="outlined"
                  value={formData.diagnosisCode}
                  placeholder="z.B. G43.1 Migräne mit Aura"
                  className={`block w-full rounded-md py-1.5 text-gray-900 shadow-sm ${
                    !formData?.diagnosisCode
                      ? "!border !border-red-500 focus:ring-red-500/10"
                      : formData?.diagnosisCode
                      ? "!border !border-green-500 focus:ring-green-500/10"
                      : "!border !border-gray-300 focus:ring-gray-900/10"
                  } bg-white ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:!border-gray-900 focus:!border-t-gray-900`}
                  onChange={(e) => handleInputChange("diagnosisCode", e.target.value)}
                  labelProps={{
                    className: "hidden",
                  }}
                />
              </div>

              {/* Diagnose bestätigt */}
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                  Wurde die Erkrankung ärztlich diagnostiziert?
                </label>
                <Select
                  label="Bitte auswählen"
                  value={formData.diagnosisConfirmed}
                  error={!formData?.diagnosisConfirmed}
                  success={!!formData?.diagnosisConfirmed}
                  className="shadow-sm sm:text-sm/6"
                  onChange={(value) =>
                    handleInputChange("diagnosisConfirmed", value)
                  }
                >
                  <Option value="Ja">Ja</Option>
                  <Option value="Nein">Nein</Option>
                </Select>
              </div>

              {/* Weitere Erkrankungen */}
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                  Es bestehen weitere Erkrankungen
                </label>
                <Select
                  label="Bitte auswählen"
                  value={formData.hasAdditionalComplaints}
                  error={!formData?.hasAdditionalComplaints}
                  success={!!formData?.hasAdditionalComplaints}
                  className="shadow-sm sm:text-sm/6"
                  onChange={(value) => handleInputChange("hasAdditionalComplaints", value)}
                >
                  <Option value="Ja">Ja</Option>
                  <Option value="Nein">Nein</Option>
                </Select>
              </div>

              {/* Conditional Input: Weitere Erkrankungen Beschreibung */}
              {formData?.hasAdditionalComplaints === "Ja" && (
                <div className="col-span-1 md:col-span-2">
                  <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                    An welchen anderen Erkrankungen leiden Sie?
                  </label>
                  <Input
                    variant="outlined"
                    value={formData.additionalComplaints}
                    placeholder="z.B. Schlafstörungen, Schmerzen"
                    className={`block w-full rounded-md py-1.5 text-gray-900 shadow-sm ${
                      !formData?.additionalComplaints
                        ? "!border !border-red-500 focus:ring-red-500/10"
                        : formData?.additionalComplaints
                        ? "!border !border-green-500 focus:ring-green-500/10"
                        : "!border !border-gray-300 focus:ring-gray-900/10"
                    } bg-white ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:!border-gray-900 focus:!border-t-gray-900`}
                    onChange={(e) =>
                      handleInputChange("additionalComplaints", e.target.value)
                    }
                    labelProps={{
                      className: "hidden",
                    }}
                  />
                </div>
              )}

              {/* Beschwerden seit */}
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                  Beschwerden seit (Monat/Jahr)
                </label>
                <Input
                  variant="outlined"
                  value={formData.complaintStartDate}
                  placeholder="Monat/Jahr"
                  className={`block w-full rounded-md py-1.5 text-gray-900 shadow-sm ${
                    !formData?.complaintStartDate
                      ? "!border !border-red-500 focus:ring-red-500/10"
                      : formData?.complaintStartDate
                      ? "!border !border-green-500 focus:ring-green-500/10"
                      : "!border !border-gray-300 focus:ring-gray-900/10"
                  } bg-white ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:!border-gray-900 focus:!border-t-gray-900`}
                  onChange={(e) => handleInputChange("complaintStartDate", e.target.value)}
                  labelProps={{
                    className: "hidden",
                  }}
                />
              </div>

              {/* Wunschort Erstgespräch */}
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                  Wunschort Erstgespräch
                </label>
                <Select
                  label="Bitte auswählen"
                  value={formData.therapyEntryLocation}
                  error={!formData?.therapyEntryLocation}
                  success={!!formData?.therapyEntryLocation}
                  className="shadow-sm sm:text-sm/6"
                  onChange={(value) => handleInputChange("therapyEntryLocation", value)}
                >
                  <Option value="Online">Online</Option>
                  <Option value="Vor Ort">Vor Ort</Option>
                </Select>
              </div>

              {/* Bereits Cannabispatient */}
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                  Bereits Cannabispatient?
                </label>
                <Select
                  label="Bitte auswählen"
                  value={formData.alreadyCannabisPatient}
                  error={!formData?.alreadyCannabisPatient}
                  success={!!formData?.alreadyCannabisPatient}
                  className="shadow-sm sm:text-sm/6"
                  onChange={(value) =>
                    handleInputChange("alreadyCannabisPatient", value)
                  }
                >
                  <Option value="Ja">Ja</Option>
                  <Option value="Nein">Nein</Option>
                </Select>
              </div>

              {/* Quelle */}
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                  Wie sind Sie auf uns aufmerksam geworden?
                </label>
                <Input
                  variant="outlined"
                  value={formData.sourceOfReference}
                  placeholder="z.B. Google, Bekanntschaft"
                  className={`block w-full rounded-md py-1.5 text-gray-900 shadow-sm ${
                    !formData?.sourceOfReference
                      ? "!border !border-red-500 focus:ring-red-500/10"
                      : formData?.sourceOfReference
                      ? "!border !border-green-500 focus:ring-green-500/10"
                      : "!border !border-gray-300 focus:ring-gray-900/10"
                  } bg-white ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:!border-gray-900 focus:!border-t-gray-900`}
                  onChange={(e) =>
                    handleInputChange("sourceOfReference", e.target.value)
                  }
                  labelProps={{
                    className: "hidden",
                  }}
                />
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-500 mb-6">
              Im Folgenden bitten wir um Angabe der Stärke der Beschwerden auf einer Skala von 0 bis 10. Die
              Ziffer 0 steht für keine Beschwerden und 10 für maximal vorstellbare Beschwerden.
            </p>
              {/* Slider Inputs */}
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
                {[
                  {
                    label: "Wie stark waren die Beschwerden durchschnittlich im letzten Monat?",
                    name: "averageComplaintStrength",
                  },
                  {
                    label: "Wie stark waren die Beschwerden maximal im letzten Monat?",
                    name: "maxComplaintStrength",
                  },
                  {
                    label: "Mit welcher Stärke der Beschwerden könnten Sie einigermaßen leben?",
                    name: "tolerableComplaintStrength",
                  },
                ].map((item) => (
                  <div className="flex flex-col items-start" key={item.name}>
                    <label className="block mb-2 text-sm font-medium text-gray-800">
                      {item.label}
                    </label>
                    <input
                      type="range"
                      min={0}
                      max={10}
                      step={1}
                      name={item.name}
                      value={formData[item.name] || 0}
                      onChange={(e) => handleInputChange(item.name, e.target.value)}
                      className={`slider w-full appearance-none mb-2 h-2 rounded-lg cursor-pointer focus:outline-none ${
                        !formData[item.name]
                          ? "bg-red-200 focus:ring-red-500"
                          : formData[item.name] >= 0
                          ? "bg-green-200 focus:ring-green-500"
                          : "bg-gray-200 focus:ring-gray-900"
                      }`}
                      style={{
                        background: `linear-gradient(to right, ${
                          !formData[item.name] ? "red" : "black"
                        } 0%, ${
                          !formData[item.name] ? "red" : "black"
                        } ${(formData[item.name] / 10) * 100}%, #e0e0e0 ${
                          (formData[item.name] / 10) * 100
                        }%, #e0e0e0 100%)`,
                      }}
                    />
                    <span
                      className={`text-sm ${
                        !formData[item.name] ? "text-red-500" : "text-gray-500"
                      }`}
                    >
                      {formData[item.name] || 0}/10
                    </span>
                  </div>
                ))}

                {/* Select Box */}
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                    Wie häufig treten die Beschwerden der Haupterkrankung auf?
                  </label>
                  <Select
                    label="Bitte wählen"
                    value={formData.complaintFrequency}
                    error={!formData?.complaintFrequency} // Fehlerzustand bei leerem Wert
                    success={!!formData?.complaintFrequency} // Erfolgszustand bei gültigem Wert
                    className="shadow-sm sm:text-sm/6"
                    onChange={(value) => handleInputChange("complaintFrequency", value)}
                  >
                    <Option value="Andauernd">Andauernd</Option>
                    <Option value="Täglich">Täglich</Option>
                    <Option value="Wöchentlich">Wöchentlich</Option>
                    <Option value="Monatlich">Monatlich</Option>
                    <Option value="Seltener">Seltener</Option>
                  </Select>
                </div>
              </div>
          </div>
        );
      case 2:
        return (
          <div>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-500 mb-6">
              Wie stark beeinträchtigt die Erkrankung Ihr Wohlbefinden auf einer Skala von 0 bis 10? Die
              Ziffer 0 steht für keine Einschränkung und 10 für eine maximal vorstellbare Einschränkung.
            </p>
            {/* Additional Sliders */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
              {[
                { label: "Stimmung", name: "complaintImpactMood" },
                { label: "Appetit", name: "complaintImpactAppetite" },
                { label: "Beruf/Arbeit", name: "complaintImpactWork" },
                { label: "Konzentration", name: "complaintImpactFocus" },
                { label: "Lebensfreude", name: "complaintImpactJoy" },
                { label: "Sexualität", name: "complaintImpactSexuality" },
                { label: "Belastbarkeit", name: "complaintImpactResilience" },
                { label: "Schlaf", name: "complaintImpactSleep" },
              ].map((item) => (
                <div className="flex flex-col items-start" key={item.name}>
                  <label className="block mb-2 text-sm font-medium text-gray-800">
                    {item.label}
                  </label>
                  <input
                    type="range"
                    min={0}
                    max={10}
                    step={1}
                    name={item.name}
                    value={formData[item.name] || 0}
                    onChange={(e) => handleInputChange(item.name, e.target.value)}
                    className={`slider w-full appearance-none mb-2 h-2 rounded-lg cursor-pointer focus:outline-none ${
                      !formData[item.name]
                        ? "bg-red-200 focus:ring-red-500"
                        : formData[item.name] >= 0
                        ? "bg-green-200 focus:ring-green-500"
                        : "bg-gray-200 focus:ring-gray-900"
                    }`}
                    style={{
                      background: `linear-gradient(to right, ${
                        !formData[item.name] ? "red" : "black"
                      } 0%, ${
                        !formData[item.name] ? "red" : "black"
                      } ${(formData[item.name] / 10) * 100}%, #e0e0e0 ${
                        (formData[item.name] / 10) * 100
                      }%, #e0e0e0 100%)`,
                    }}
                  />
                  <span
                    className={`text-sm ${
                      !formData[item.name] ? "text-red-500" : "text-gray-500"
                    }`}
                  >
                    {formData[item.name] || 0}/10
                  </span>
                </div>
              ))}
            </div>
          </div>
        );
      case 3:
        return (
          <div>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-500 mb-6">
              Bitte geben Sie alle bisherigen Therapieansätze an.
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 gap-4">
              {/* Bereits besuchte Ärzte/Therapeuten */}
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                  Bereits besuchte Ärzte/Therapeuten
                </label>
                <Input
                  name="previousDoctors"
                  variant="outlined"
                  value={formData.previousDoctors}
                              placeholder="Geben Sie Ärzte oder Therapeuten an."
                  className={`block w-full rounded-md py-1.5 text-gray-900 shadow-sm ${
                    !formData?.previousDoctors
                      ? "!border !border-red-500 focus:ring-red-500/10"
                      : formData?.previousDoctors
                      ? "!border !border-green-500 focus:ring-green-500/10"
                      : "!border !border-gray-300 focus:ring-gray-900/10"
                  } bg-white ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:!border-gray-900 focus:!border-t-gray-900`}
                  onChange={(e) => handleInputChange("previousDoctors", e.target.value)}
                  labelProps={{
                    className: "hidden",
                  }}
                />
              </div>
              {/* Bereits versuchte Behandlungsmethoden */}
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                  Bereits versuchte Behandlungsmethoden
                </label>
                <Input
                  name="previousTreatments"
                  variant="outlined"
                  value={formData.previousTreatments}
                              placeholder="Geben Sie Ärzte oder Therapeuten an."
                  className={`block w-full rounded-md py-1.5 text-gray-900 shadow-sm ${
                    !formData?.previousTreatments
                      ? "!border !border-red-500 focus:ring-red-500/10"
                      : formData?.previousTreatments
                      ? "!border !border-green-500 focus:ring-green-500/10"
                      : "!border !border-gray-300 focus:ring-gray-900/10"
                  } bg-white ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:!border-gray-900 focus:!border-t-gray-900`}
                  onChange={(e) => handleInputChange("previousTreatments", e.target.value)}
                  labelProps={{
                    className: "hidden",
                  }}
                />
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <div>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-500 mb-6">
              Bitte geben Sie alle bisherigen und aktuellen Medikamente an.
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
              {/* Aktuelle Medikation */}
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                  Nehmen Sie aktuell Medikamente ein? *
                </label>
                <Select
                  label="bitte wählen"
                  value={formData.currentMedications}
                  error={!formData?.currentMedications}
                  success={!!formData?.currentMedications}
                  className="shadow-sm sm:text-sm/6"
                  onChange={(value) => handleInputChange("currentMedications", value)}
                >
                  <Option value="Ja">Ja</Option>
                  <Option value="Nein">Nein</Option>
                </Select>
              </div>

              {/* Conditional: Aktuelle Medikation Details */}
              {formData.currentMedications === "Ja" && (
                <>
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                      Welche Medikamente und seit wann? *
                    </label>
                    <Input
                      variant="outlined"
                      placeholder="Geben Sie Details an."
                      value={formData.currentMedicationDetails}
                      className={`block w-full rounded-md py-1.5 text-gray-900 shadow-sm ${
                        !formData?.currentMedicationDetails
                          ? "!border !border-red-500"
                          : formData?.currentMedicationDetails
                          ? "!border !border-green-500"
                          : "!border !border-gray-300"
                      } bg-white placeholder:text-gray-500 placeholder:opacity-100`}
                      onChange={(e) =>
                        handleInputChange("currentMedicationDetails", e.target.value)
                      }
                      labelProps={{
                        className: "hidden",
                      }}
                    />
                  </div>
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                      Wie sehr hilft die aktuelle Medikation? *
                    </label>
                    <Select
                      label="bitte wählen"
                      value={formData.currentMedicationEffectiveness}
                      error={!formData?.currentMedicationEffectiveness}
                      success={!!formData?.currentMedicationEffectiveness}
                      className="shadow-sm sm:text-sm/6"
                      onChange={(value) =>
                        handleInputChange("currentMedicationEffectiveness", value)
                      }
                    >
                      <Option value="Sehr gut">Sehr gut</Option>
                      <Option value="Mäßig">Mäßig</Option>
                      <Option value="Kaum">Kaum</Option>
                      <Option value="Überhaupt nicht">Überhaupt nicht</Option>
                    </Select>
                  </div>
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                      Welche Nebenwirkungen treten auf? *
                    </label>
                    <Input
                      variant="outlined"
                      placeholder="Beschreiben Sie die Nebenwirkungen."
                      value={formData.currentMedicationSideEffects}
                      className={`block w-full rounded-md py-1.5 text-gray-900 shadow-sm ${
                        !formData?.currentMedicationSideEffects
                          ? "!border !border-red-500"
                          : formData?.currentMedicationSideEffects
                          ? "!border !border-green-500"
                          : "!border !border-gray-300"
                      } bg-white placeholder:text-gray-500 placeholder:opacity-100`}
                      onChange={(e) =>
                        handleInputChange("currentMedicationSideEffects", e.target.value)
                      }
                      labelProps={{
                        className: "hidden",
                      }}
                    />
                  </div>
                </>
              )}

              {/* Wurde bereits andere Medikation verwendet? */}
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                  Wurden bereits andere Medikamente eingenommen? *
                </label>
                <Select
                  label="bitte wählen"
                  value={formData.previousMedications}
                  error={!formData?.previousMedications}
                  success={!!formData?.previousMedications}
                  className="shadow-sm sm:text-sm/6"
                  onChange={(value) => handleInputChange("previousMedications", value)}
                >
                  <Option value="Ja">Ja</Option>
                  <Option value="Nein">Nein</Option>
                </Select>
              </div>

              {/* Conditional: Details zu früherer Medikation */}
              {formData.previousMedications === "Ja" && (
                <>
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                      Welche Medikamente wurden früher eingenommen? *
                    </label>
                    <Input
                      variant="outlined"
                      placeholder="Geben Sie Details an."
                      value={formData.previousMedicationDetails}
                      className={`block w-full rounded-md py-1.5 text-gray-900 shadow-sm ${
                        !formData?.previousMedicationDetails
                          ? "!border !border-red-500"
                          : formData?.previousMedicationDetails
                          ? "!border !border-green-500"
                          : "!border !border-gray-300"
                      } bg-white placeholder:text-gray-500 placeholder:opacity-100`}
                      onChange={(e) =>
                        handleInputChange("previousMedicationDetails", e.target.value)
                      }
                      labelProps={{
                        className: "hidden",
                      }}
                    />
                  </div>
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                      Wie gut half die frühere Medikation? *
                    </label>
                    <Select
                      label="bitte wählen"
                      value={formData.previousMedicationEffectiveness}
                      error={!formData?.previousMedicationEffectiveness}
                      success={!!formData?.previousMedicationEffectiveness}
                      className="shadow-sm sm:text-sm/6"
                      onChange={(value) =>
                        handleInputChange("previousMedicationEffectiveness", value)
                      }
                    >
                      <Option value="Sehr gut">Sehr gut</Option>
                      <Option value="Mäßig">Mäßig</Option>
                      <Option value="Kaum">Kaum</Option>
                      <Option value="Überhaupt nicht">Überhaupt nicht</Option>
                    </Select>
                  </div>
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                      Welche Nebenwirkungen traten auf? *
                    </label>
                    <Input
                      variant="outlined"
                      placeholder="Beschreiben Sie die Nebenwirkungen."
                      value={formData.previousMedicationSideEffects}
                      className={`block w-full rounded-md py-1.5 text-gray-900 shadow-sm ${
                        !formData?.previousMedicationSideEffects
                          ? "!border !border-red-500"
                          : formData?.previousMedicationSideEffects
                          ? "!border !border-green-500"
                          : "!border !border-gray-300"
                      } bg-white placeholder:text-gray-500 placeholder:opacity-100`}
                      onChange={(e) =>
                        handleInputChange("previousMedicationSideEffects", e.target.value)
                      }
                      labelProps={{
                        className: "hidden",
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        );
      case 5:
        return (
          <div>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-500 mb-6">
              Bitte geben Sie Ihre bisherige Erfahrung mit Cannabis an.
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
              {/* Wurde bereits THC-haltiges Cannabis eingenommen? */}
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                  Wurde bereits THC-haltiges Cannabis eingenommen? *
                </label>
                <Select
                  label="bitte wählen"
                  value={formData.previousCannabisUse}
                  error={!formData?.previousCannabisUse}
                  success={!!formData?.previousCannabisUse}
                  className="shadow-sm sm:text-sm/6"
                  onChange={(value) => handleInputChange("previousCannabisUse", value)}
                >
                  <Option value="Ja">Ja</Option>
                  <Option value="Nein">Nein</Option>
                </Select>
              </div>

              {/* Conditional: Details bei Cannabisgebrauch */}
              {formData.previousCannabisUse === "Ja" && (
                <>
                  {/* Hat es die Beschwerden gelindert? */}
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                      Hat es die Beschwerden gelindert? *
                    </label>
                    <Select
                      label="bitte wählen"
                      value={formData.cannabisEffectiveness}
                      error={!formData?.cannabisEffectiveness}
                      success={!!formData?.cannabisEffectiveness}
                      className="shadow-sm sm:text-sm/6"
                      onChange={(value) => handleInputChange("cannabisEffectiveness", value)}
                    >
                      <Option value="Ja">Ja</Option>
                      <Option value="Nein">Nein</Option>
                    </Select>
                  </div>

                  {/* Wurde eine ärztliche Cannabistherapie empfohlen? */}
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                      Wurde eine ärztliche Cannabistherapie durchgeführt? *
                    </label>
                    <Select
                      label="bitte wählen"
                      value={formData.recommendedCannabisTherapy}
                      error={!formData?.recommendedCannabisTherapy}
                      success={!!formData?.recommendedCannabisTherapy}
                      className="shadow-sm sm:text-sm/6"
                      onChange={(value) =>
                        handleInputChange("recommendedCannabisTherapy", value)
                      }
                    >
                      <Option value="Ja">Ja</Option>
                      <Option value="Nein">Nein</Option>
                    </Select>
                  </div>

                  {/* Conditional: Details bei ärztlicher Cannabistherapie */}
                  {formData.recommendedCannabisTherapy === "Ja" && (
                    <>
                      {/* Von welchem Arzt wurde Cannabis verordnet? */}
                      <div>
                        <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                          Von welchem Arzt wurde Cannabis verordnet?
                        </label>
                        <Input
                          variant="outlined"
                          placeholder="Name des Arztes"
                          value={formData.prescribingDoctor}
                          className={`block w-full rounded-md py-1.5 text-gray-900 shadow-sm ${
                            !formData?.prescribingDoctor
                              ? "!border !border-red-500"
                              : formData?.prescribingDoctor
                              ? "!border !border-green-500"
                              : "!border !border-gray-300"
                          } bg-white placeholder:text-gray-500 placeholder:opacity-100`}
                          onChange={(e) =>
                            handleInputChange("prescribingDoctor", e.target.value)
                          }
                          labelProps={{
                            className: "hidden",
                          }}
                        />
                      </div>

                      {/* Bei welcher Apotheke haben Sie zuletzt Cannabis bezogen? */}
                      <div>
                        <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                          Bei welcher Apotheke haben Sie zuletzt Cannabis bezogen?
                        </label>
                        <Input
                          variant="outlined"
                          placeholder="Name der Apotheke"
                          value={formData.lastPharmacy}
                          className={`block w-full rounded-md py-1.5 text-gray-900 shadow-sm ${
                            !formData?.lastPharmacy
                              ? "!border !border-red-500"
                              : formData?.lastPharmacy
                              ? "!border !border-green-500"
                              : "!border !border-gray-300"
                          } bg-white placeholder:text-gray-500 placeholder:opacity-100`}
                          onChange={(e) =>
                            handleInputChange("lastPharmacy", e.target.value)
                          }
                          labelProps={{
                            className: "hidden",
                          }}
                        />
                      </div>

                      {/* Wann war die letzte Cannabis-Verschreibung? */}
                      <div>
                        <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                          Wann war die letzte Cannabis-Verschreibung?
                        </label>
                        <Input
                          type="date"
                          variant="outlined"
                          value={formData.lastPrescriptionDate}
                          className={`block w-full rounded-md py-1.5 text-gray-900 shadow-sm ${
                            !formData?.lastPrescriptionDate
                              ? "!border !border-red-500"
                              : formData?.lastPrescriptionDate
                              ? "!border !border-green-500"
                              : "!border !border-gray-300"
                          } bg-white placeholder:text-gray-500 placeholder:opacity-100`}
                          onChange={(e) =>
                            handleInputChange("lastPrescriptionDate", e.target.value)
                          }
                          labelProps={{
                            className: "hidden",
                          }}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        );
      case 6:
        return (
          <div>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-500 mb-6">
              Bitte geben Sie Ihre Persönlichen Daten bitte unbedingt vollständig und den Angaben im Ausweis entsprechend an.
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">

              {/* Geschlecht */}
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                  Geschlecht
                </label>
                <Select
                  label="bitte wählen"
                  value={formData.gender}
                  error={!formData?.gender}
                  success={!!formData?.gender}
                  className="shadow-sm sm:text-sm/6"
                  onChange={(value) => handleInputChange("gender", value)}
                >
                  <Option value="Männlich">Männlich</Option>
                  <Option value="Weiblich">Weiblich</Option>
                  <Option value="Divers">Divers</Option>
                </Select>
              </div>

              {/* Geburtsdatum */}
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                  Geburtsdatum
                </label>
                <Input
                  type="date"
                  variant="outlined"
                  value={formData.birthDate}
                  className={`block w-full rounded-md py-1.5 text-gray-900 shadow-sm ${
                    !formData?.birthDate
                      ? "!border !border-red-500"
                      : formData?.birthDate
                      ? "!border !border-green-500"
                      : "!border !border-gray-300"
                  } bg-white placeholder:text-gray-500 placeholder:opacity-100`}
                  onChange={(e) => handleInputChange("birthDate", e.target.value)}
                  labelProps={{
                    className: "hidden",
                  }}
                />
              </div>

              {/* Geburtsort */}
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                  Geburtsort
                </label>
                <Input
                  variant="outlined"
                  placeholder="Geburtsort"
                  value={formData.birthPlace}
                  className={`block w-full rounded-md py-1.5 text-gray-900 shadow-sm ${
                    !formData?.birthPlace
                      ? "!border !border-red-500"
                      : formData?.birthPlace
                      ? "!border !border-green-500"
                      : "!border !border-gray-300"
                  } bg-white placeholder:text-gray-500 placeholder:opacity-100`}
                  onChange={(e) => handleInputChange("birthPlace", e.target.value)}
                  labelProps={{
                    className: "hidden",
                  }}
                />
              </div>

              {/* Personalausweis-Nummer */}
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                  Personalausweis-Nummer
                </label>
                <Input
                  variant="outlined"
                  placeholder="Personalausweis-Nummer"
                  value={formData.idNumber}
                  className={`block w-full rounded-md py-1.5 text-gray-900 shadow-sm ${
                    !formData?.idNumber
                      ? "!border !border-red-500"
                      : formData?.idNumber
                      ? "!border !border-green-500"
                      : "!border !border-gray-300"
                  } bg-white placeholder:text-gray-500 placeholder:opacity-100`}
                  onChange={(e) => handleInputChange("idNumber", e.target.value)}
                  labelProps={{
                    className: "hidden",
                  }}
                />
              </div>
            </div>
          </div>
        );
      case 7:
        return (
          <div>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-500 mb-6">
              Bitte teilen Sie uns mit, wie wir Sie erreichen können. Bitte unbedingt die korrekte Meldeadresse angeben. Eine eventuell abweichende Rechnungsanschrift können Sie uns zu einem späteren Zeitpunkt mitteilen.
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
              {/* Land */}
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                  Land
                </label>
                <Select
                  label="bitte wählen"
                  value={formData.country}
                  error={!formData?.country}
                  success={!!formData?.country}
                  className="shadow-sm sm:text-sm/6"
                  onChange={(value) => handleInputChange("country", value)}
                >
                  <Option value="Deutschland">Deutschland</Option>
                  <Option value="Österreich">Österreich</Option>
                  <Option value="Schweiz">Schweiz</Option>
                  {/* Weitere Länder können hinzugefügt werden */}
                </Select>
              </div>

              {/* Telefonnummer */}
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                  Telefonnummer
                </label>
                <Input
                  type="tel"
                  variant="outlined"
                  placeholder="Telefonnummer"
                  value={formData.phone}
                  className={`block w-full rounded-md py-1.5 text-gray-900 shadow-sm ${
                    !formData?.phone
                      ? "!border !border-red-500"
                      : formData?.phone
                      ? "!border !border-green-500"
                      : "!border !border-gray-300"
                  } bg-white placeholder:text-gray-500 placeholder:opacity-100`}
                  onChange={(e) => handleInputChange("phone", e.target.value)}
                  labelProps={{
                    className: "hidden",
                  }}
                />
              </div>

              {/* Straße */}
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                  Straße
                </label>
                <Input
                  variant="outlined"
                  placeholder="Straße"
                  value={formData.street}
                  className={`block w-full rounded-md py-1.5 text-gray-900 shadow-sm ${
                    !formData?.street
                      ? "!border !border-red-500"
                      : formData?.street
                      ? "!border !border-green-500"
                      : "!border !border-gray-300"
                  } bg-white placeholder:text-gray-500 placeholder:opacity-100`}
                  onChange={(e) => handleInputChange("street", e.target.value)}
                  labelProps={{
                    className: "hidden",
                  }}
                />
              </div>

              {/* Hausnummer */}
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                  Hausnummer
                </label>
                <Input
                  variant="outlined"
                  placeholder="Hausnummer"
                  value={formData.houseNumber}
                  className={`block w-full rounded-md py-1.5 text-gray-900 shadow-sm ${
                    !formData?.houseNumber
                      ? "!border !border-red-500"
                      : formData?.houseNumber
                      ? "!border !border-green-500"
                      : "!border !border-gray-300"
                  } bg-white placeholder:text-gray-500 placeholder:opacity-100`}
                  onChange={(e) => handleInputChange("houseNumber", e.target.value)}
                  labelProps={{
                    className: "hidden",
                  }}
                />
              </div>

              {/* PLZ */}
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                  PLZ
                </label>
                <Input
                  variant="outlined"
                  placeholder="PLZ"
                  value={formData.postalCode}
                  className={`block w-full rounded-md py-1.5 text-gray-900 shadow-sm ${
                    !formData?.postalCode
                      ? "!border !border-red-500"
                      : formData?.postalCode
                      ? "!border !border-green-500"
                      : "!border !border-gray-300"
                  } bg-white placeholder:text-gray-500 placeholder:opacity-100`}
                  onChange={(e) => handleInputChange("postalCode", e.target.value)}
                  labelProps={{
                    className: "hidden",
                  }}
                />
              </div>

              {/* Ort */}
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                  Ort
                </label>
                <Input
                  variant="outlined"
                  placeholder="Ort"
                  value={formData.city}
                  className={`block w-full rounded-md py-1.5 text-gray-900 shadow-sm ${
                    !formData?.city
                      ? "!border !border-red-500"
                      : formData?.city
                      ? "!border !border-green-500"
                      : "!border !border-gray-300"
                  } bg-white placeholder:text-gray-500 placeholder:opacity-100`}
                  onChange={(e) => handleInputChange("city", e.target.value)}
                  labelProps={{
                    className: "hidden",
                  }}
                />
              </div>
            </div>
          </div>
        );
      case 8:
        return (
          <div>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-500 mb-6">
              Bitte machen Sie Angaben zu Ihrer Versicherung und formalen Beeinträchtigung.
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
              {/* Krankenversicherung */}
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                  Krankenversicherung
                </label>
                <Select
                  label="bitte wählen"
                  value={formData.insuranceType}
                  error={!formData?.insuranceType}
                  success={!!formData?.insuranceType}
                  className="shadow-sm sm:text-sm/6"
                  onChange={(value) => handleInputChange("insuranceType", value)}
                >
                  <Option value="Gesetzlich">Gesetzlich</Option>
                  <Option value="Privat">Privat</Option>
                </Select>
              </div>

              {/* Krankenkasse */}
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                  Krankenkasse
                </label>
                <Input
                  variant="outlined"
                  placeholder="Krankenkasse"
                  value={formData.insuranceProvider}
                  className={`block w-full rounded-md py-1.5 text-gray-900 shadow-sm ${
                    !formData?.insuranceProvider
                      ? "!border !border-red-500"
                      : formData?.insuranceProvider
                      ? "!border !border-green-500"
                      : "!border !border-gray-300"
                  } bg-white placeholder:text-gray-500 placeholder:opacity-100`}
                  onChange={(e) => handleInputChange("insuranceProvider", e.target.value)}
                  labelProps={{
                    className: "hidden",
                  }}
                />
              </div>

              {/* Gesetzliche Betreuung */}
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                  Besteht eine gesetzliche Betreuung?
                </label>
                <Select
                  label="bitte wählen"
                  value={formData.underLegalCustody}
                  error={!formData?.underLegalCustody}
                  success={!!formData?.underLegalCustody}
                  className="shadow-sm sm:text-sm/6"
                  onChange={(value) => handleInputChange("underLegalCustody", value)}
                >
                  <Option value="Ja">Ja</Option>
                  <Option value="Nein">Nein</Option>
                </Select>
              </div>

              {/* Besteht eine geminderte Erwerbsfähigkeit? */}
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                  Besteht eine geminderte Erwerbsfähigkeit? *
                </label>
                <Select
                  label="bitte wählen"
                  value={formData.reducedEarningCapacity}
                  error={!formData?.reducedEarningCapacity}
                  success={!!formData?.reducedEarningCapacity}
                  className="shadow-sm sm:text-sm/6"
                  onChange={(value) => handleInputChange("reducedEarningCapacity", value)}
                >
                  <Option value="Ja">Ja</Option>
                  <Option value="Nein">Nein</Option>
                </Select>
              </div>

              {/* Besteht ein Schwerbehindertengrad? */}
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                  Besteht ein Schwerbehindertengrad? *
                </label>
                <Select
                  label="bitte wählen"
                  value={formData.severeDisability}
                  error={!formData?.severeDisability}
                  success={!!formData?.severeDisability}
                  className="shadow-sm sm:text-sm/6"
                  onChange={(value) => handleInputChange("severeDisability", value)}
                >
                  <Option value="Ja">Ja</Option>
                  <Option value="Nein">Nein</Option>
                </Select>
              </div>

              {/* Wurde bereits ein Rentenantrag gestellt? */}
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                  Wurde bereits ein Rentenantrag gestellt? *
                </label>
                <Select
                  label="bitte wählen"
                  value={formData.appliedForPension}
                  error={!formData?.appliedForPension}
                  success={!!formData?.appliedForPension}
                  className="shadow-sm sm:text-sm/6"
                  onChange={(value) => handleInputChange("appliedForPension", value)}
                >
                  <Option value="Ja">Ja</Option>
                  <Option value="Nein">Nein</Option>
                </Select>
              </div>

              {/* Besteht eine längerfristige Arbeitsunfähigkeit? */}
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                  Besteht eine längerfristige Arbeitsunfähigkeit? *
                </label>
                <Select
                  label="bitte wählen"
                  value={formData.longTermSickLeave}
                  error={!formData?.longTermSickLeave}
                  success={!!formData?.longTermSickLeave}
                  className="shadow-sm sm:text-sm/6"
                  onChange={(value) => handleInputChange("longTermSickLeave", value)}
                >
                  <Option value="Ja">Ja</Option>
                  <Option value="Nein">Nein</Option>
                </Select>
              </div>
            </div>
          </div>
        );
      case 9:
        return (
          <div>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-500 mb-6">
              Bitte machen Sie Angaben zu Ihrem Gesundheitsprofil.
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {/* Körpergröße in cm */}
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                  Körpergröße in cm *
                </label>
                <div className="relative">
                  <Input
                    variant="outlined"
                    placeholder="Körpergröße in cm"
                    value={formData.height}
                    className={`block w-full pr-12 rounded-md py-1.5 text-gray-900 shadow-sm ${
                      !formData?.height
                        ? "!border !border-red-500"
                        : formData?.height
                        ? "!border !border-green-500"
                        : "!border !border-gray-300"
                    } bg-white placeholder:text-gray-500 placeholder:opacity-100`}
                    onChange={(e) => handleInputChange("height", e.target.value)}
                    labelProps={{
                      className: "hidden",
                    }}
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center px-5">
                    <span className="text-gray-500 sm:text-sm">cm</span>
                  </div>
                </div>
              </div>

              {/* Gewicht in kg */}
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                  Gewicht in kg *
                </label>
                <div className="relative">
                  <Input
                    variant="outlined"
                    placeholder="Gewicht in kg"
                    value={formData.weight}
                    className={`block w-full pr-12 rounded-md py-1.5 text-gray-900 shadow-sm ${
                      !formData?.weight
                        ? "!border !border-red-500"
                        : formData?.weight
                        ? "!border !border-green-500"
                        : "!border !border-gray-300"
                    } bg-white placeholder:text-gray-500 placeholder:opacity-100`}
                    onChange={(e) => handleInputChange("weight", e.target.value)}
                    labelProps={{
                      className: "hidden",
                    }}
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center px-5">
                    <span className="text-gray-500 sm:text-sm">kg</span>
                  </div>
                </div>
              </div>

              {/* Bekannte Allergien */}
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                  Bekannte Allergien
                </label>
                <Input
                  variant="outlined"
                  placeholder="Bekannte Allergien"
                  value={formData.knownAllergies}
                  className={`block w-full rounded-md py-1.5 text-gray-900 shadow-sm ${
                      !formData?.knownAllergies
                        ? "!border !border-red-500"
                        : formData?.knownAllergies
                        ? "!border !border-green-500"
                        : "!border !border-gray-300"
                  } bg-white placeholder:text-gray-500 placeholder:opacity-100`}
                  onChange={(e) => handleInputChange("knownAllergies", e.target.value)}
                  labelProps={{
                    className: "hidden",
                  }}
                />
              </div>

              {/* Sind Sie Raucher? */}
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                  Sind Sie Raucher? *
                </label>
                <Select
                  label="bitte wählen"
                  value={formData.smoker}
                  error={!formData?.smoker}
                  success={!!formData?.smoker}
                  className="shadow-sm sm:text-sm/6"
                  onChange={(value) => handleInputChange("smoker", value)}
                >
                  <Option value="Ja">Ja</Option>
                  <Option value="Nein">Nein</Option>
                </Select>
              </div>

              {/* Wurden innerhalb der letzten 5 Jahre Drogen konsumiert? */}
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                  Wurden innerhalb der letzten 5 Jahre Drogen (nicht THC) konsumiert? *
                </label>
                <Select
                  label="bitte wählen"
                  value={formData.drugUseLast5Years}
                  error={!formData?.drugUseLast5Years}
                  success={!!formData?.drugUseLast5Years}
                  className="shadow-sm sm:text-sm/6"
                  onChange={(value) => handleInputChange("drugUseLast5Years", value)}
                >
                  <Option value="Ja">Ja</Option>
                  <Option value="Nein">Nein</Option>
                </Select>
              </div>

              {/* Wie oft trinken Sie Alkohol? */}
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                  Wie oft trinken Sie Alkohol? *
                </label>
                <Select
                  label="bitte wählen"
                  value={formData.alcoholFrequency}
                  error={!formData?.alcoholFrequency}
                  success={!!formData?.alcoholFrequency}
                  className="shadow-sm sm:text-sm/6"
                  onChange={(value) => handleInputChange("alcoholFrequency", value)}
                >
                  <Option value="Nie">Nie</Option>
                  <Option value="Gelegentlich">Gelegentlich</Option>
                  <Option value="Wöchentlich">Wöchentlich</Option>
                  <Option value="Täglich">Täglich</Option>
                </Select>
              </div>
            </div>
          </div>
        );
      case 10:
        return (
          <div>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-500 mb-6">
              Bitte bestätigen Sie die folgenden Punkte, bevor Sie die Anfrage absenden.
            </p>
            <div className="space-y-6">
              {/* Erste Checkbox */}
              <Checkbox
                id="agreementCompleteAndHonest"
                checked={formData.agreementCompleteAndHonest}
                onChange={(e) => handleInputChange("agreementCompleteAndHonest", e.target.checked)}
                color="black"
                ripple={false}
                className="hover:before:content-none"
                label={
                  <div>
                    <Typography color="blue-gray" className="font-medium">
                      Wahrheitsgemäße Angaben
                    </Typography>
                    <Typography variant="small" color="gray" className="font-normal">
                      Ich bestätige, dass ich den medizinischen Fragebogen wahrheitsgemäß und
                      nach bestem Wissen und Gewissen hinsichtlich meiner Beschwerden und
                      medizinischen Vorgeschichte ausgefüllt habe.
                    </Typography>
                  </div>
                }
                containerProps={{
                  className: "-mt-8 !overflow-visible",
                }}
              />

              {/* Zweite Checkbox */}
              <Checkbox
                id="agreementPrivacyPolicy"
                checked={formData.agreementPrivacyPolicy}
                onChange={(e) => handleInputChange("agreementPrivacyPolicy", e.target.checked)}
                color="black"
                ripple={false}
                className="hover:before:content-none"
                label={
                  <div>
                    <Typography color="blue-gray" className="font-medium">
                      Datenschutz
                    </Typography>
                    <Typography variant="small" color="gray" className="font-normal">
                      Die Informationen zum{" "}
                      <a href="/datenschutz" className="font-semibold text-blue-500 hover:underline">
                        Datenschutz
                      </a>{" "}
                      habe ich gelesen und zur Kenntnis genommen. Ich erkläre mich mit der im
                      Dokument beschriebenen Nutzung und Handhabung meiner Daten einverstanden.
                    </Typography>
                  </div>
                }
                containerProps={{
                  className: "-mt-8 !overflow-visible",
                }}
              />

              {/* Dritte Checkbox */}
              <Checkbox
                id="agreementCostInfo"
                checked={formData.agreementCostInfo}
                onChange={(e) => handleInputChange("agreementCostInfo", e.target.checked)}
                color="black"
                ripple={false}
                className="hover:before:content-none"
                label={
                  <div>
                    <Typography color="blue-gray" className="font-medium">
                      Kosteninformationen
                    </Typography>
                    <Typography variant="small" color="gray" className="font-normal">
                      Ich habe die{" "}
                      <a href="/kosteninformationen" className="font-semibold text-blue-500 hover:underline">
                        Kosteninformationen
                      </a>{" "}
                      sorgfältig gelesen und verstanden. Ich nehme zur Kenntnis, dass alle
                      entstehenden Behandlungskosten ggfs. vollständig durch mich selbst zu tragen sind.
                    </Typography>
                  </div>
                }
                containerProps={{
                  className: "-mt-8 !overflow-visible",
                }}
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>

      {/* Initial Information Screen */}
      {step === -1 && (
        <>
              <h3 className="flex items-start mb-1 text-md font-semibold text-gray-900 dark:text-white">Wichtige Hinweise</h3>
              <p className="text-base leading-relaxed text-gray-500 dark:text-gray-500">
                Vielen Dank für Ihr Interesse an einer Behandlung bei EvoCan. Dieses Formular ist der erste Schritt, ein erstes Bild Ihrer Erkrankung und Situation zu bekommen.
                <br />
                Bitte machen Sie alle Angaben vollständig und detailliert. Ihre Informationen werden vertraulich behandelt und unterliegen der ärztlichen Schweigepflicht.
                Nach dem Absenden melden wir uns bei Ihnen, um die nächsten Schritte zu besprechen.
                <br />
                Klicken Sie auf <span className="font-medium">"Weiter"</span>, um mit dem Formular zu beginnen.
              </p>
              <div className="flex items-center mt-6">
                <button
                  onClick={nextStep}
                  className="ml-auto py-2 px-3 text-sm font-medium bg-gray-800 text-white rounded-lg hover:bg-gray-700"
                >
                  Start
                </button>
              </div>
        </>
      )}

      {/* Stepper */}
      {step >= 0 && (

        <div>

          {isLoading ? (
              <div className="flex items-center justify-center h-64">
                <Spinner className="h-8 w-8" />
              </div>
            ) : successMessageVisible ? (

                // Erfolgsmeldung
                <Alert className="bg-primary-800">
                  <Typography variant="h5" color="white">
                    Erfolgreich gesendet!
                  </Typography>
                  <Typography color="white" className="mt-2 mb-5 font-normal">
                    Ihre Behandlungsanfrage ist eingegangen. Bitte unterschreiben Sie das Dokument, das wir Ihnen per E-Mail zugesendet haben und buchen Sie ein Erstgespräch.
                  </Typography>
                  <div className="mt-4">
                    <button
                      onClick={onClose}
                      className="py-2 px-4 bg-gray-800 text-white rounded-lg shadow-sm hover:bg-gray-700"
                    >
                      Schließen
                    </button>
                  </div>
                </Alert>
              ) : (

                <>
                  <div className="mb-8">
                    {/* Header: Step Information */}
                    <p className="text-sm text-gray-500">
                      Schritt {step + 1} von {steps.length}
                    </p>
                    {/* Title of Current Step */}
                    <h2 className="text-md text-gray-800 font-bold">{steps[step]}</h2>
                    {/* Progress Bar */}
                    <div className="w-full bg-gray-200 h-2 rounded-full mt-3">
                      <div
                        className="bg-black h-2 rounded-full transition-all duration-300 ease-in-out"
                        style={{
                          width: `${((step + 1) / steps.length) * 100}%`,
                        }}
                      ></div>
                    </div>
                  </div>

                  {/* Step Content with Tailwind Transition */}
                  <div
                    className="transition-all duration-300 ease-in-out opacity-100 translate-y-0"
                    key={step}
                  >

                    {/* Formular anzeigen */}
                    <form onSubmit={handleSubmit}>
                      {renderStep()}

                      <div className="flex items-center mt-12">
                        {step > 0 && (
                          <button
                            type="button"
                            onClick={prevStep}
                            className="py-2 px-3 text-sm font-medium border border-gray-200 bg-white text-gray-800 rounded-lg shadow-sm hover:bg-gray-50"
                          >
                            Zurück
                          </button>
                        )}
                        <div className={`ml-auto ${step > 0 ? "ml-4" : ""}`}>
                          {step < steps.length - 1 ? (
                            <button
                              type="button"
                              onClick={nextStep}
                              disabled={!isStepValid()}
                              className="py-2 px-3 text-sm font-medium bg-gray-800 text-white rounded-lg hover:bg-gray-700"
                            >
                              Weiter
                            </button>
                          ) : (
                            <button
                              type="submit"
                              disabled={!isStepValid(step)}
                              className={`py-2 px-3 text-sm font-medium bg-gray-800 text-white rounded-lg ${
                                !isStepValid(step) ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-700"
                              }`}
                            >
                              Absenden
                            </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </>
            )}
        </div>
      )}
    </div>
  );
};

export default TreatmentRequestForm;