import React, { useState, useEffect } from "react";
import {
  Input,
  Select,
  Option,
  Textarea,
  Button,
  Typography,
  Checkbox,
  Alert,
  Spinner,
} from "@material-tailwind/react";


const openModal = (modalType) => {
  setActiveModal(modalType);
};

const closeModal = () => {
  setActiveModal(null);
};

const PrescriptionRequestForm = ({ onClose, onSubmit, openModal, patientDetails }) => {
  const [step, setStep] = useState(-1);
  const [formData, setFormData] = useState({
    email: patientDetails.email || "",
    firstName: patientDetails.firstName || "",
    lastName: patientDetails.lastName || "",
    id: patientDetails.id || "",
    sideEffectsOccurred: "",
    conditionPersistent: "",
    reliefContinued: "",
    medicationAvailable: "",
    pharmacySwitch: "",
    newPharmacy: "",
    alternativeMedicationName: "",
    notes: "",
    complaintImpactPain: "",
    complaintImpactSleep: "",
    complaintImpactMood: "",
    complaintImpactAppetite: "",
    complaintImpactWork: "",
    complaintImpactSexuality: "",
    complaintImpactResilience: "",
    complaintImpactFocus: "",
    agreementCostInfo: false,
    agreementCompleteAndHonest: false,
  });

  const requiredFieldsPerStep = [
    // Schritt 1
    [
      "sideEffectsOccurred",
      "conditionPersistent", 
      "reliefContinued", 
      "medicationAvailable"
    ], 
    // Schritt 2
    [
      "complaintImpactPain", 
      "complaintImpactSleep", 
      "complaintImpactMood", 
      "complaintImpactAppetite",
      "complaintImpactWork", 
      "complaintImpactSexuality", 
      "complaintImpactResilience", 
      "complaintImpactFocus"
    ], 
    // Schritt 2
    [
      "agreementCostInfo", 
      "agreementCompleteAndHonest"
    ]
  ];

  const [successMessageVisible, setSuccessMessageVisible] = useState(false);
  const [errors, setErrors] = useState({});
  const [touchedFields, setTouchedFields] = useState({});
  const [isLoading, setIsLoading] = useState(false); // Spinner-Steuerung

  const validateField = (field, value) => {
    let error = "";

    // Validierungslogik
    if (requiredFieldsPerStep[step]?.includes(field)) {
      if (!value) {
        error = "Dieses Feld ist erforderlich.";
      }
    }

    // Aktualisiere Fehlerstatus nur bei Änderungen
    if (errors[field] !== error) {
      setErrors((prev) => ({ ...prev, [field]: error }));
    }

    return !error; // true = gültig, false = ungültig
  };

  const handleInputChange = (field, value) => {
    setFormData((prevData) => {
      if (prevData[field] === value) return prevData;
      return { ...prevData, [field]: value };
    });

    if (touchedFields[field]) {
      validateField(field, value);
    }
  };

  const handleFieldBlur = (field) => {
    // Markiere das Feld als "berührt"
    setTouchedFields((prev) => ({ ...prev, [field]: true }));

    // Führe die Validierung durch
    validateField(field, formData[field]);
  };

const steps = [
  "Ihre Medikation",
  "Aktuelle Beschwerden",
  "Abschluss",
];

const isStepValid = (step) => {
  const fieldsToValidate = requiredFieldsPerStep[step] || [];
  const allFieldsValid = fieldsToValidate.every((field) => {
    const value = formData[field];
    let error = "";
    if (!value) {
      error = "Dieses Feld ist erforderlich.";
    }
    if (field.includes("Strength") && (value < 0 || value > 10)) {
      error = "Der Wert muss zwischen 0 und 10 liegen.";
    }
    return !error; // true = gültig
  });

  // Zusätzliche Logik für den letzten Schritt
  if (step === steps.length - 1) {
    const agreementFields = [
      "agreementCompleteAndHonest",
      "agreementCostInfo",
    ];
    const allCheckboxesChecked = agreementFields.every(
      (field) => formData[field] === true
    );
    return allFieldsValid && allCheckboxesChecked;
  }

  return allFieldsValid;
};

  const validateUpToCurrentStep = () => {
    let isValid = true;
    for (let i = 0; i <= step; i++) {
      if (!isStepValid(i)) {
        isValid = false;
        validateStepFields(i); // Fehler für alle Felder dieses Schritts setzen
      }
    }
    return isValid;
  };

  const nextStep = () => {
    if (validateUpToCurrentStep()) {
      setStep((prev) => prev + 1);
    } else {
      alert("Bitte füllen Sie alle erforderlichen Felder korrekt aus.");
    }
  };

  const prevStep = () => {
    setStep((prev) => {
      const newStep = prev > 0 ? prev - 1 : prev;
      validateStepFields(newStep); // Validierung für den Zielschritt durchführen
      return newStep;
    });
  };

  const validateStepFields = (stepIndex) => {
    const fieldsToValidate = requiredFieldsPerStep[stepIndex] || [];
    fieldsToValidate.forEach((field) => {
      validateField(field, formData[field]);
    });
  };

  useEffect(() => {
    if (patientDetails?.email || patientDetails?.id || patientDetails?.firstName || patientDetails?.lastName) {
      setFormData((prevData) => ({
        ...prevData,
        email: patientDetails.email, // Email aus den geladenen Patientendetails übernehmen
        firstName: patientDetails.firstName, // firstName aus den geladenen Patientendetails übernehmen
        lastName: patientDetails.lastName, // lastName aus den geladenen Patientendetails übernehmen
        id: patientDetails.id, // ID aus den geladenen Patientendetails übernehmen
      }));
    }
  }, [patientDetails]);

  useEffect(() => {
    if (step >= 0) {
      const fieldsToValidate = requiredFieldsPerStep[step] || [];
      const updatedErrors = {};
      let hasErrorChanged = false;

      fieldsToValidate.forEach((field) => {
        const value = formData[field];
        let error = "";
        if (!value) {
          error = "Dieses Feld ist erforderlich.";
        }
        if (field.includes("Strength") && (value < 0 || value > 10)) {
          error = "Der Wert muss zwischen 0 und 10 liegen.";
        }
        if (errors[field] !== error) {
          hasErrorChanged = true;
          updatedErrors[field] = error;
        }
      });

      if (hasErrorChanged) {
        setErrors((prevErrors) => ({ ...prevErrors, ...updatedErrors }));
      }
    }
  }, [step, formData, requiredFieldsPerStep]);

const handleSubmit = async (e) => {
  e.preventDefault();
  setIsLoading(true);

  try {
    const webhookUrl = "https://flow.scheffold-prei.de/webhook/prescriptionRequest";
    const response = await fetch(webhookUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData), // Anfrage senden
    });

    if (response.ok) {
      const responseData = await response.json();
      setSuccessMessageVisible(true);
    } else {
      const errorText = await response.text(); // Fehlerdetails aus der Antwort lesen
      throw new Error("Fehler beim Senden des Formulars. Details: " + errorText);
    }
  } catch (error) {
    alert("Ein Fehler ist aufgetreten: " + error.message);
  } finally {
    setIsLoading(false);
  }
};

  // Prüfen, ob eine kritische Antwort negativ ist
  const isCriticalAnswer = () =>
    formData.sideEffectsOccurred === "Ja" ||
    formData.conditionPersistent === "Nein" ||
    formData.reliefContinued === "Nein";

const renderStep = () => {
    // Normale Eingabefelder, wenn keine kritische Antwort gegeben wurde
    switch (step) {
      case 0:
        return (
        <>
          <div>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-500 mb-6">
              Bitte machen Sie Angaben zur Medikation.
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                  Es sind unerwünschte Wirkungen aufgetreten
                </label>
                <Select
                  label="Bitte auswählen"
                  value={formData.sideEffectsOccurred}
                  error={!formData?.sideEffectsOccurred}
                  success={!!formData?.sideEffectsOccurred}
                  className="shadow-sm sm:text-sm/6"
                  onChange={(value) => handleInputChange("sideEffectsOccurred", value)}
                >
                  <Option value="Ja">Ja</Option>
                  <Option value="Nein">Nein</Option>
                </Select>
              </div>

              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                  Die Erkrankung besteht weiterhin
                </label>
                <Select
                  label="Bitte auswählen"
                  value={formData.conditionPersistent}
                  error={!formData?.conditionPersistent}
                  success={!!formData?.conditionPersistent}
                  className="shadow-sm sm:text-sm/6"
                  onChange={(value) => handleInputChange("conditionPersistent", value)}
                >
                  <Option value="Ja">Ja</Option>
                  <Option value="Nein">Nein</Option>
                </Select>
              </div>

              <div>
                <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                  Die Beschwerden sind weiterhin gelindert
                </label>
                <Select
                  label="Bitte auswählen"
                  value={formData.reliefContinued}
                  error={!formData?.reliefContinued}
                  success={!!formData?.reliefContinued}
                  className="shadow-sm sm:text-sm/6"
                  onChange={(value) => handleInputChange("reliefContinued", value)}
                >
                  <Option value="Ja">Ja</Option>
                  <Option value="Nein">Nein</Option>
                </Select>
              </div>

                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                    Zuletzt verschriebenes Präparat ist verfügbar
                  </label>
                  <Select
                    label="Bitte auswählen"
                    value={formData.medicationAvailable}
                    error={!formData?.medicationAvailable}
                    success={!!formData?.medicationAvailable}
                    className="shadow-sm sm:text-sm/6"
                    onChange={(value) => handleInputChange("medicationAvailable", value)}
                  >
                    <Option value="Ja">Ja</Option>
                    <Option value="Nein">Nein</Option>
                  </Select>
                </div>

                  {formData.medicationAvailable === "Nein" && (
                    <>
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                        Vorübergehend Apotheke oder Präparat ändern
                      </label>
                      <Select
                        label="Bitte auswählen"
                        value={formData.pharmacySwitch}
                        error={!formData?.pharmacySwitch}
                        success={!!formData?.pharmacySwitch}
                        className="shadow-sm sm:text-sm/6"
                        onChange={(value) => handleInputChange("pharmacySwitch", value)}
                      >
                        <Option value="Apotheke wechseln">Apotheke wechseln</Option>
                        <Option value="Alternativpräparat erhalten">
                          Alternativpräparat erhalten
                        </Option>
                      </Select>
                    </div>

                      {formData.pharmacySwitch === "Apotheke wechseln" && (
                      <div>
                        <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                          Alternative Apotheke
                        </label>
                        <Input
                          variant="outlined"
                          value={formData.newPharmacy}
                          placeholder="Name und Ort der neuen Apotheke"
                          className={`block w-full rounded-md py-1.5 text-gray-900 shadow-sm ${
                            !formData?.newPharmacy
                              ? "!border !border-red-500 focus:ring-red-500/10"
                              : formData?.newPharmacy
                              ? "!border !border-green-500 focus:ring-green-500/10"
                              : "!border !border-gray-300 focus:ring-gray-900/10"
                          } bg-white ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:!border-gray-900 focus:!border-t-gray-900`}
                          onChange={(e) =>
                            handleInputChange("newPharmacy", e.target.value)
                          }
                          labelProps={{
                            className: "hidden",
                          }}
                        />
                      </div>
                      )}
                      {formData.pharmacySwitch === "Alternativpräparat erhalten" && (
                      <div>    
                        <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                          Vorschlag eines Alternativpräparats
                        </label>
                        <Input
                          variant="outlined"
                          value={formData.alternativeMedicationName}
                          placeholder="Vorschlag wird berücksichtigt, Auswahl durch Arzt"
                          className={`block w-full rounded-md py-1.5 text-gray-900 shadow-sm ${
                            !formData?.alternativeMedicationName
                              ? "!border !border-red-500 focus:ring-red-500/10"
                              : formData?.alternativeMedicationName
                              ? "!border !border-green-500 focus:ring-green-500/10"
                              : "!border !border-gray-300 focus:ring-gray-900/10"
                          } bg-white ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:!border-gray-900 focus:!border-t-gray-900`}
                          onChange={(e) =>
                            handleInputChange("alternativeMedicationName", e.target.value)
                          }
                          labelProps={{
                            className: "hidden",
                          }}
                        />
                      </div>         
                      )}
                    </>
                  )}
                  <div className="col-span-2">  
                    <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                      Besondere Anmerkungen
                    </label>
                    <Input
                      variant="outlined"
                      value={formData.notes}
                      placeholder="Anmerkungen, Wünsche etc."
                      className={`block w-full rounded-md py-1.5 text-gray-900 shadow-sm ${
                        !formData?.notes
                          ? "!border !border-red-500 focus:ring-red-500/10"
                          : formData?.notes
                          ? "!border !border-green-500 focus:ring-green-500/10"
                          : "!border !border-gray-300 focus:ring-gray-900/10"
                      } bg-white ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:!border-gray-900 focus:!border-t-gray-900`}
                      onChange={(e) =>
                        handleInputChange("notes", e.target.value)
                      }
                      labelProps={{
                        className: "hidden",
                      }}
                    />
                  </div>
            </div>
          </div>
          </>
        );
      case 1:
        return (
          <div>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-500 mb-6">
              Wie stark hat die Erkrankung innerhalb der letzten 4 Wochen Ihr Wohlbefinden auf einer Skala von 0 bis 10 beeinflusst? Die
              Ziffer 0 steht für keine Einschränkung und 10 für eine maximal vorstellbare Einschränkung.
            </p>
            {/* Sliders */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
              {[
                { label: "Schmerzen", name: "complaintImpactPain" },
                { label: "Schlaf", name: "complaintImpactSleep" },
                { label: "Stimmung", name: "complaintImpactMood" },
                { label: "Appetit", name: "complaintImpactAppetite" },
                { label: "Beruf/Arbeit", name: "complaintImpactWork" },
                { label: "Sexualität", name: "complaintImpactSexuality" },
                { label: "Körperliche Belastbarkeit", name: "complaintImpactResilience" },
                { label: "Konzentration", name: "complaintImpactFocus" },
              ].map((item) => (
                <div className="flex flex-col items-start" key={item.name}>
                  <label className="block mb-2 text-sm font-medium text-gray-800">
                    {item.label}
                  </label>
                  <input
                    type="range"
                    min={0}
                    max={10}
                    step={1}
                    name={item.name}
                    value={formData[item.name] || 0}
                    onChange={(e) => handleInputChange(item.name, e.target.value)}
                    className={`slider w-full appearance-none mb-2 h-2 rounded-lg cursor-pointer focus:outline-none ${
                      !formData[item.name]
                        ? "bg-red-200 focus:ring-red-500"
                        : formData[item.name] >= 0
                        ? "bg-green-200 focus:ring-green-500"
                        : "bg-gray-200 focus:ring-gray-900"
                    }`}
                    style={{
                      background: `linear-gradient(to right, ${
                        !formData[item.name] ? "red" : "black"
                      } 0%, ${
                        !formData[item.name] ? "red" : "black"
                      } ${(formData[item.name] / 10) * 100}%, #e0e0e0 ${
                        (formData[item.name] / 10) * 100
                      }%, #e0e0e0 100%)`,
                    }}
                  />
                  <span
                    className={`text-sm ${
                      !formData[item.name] ? "text-red-500" : "text-gray-500"
                    }`}
                  >
                    {formData[item.name] || 0}/10
                  </span>
                </div>
              ))}
            </div>
          </div>
        );
      case 2:
        return (
          <div>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-500 mb-6">
              Bitte bestätigen Sie die folgenden Punkte, bevor Sie die Anfrage absenden.
            </p>
            <div className="space-y-6">
              {/* Erste Checkbox */}
              <Checkbox
                id="agreementCompleteAndHonest"
                checked={formData.agreementCompleteAndHonest}
                onChange={(e) => handleInputChange("agreementCompleteAndHonest", e.target.checked)}
                color="black"
                ripple={false}
                className="hover:before:content-none"
                label={
                  <div>
                    <Typography color="blue-gray" className="font-medium">
                      Wahrheitsgemäße Angaben
                    </Typography>
                    <Typography variant="small" color="gray" className="font-normal">
                      Ich bestätige, dass ich das Formular wahrheitsgemäß ausgefüllt habe.
                    </Typography>
                  </div>
                }
                containerProps={{
                  className: "-mt-4 !overflow-visible",
                }}
              />

              {/* Zweite Checkbox */}
              <Checkbox
                id="agreementCostInfo"
                checked={formData.agreementCostInfo}
                onChange={(e) => handleInputChange("agreementCostInfo", e.target.checked)}
                color="black"
                ripple={false}
                className="hover:before:content-none"
                label={
                  <div>
                    <Typography color="blue-gray" className="font-medium">
                      Kosteninformation
                    </Typography>
                    <Typography variant="small" color="gray" className="font-normal">
                      Über die entstehenden Kosten der Folgeverschreibung wurde ich aufgeklärt.
                    </Typography>
                  </div>
                }
                containerProps={{
                  className: "-mt-4 !overflow-visible",
                }}
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

const renderButtons = () => {
  return (
    <div className="flex items-center mt-12">
      {/* Zurück-Button anzeigen */}
      {step > 0 && (
        <button
          type="button"
          onClick={prevStep}
          className="py-2 px-3 text-sm font-medium border border-gray-200 bg-white text-gray-800 rounded-lg shadow-sm hover:bg-gray-50"
        >
          Zurück
        </button>
      )}

      {/* Weiter/Videosprechstunde Button */}
      <div className={`ml-auto ${step > 0 ? "ml-4" : ""}`}>
        {isCriticalAnswer() ? (
          <div className="flex flex-col items-end">
            {/* Hinweistext */}
            <div className="mb-4 p-4 bg-gray-100 text-gray-800 rounded-lg">
              <Typography className="font-medium">
                Basierend auf Ihren Antworten ist eine erneute Videosprechstunde notwendig, 
                um Ihre Therapie weiter zu besprechen.
              </Typography>
              <Typography variant="small" className="mt-2 text-gray-800">
                Grund: Unerwünschte Wirkungen, unzureichende Linderung oder fehlende Verbesserung 
                des Zustands wurden angegeben.
              </Typography>
            </div>
            {/* "Videosprechstunde buchen"-Button */}
            <button
              type="button"
                onClick={() => {
                  onClose(); // Schließe das aktuelle Modal
                  setTimeout(() => {
                    openModal("session"); // Öffne das Videosprechstunden-Modal
                  }, 300); // Verzögerung für die Übergabe
                }}
              className="py-2 px-3 text-sm font-medium bg-gray-800 text-white rounded-lg hover:bg-gray-700"
            >
              Videosprechstunde buchen
            </button>
          </div>
        ) : step < steps.length - 1 ? (
          // "Weiter"-Button
          <button
            type="button"
            onClick={nextStep}
            disabled={!isStepValid()}
            className="py-2 px-3 text-sm font-medium bg-gray-800 text-white rounded-lg hover:bg-gray-700"
          >
            Weiter
          </button>
        ) : (
          // "Absenden"-Button
          <button
            type="submit"
            disabled={!isStepValid(step)}
            className={`py-2 px-3 text-sm font-medium bg-gray-800 text-white rounded-lg ${
              !isStepValid(step) ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-700"
            }`}
          >
            Absenden
          </button>
        )}
      </div>
    </div>
  );
};

return (
  <div>
      {step === -1 && (
        <>
          <h3 className="flex items-start mb-1 text-md font-semibold text-gray-900 dark:text-white">Wichtige Hinweise</h3>
          <p className="text-base leading-relaxed text-gray-500 dark:text-gray-500">
            Für die Verschreibung eines Folgerezeptes nach Ihrer regulären
            Videosprechstunde bitte das nachfolgende Formular wahrheitsgemäß
            ausfüllen. 
            <br />
            Rezepte werden immer Sonntags bearbeitet und Dienstags an die
            Apotheken übermittelt.
            <br /><br />
            Klicken Sie auf <span className="font-medium">"Start"</span>, um mit dem Formular zu beginnen.
          </p>
          <div className="flex items-center mt-6">
            <button
              onClick={() => setStep(0)}
              className="ml-auto py-2 px-3 text-sm font-medium bg-gray-800 text-white rounded-lg hover:bg-gray-700"
            >
              Start
            </button>
          </div>
        </>
      )}
      
      {/* Stepper */}
      {step >= 0 && (

        <div>

          {isLoading ? (
              <div className="flex items-center justify-center h-64">
                <Spinner className="h-8 w-8" />
              </div>
            ) : successMessageVisible ? (

                // Erfolgsmeldung
                <Alert className="bg-primary-800">
                  <Typography variant="h5" color="white">
                    Erfolgreich gesendet!
                  </Typography>
                  <Typography color="white" className="mt-2 mb-5 font-normal">
                    Ihre Rezeptanfrage ist eingegangen. Sie erhalten im Verlauf eine E-Mail mit dem Rezept-Vordruck zur Bestellung bei der Apotheke.
                  </Typography>
                  <div className="mt-4">
                    <button
                      onClick={onClose}
                      className="py-2 px-4 bg-gray-800 text-white rounded-lg shadow-sm hover:bg-gray-700"
                    >
                      Schließen
                    </button>
                  </div>
                </Alert>
              ) : (

                <>
                  <div className="mb-8">
                    {/* Header: Step Information */}
                    <p className="text-sm text-gray-500">
                      Schritt {step + 1} von {steps.length}
                    </p>
                    {/* Title of Current Step */}
                    <h2 className="text-md text-gray-800 font-bold">{steps[step]}</h2>
                    {/* Progress Bar */}
                    <div className="w-full bg-gray-200 h-2 rounded-full mt-3">
                      <div
                        className="bg-black h-2 rounded-full transition-all duration-300 ease-in-out"
                        style={{
                          width: `${((step + 1) / steps.length) * 100}%`,
                        }}
                      ></div>
                    </div>
                  </div>

                  {/* Step Content with Tailwind Transition */}
                  <div
                    className="transition-all duration-300 ease-in-out opacity-100 translate-y-0"
                    key={step}
                  >

                    {/* Formular anzeigen */}
                    <form onSubmit={handleSubmit}>
                      {renderStep()}
                      {renderButtons()}
                  </form>
                </div>
              </>
            )}
        </div>
      )}
  </div>
);
};

export default PrescriptionRequestForm;