// Hilfsfunktion: Datum formatieren
export const formatDate = (filename) => {
  const dateStr = filename.substring(0, 6); // Extrahiert die ersten sechs Zeichen des Dateinamens
  const day = dateStr.substring(4, 6); // Extrahiert den Tag
  const month = dateStr.substring(2, 4); // Extrahiert den Monat
  const year = `20${dateStr.substring(0, 2)}`; // Extrahiert das Jahr und fügt "20" hinzu
  return `${day}.${month}.${year}`; // Gibt das Datum im Format TT.MM.JJJJ zurück
};

// Hilfsfunktion: Betrag extrahieren
export const extractAmount = (filename) => {
  const underscoreIndex = filename.indexOf("_");
  if (underscoreIndex === -1) {
    return "Betrag nicht gefunden";
  }
  const euros = filename.substring(underscoreIndex - 3, underscoreIndex);
  const cents = filename.substring(underscoreIndex + 1, underscoreIndex + 3);
  return `${euros},${cents} €`;
};

// Hilfsfunktion: Rechnungsnummer generieren
export const generateInvoiceNumber = (filename) => {
  const nameWithoutExtension = filename.substring(0, filename.lastIndexOf("."));
  const lastSpaceIndex = nameWithoutExtension.lastIndexOf(" ");
  const patientId = nameWithoutExtension.substring(lastSpaceIndex + 1); // Extrahiert die Patient-ID
  const dateStr = filename.substring(0, 6); // Datum extrahieren (erste 6 Zeichen)
  return `${dateStr}-${patientId}`;
};

// Hilfsfunktion: Sitzungen sortieren
export const sortSessions = (sessions, sortField, sortOrder) => {
  return sessions.sort((a, b) => {
    const sessionDateA = new Date(a.name.split(" ")[0]);
    const sessionDateB = new Date(b.name.split(" ")[0]);

    if (sortField === "date") {
      return sortOrder === "desc" ? sessionDateB - sessionDateA : sessionDateA - sessionDateB;
    }

    const typeA = a.name.split(" ")[1];
    const typeB = b.name.split(" ")[1];
    return sortOrder === "desc" ? typeB.localeCompare(typeA) : typeA.localeCompare(typeB);
  });
};

export const filterAndSortData = ({
  data,
  type,
  searchQuery = "",
  filterType = "",
  sortField = "date",
  sortOrder = "desc",
  formatDate,
  extractAmount,
  generateInvoiceNumber,
}) => {
  // Sicherstellen, dass der Datentyp ein Array ist
  if (!data[type] || !Array.isArray(data[type])) {
    return [];
  }

  // Daten filtern
  const filtered = data[type].filter((item) => {
    const itemDate = formatDate(item.name); // Formatierte Datum
    const matchesSearchQuery =
      itemDate.toLowerCase().includes(searchQuery.toLowerCase()) || // Filter nach Datum
      item.name.toLowerCase().includes(searchQuery.toLowerCase()); // Filter nach Name

    return matchesSearchQuery;
  });

  // Daten sortieren
  return filtered.sort((a, b) => {
    let valueA = "";
    let valueB = "";

    if (sortField === "date") {
      // Datum sortieren
      valueA = new Date(formatDate(a.name).split(".").reverse().join("-"));
      valueB = new Date(formatDate(b.name).split(".").reverse().join("-"));
    } else if (sortField === "amount") {
      // Betrag sortieren
      valueA = parseFloat(extractAmount(a.name).replace(",", ".").replace(" €", "")) || 0;
      valueB = parseFloat(extractAmount(b.name).replace(",", ".").replace(" €", "")) || 0;
    } else if (sortField === "invoice") {
      // Rechnungsnummer sortieren
      valueA = generateInvoiceNumber(a.name).toLowerCase() || "";
      valueB = generateInvoiceNumber(b.name).toLowerCase() || "";
    } else if (sortField === "type") {
      // Typ sortieren (aus Name extrahieren)
      valueA = a.name.split(" ")[1]?.toLowerCase() || ""; // Extrahiert den Typ aus dem Namen
      valueB = b.name.split(" ")[1]?.toLowerCase() || "";
    } else if (sortField === "due") {
      // Fälligkeitsdatum sortieren
      const dueA = new Date(formatDate(a.name).split(".").reverse().join("-"));
      dueA.setDate(dueA.getDate() + 14);
      const dueB = new Date(formatDate(b.name).split(".").reverse().join("-"));
      dueB.setDate(dueB.getDate() + 14);
      valueA = dueA;
      valueB = dueB;
    } else if (sortField === "status") {
      // Status alphabetisch sortieren
      valueA = (a[sortField] || "").toString().toLowerCase();
      valueB = (b[sortField] || "").toString().toLowerCase();
    } else {
      return 0; // Keine Sortierung
    }

    // Sicherstellen, dass Werte vergleichbar sind
    if (typeof valueA === "string" && typeof valueB === "string") {
      return sortOrder === "desc"
        ? valueB.localeCompare(valueA)
        : valueA.localeCompare(valueB);
    }

    // Für numerische oder andere Vergleiche
    return sortOrder === "desc" ? valueB - valueA : valueA - valueB;
  });
};