import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  fetchPatientDetails,
  fetchPrescriptions,
  fetchPrescriptionRequests,
  fetchInvoices,
  fetchSessions,
  fetchNextSession,
  generateDownloadLink,
} from "../services/api";
import {
  formatDate,
  extractAmount,
  generateInvoiceNumber,
  filterAndSortData,
} from "../utils/helpers";
import { sessionTypeMap } from "../utils/sessionTypeMap";

import SessionsTable from "../components/Tables/SessionsTable";
import PrescriptionsTable from "../components/Tables/PrescriptionsTable";
import PrescriptionRequestsTable from "../components/Tables/PrescriptionRequestsTable";
import InvoicesTable from "../components/Tables/InvoicesTable";
import SessionInfoCard from "../components/Cards/SessionInfoCard";
import PrescriptionRequestCard from "../components/Cards/PrescriptionRequestCard";
import AppointmentBookingCard from "../components/Cards/AppointmentBookingCard";
import OnboardingDocumentsCard from "../components/Cards/OnboardingDocumentsCard";
import NextSessionCard from "../components/Cards/NextSessionCard";
import UnpaidInvoicesCard from "../components/Cards/UnpaidInvoicesCard";
import OnboardingProgressCard from "../components/Cards/OnboardingProgressCard";

const Dashboard = ({ token }) => {
  const [patientDetails, setPatientDetails] = useState({});
  const [prescriptionRequests, setPrescriptionRequests] = useState([]);
  const [prescriptions, setPrescriptions] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [nextSession, setNextSession] = useState(null);

  useEffect(() => {
    if (!token) {
      navigate("/login"); // Benutzer zurück zur Login-Seite leiten
    }
  }, [token, navigate]);

  const handleDownload = async (path) => {
    try {
      const downloadLink = await generateDownloadLink(path, token);
      const newTab = window.open(downloadLink, "_blank");
      if (!newTab) {
      }
    } catch (err) {
      alert("Fehler beim Herunterladen.");
    }
  };

  const getGreeting = () => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      return "Guten Morgen";
    } else if (currentHour < 18) {
      return "Guten Tag";
    } else {
      return "Guten Abend";
    }
  };

useEffect(() => {
  const fetchData = async () => {
    try {
      // Zuerst Patientendetails abrufen
      const detailsResponse = await fetchPatientDetails();
      if (detailsResponse.status === 200) {
        setPatientDetails(detailsResponse.data);
      } else {
        setPatientDetails({ fullName: "Unbekannt" });
      }

      // E-Mail-Adresse des Patienten extrahieren
      const email = detailsResponse.data.email;

      // Weitere Daten parallel abrufen
      const [
        prescriptionsResponse,
        prescriptionRequestsResponse,
        invoicesResponse,
        sessionsResponse,
        nextSessionResponse,
      ] = await Promise.allSettled([
        fetchPrescriptions(),
        fetchPrescriptionRequests(),
        fetchInvoices(),
        fetchSessions(),
        fetchNextSession(), // Nächste Sitzung abrufen
      ]);

      // Rezepte
      if (prescriptionsResponse.status === "fulfilled") {
        setPrescriptions(prescriptionsResponse.value.data || []);
      } else {
        setPrescriptions([]);
      }

      // Rezeptanfragen
      if (prescriptionRequestsResponse.status === "fulfilled") {
        setPrescriptionRequests(
          prescriptionRequestsResponse.value.data.prescriptionRequests || []
        );
      } else {
        setPrescriptionRequests([]);
      }

      // Rechnungen
      if (invoicesResponse.status === "fulfilled") {
        setInvoices(invoicesResponse.value.data.invoices || []);
      } else {
        setInvoices([]);
      }

      // Sitzungen
      if (sessionsResponse.status === "fulfilled") {
        const fetchedSessions = sessionsResponse.value.data;
        setSessions(Array.isArray(fetchedSessions) ? fetchedSessions : []);
      } else {
        setSessions([]);
      }

      // Nächste Sitzung
      if (nextSessionResponse.status === "fulfilled") {
        setNextSession(nextSessionResponse.value.data || null);
      } else {
        setNextSession(null);
      }
    } catch (err) {
      setError(
        "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut."
      );
    } finally {
      setIsLoading(false);
    }
  };

  fetchData();
}, []);

  const hasRequiredSessions = sessions.some((session) => {
    const sessionName = session.name.toLowerCase();
    return sessionName.includes("oeg") || sessionName.includes("eg");
  }) && sessions.some((session) => {
    const sessionName = session.name.toLowerCase();
    return sessionName.includes("vss");
  });

  const filteredAndSortedPrescriptions = () => {
    return filterAndSortData({
      data: { prescriptions },
      type: "prescriptions",
      sortField: "date",
      sortOrder: "desc",
      formatDate,
    });
  };

  const filteredAndSortedSessions = () => {
    return filterAndSortData({
      data: { sessions },
      type: "sessions",
      sortField: "date",
      sortOrder: "desc",
      formatDate,
    });
  };

  const filteredAndSortedInvoices = () => {
    return filterAndSortData({
      data: { invoices },
      type: "invoices",
      sortField: "date",
      sortOrder: "desc",
      formatDate,
    });
  };

  const sessionsExist = Array.isArray(sessions) && sessions.length > 0;


  const renderSkeleton = (rows, columns) => (
    Array.from({ length: rows }).map((_, rowIndex) => (
      <tr key={rowIndex}>
        {Array.from({ length: columns }).map((_, colIndex) => (
          <td key={colIndex} className="px-6 py-4 whitespace-nowrap">
            <div className="h-3 bg-gray-300 rounded animate-pulse"></div>
          </td>
        ))}
      </tr>
    ))
  );

  if (error) return <div className="text-red-600">{error}</div>;

  return (

  <div className="content-container">
  
    {/* Begrüßung */}
    <div className="page-title my-6">
      <h2 className="text-xl font-semibold text-gray-800">
        {getGreeting()}, {patientDetails.firstName || ""}
      </h2>
      <p className="text-gray-500 text-sm">
        Willkommen im persönlichen EvoCan-Dashboard!
      </p>
    </div>

    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
      {/* Linke Spalte (2/3 Breite) */}
      <div className="md:col-span-2 space-y-6">
       
          {/* Onboarding Progress */}
          {!isLoading && !sessionsExist && (
            <OnboardingProgressCard isLoading={isLoading} />
          )}

        {/* Nächste Sprechstunde Info */}
        <SessionInfoCard
          sessions={sessions}
          clientEmail={patientDetails.email}
          nextSession={nextSession}
          isLoading={isLoading}
        />

        {/*  UnpaidInvoicesCard */}
        <UnpaidInvoicesCard
          invoices={invoices}
          formatDate={formatDate}
          generateInvoiceNumber={generateInvoiceNumber}
          extractAmount={extractAmount}
        />

        <PrescriptionRequestsTable
          isLoading={isLoading}
          prescriptionRequests={prescriptionRequests}
          renderSkeleton={renderSkeleton}
          formatDate={formatDate}
        />

        {/* Rezepte-Tabelle */}
        <PrescriptionsTable
          isLoading={isLoading}
          prescriptions={filteredAndSortedPrescriptions()}
          renderSkeleton={renderSkeleton}
          formatDate={formatDate}
          handleDownload={handleDownload}
        />

        {/* Termine-Tabelle */}
        <SessionsTable
          isLoading={isLoading}
          sessions={filteredAndSortedSessions()}
          renderSkeleton={renderSkeleton}
          formatDate={formatDate}
          sessionTypeMap={sessionTypeMap}
        />

        <InvoicesTable
          isLoading={isLoading}
          invoices={filteredAndSortedInvoices()}
          renderSkeleton={renderSkeleton}
          formatDate={formatDate}
          generateInvoiceNumber={generateInvoiceNumber}
          extractAmount={extractAmount}
          handleDownload={handleDownload}
        />

      </div>

      {/* Rechte Spalte (1/3 Breite) */}
      <div className="md:col-span-1 space-y-6">

        {/* Nächste Sprechstunde */}
        <NextSessionCard
          isLoading={isLoading}
          formatDate={formatDate}
          nextSession={nextSession}
          renderSkeleton={renderSkeleton}
          clientEmail={patientDetails.email}
        />
         
        {/* Sprechstunde buchen */}
        <AppointmentBookingCard isLoading={isLoading} />

        {/* Prescription Request Card */}
        {(isLoading || hasRequiredSessions) && (
          <PrescriptionRequestCard sessions={sessions} isLoading={isLoading} />
        )}

      </div>
    </div>


  </div>

  );
};

export default Dashboard;