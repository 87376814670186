import React, { useState } from "react";
import ReactDOM from "react-dom";
import { extractAmount, generateInvoiceNumber } from "../../utils/helpers";
import { QRCodeCanvas } from "qrcode.react";

const PaymentModal = ({ invoice, isOpen, onClose }) => {
  if (!isOpen || !invoice) return null;
  const [popoverText, setPopoverText] = useState(null);
  const amount = extractAmount(invoice.name); // Betrag aus Rechnung extrahieren
  const invoiceNumber = generateInvoiceNumber(invoice.name); // Rechnungsnummer generieren

  const bankDetails = {
    iban: "DE19202208000027759191",
    bic: "SXPYDEHHXXX",
    receiver: "Scheffold & Prei PartG mbB Ärzte",
  };

  const handleCopy = (text, field) => {
    navigator.clipboard.writeText(text).then(() => {
      setPopoverText({ field, message: "Kopiert!" });
      setTimeout(() => setPopoverText(null), 1500);
    });
  };

  const generateGiroCode = () => {
    const amountFormatted = amount.replace("€", "").trim().replace(",", ".");
    return `BCD
001
1
SCT
${bankDetails.bic}
${bankDetails.receiver}
${bankDetails.iban}
EUR${amountFormatted}
Rechnung: ${invoiceNumber}`;
  };


  const modalContent = (
    <div className="fixed flex items-center justify-center bg-black bg-opacity-50 z-[100] overflow-y-auto overflow-x-hidden top-0 right-0 left-0 w-full md:inset-0 h-[calc(100%-1rem)] h-full">
      <div className="relative p-4 w-full max-w-3xl max-h-full">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          {/* Header */}
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h2 className="text-xl font-semibold text-gray-900">Zahlungsdetails</h2>
            <button
              onClick={onClose}
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Schließen</span>
            </button>
          </div>

          {/* Body */}
          <div className="p-4 md:p-5 space-y-4 grid grid-cols-1 md:grid-cols-2 gap-4">

            {/* Überschrift über beide Spalten */}
            <p className="col-span-1 md:col-span-2 text-base leading-relaxed text-gray-500 dark:text-gray-500">
              Bitte nutzen Sie die folgenden Kontodaten, um die Zahlung vorzunehmen. Achten Sie darauf, den Verwendungszweck korrekt anzugeben, damit wir Ihre Zahlung schnell zuordnen können. Alternativ können Sie den GiroCode für die Überweisung nutzen.
            </p>

            {/* Linke Spalte */}
              <div className="space-y-4">
                {[
                  { id: "iban", label: "IBAN", value: bankDetails.iban },
                  { id: "bic", label: "BIC", value: bankDetails.bic },
                  { id: "amount", label: "Betrag", value: amount },
                  { id: "purpose", label: "Verwendungszweck", value: `Rechnung ${invoiceNumber}` },
                ].map(({ id, label, value }) => (
                  <div key={id}>
                    <label htmlFor={id} className="mb-1 text-sm font-medium text-gray-900 dark:text-white block">
                      {label}:
                    </label>
                    <div className="relative">
                      <input
                        id={id}
                        type="text"
                        className="bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400"
                        value={value}
                        readOnly
                      />
                      <button
                        onClick={() => handleCopy(value, id)}
                        className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg p-2 inline-flex items-center justify-center group"
                      >
                        <span className="relative">
                          {/* Tooltip */}
                          <div
                            className={`absolute -top-8 left-1/2 transform -translate-x-1/2 bg-gray-900 text-white text-xs px-2 py-1 rounded opacity-0 group-hover:opacity-100 ${
                              popoverText?.field === id ? "opacity-100 bg-green-500" : ""
                            } transition-opacity`}
                          >
                            {popoverText?.field === id ? popoverText.message : "Kopieren"}
                          </div>
                          {/* Icon */}
                          <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                            <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z" />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </div>
                ))}
              </div>

            {/* Rechte Spalte */}
            <div className="flex flex-col items-center justify-center space-y-4">
              <div className="relative">
                <div className="inline-block border-4 border-black text-white rounded-lg p-1">
                  <QRCodeCanvas
                    value={generateGiroCode()}
                    size={180}
                    style={{ borderRadius: "8px", backgroundColor: "white", padding: "6px" }}
                  />
                </div>
              </div>
              <p className="text-sm text-gray-600 text-center w-48">
                Scannen Sie den GiroCode mit Ihrer Banking-App, um die Zahlungsdaten automatisch zu übernehmen – schnell und fehlerfrei.
              </p>
            </div>
          </div>

          {/* Footer */}
          <div className="flex items-center justify-end p-4 border-t">
            <button
              onClick={onClose}
              className="py-2 px-3 text-sm font-medium bg-gray-800 text-white rounded-lg hover:bg-gray-700"
            >
              Schließen
            </button>
          </div>

        </div>
      </div>
    </div>
  );

  return ReactDOM.createPortal(modalContent, document.body);
};

export default PaymentModal;