import React from "react";
import { Link } from "react-router-dom";

const VerificationError = () => {
  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="block mb-6 text-center">
          <img
            className="w-auto h-8 mx-auto mb-6"
            src="../img/logo.svg"
            alt="Logo"
          />
          <h2 className="pb-4 text-2xl font-bold text-gray-900 dark:text-white">
            Verifizierung fehlgeschlagen
          </h2>
        </div>
        <div className="w-full bg-white rounded-lg shadow dark:border sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 sm:p-8 text-center">
            <p className="text-gray-900 dark:text-gray-300">
              Der Verifizierungslink ist ungültig oder abgelaufen. Bitte registrieren Sie sich erneut oder kontaktieren Sie unseren Support.
            </p>
            <Link
              to="/register"
              className="w-full text-white bg-gray-800 hover:bg-gray-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-block"
            >
              Zur Registrierung
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VerificationError;