import React, { useState } from "react";
import { Link } from "react-router-dom";
import { filterAndSortData } from "../../utils/helpers";

const ITEMS_PER_PAGE = 10;

const AdvancedPrescriptionsTable = ({
  isLoading,
  prescriptions,
  renderSkeleton,
  formatDate,
  handleDownload,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [sortField, setSortField] = useState("date");
  const [sortOrder, setSortOrder] = useState("desc");
  const [currentPage, setCurrentPage] = useState(1);

  const filteredAndSortedPrescriptions = () => {
    return filterAndSortData({
      data: { prescriptions },
      type: "prescriptions",
      searchQuery,
      sortField,
      sortOrder,
      formatDate,
    });
  };

  const filteredPrescriptions = filteredAndSortedPrescriptions();
  const totalItems = filteredPrescriptions.length;
  const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE);

  const paginatedPrescriptions = filteredPrescriptions.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to first page
  };

  return (
    <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-x-hidden">
      <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-neutral-700">
        <div>
          <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200 inline-flex items-center gap-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
              <path d="M14.9805 7.01556C14.9805 7.01556 15.4805 7.51556 15.9805 8.51556C15.9805 8.51556 17.5687 6.01556 18.9805 5.51556" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M9.99491 2.02134C7.49644 1.91556 5.56618 2.20338 5.56618 2.20338C4.34733 2.29053 2.01152 2.97385 2.01154 6.96454C2.01156 10.9213 1.9857 15.7993 2.01154 17.7439C2.01154 18.932 2.74716 21.7033 5.29332 21.8518C8.38816 22.0324 13.9628 22.0708 16.5205 21.8518C17.2052 21.8132 19.4847 21.2757 19.7732 18.7956C20.0721 16.2263 20.0126 14.4407 20.0126 14.0157" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M21.9999 7.01556C21.9999 9.77698 19.7592 12.0156 16.9951 12.0156C14.231 12.0156 11.9903 9.77698 11.9903 7.01556C11.9903 4.25414 14.231 2.01556 16.9951 2.01556C19.7592 2.01556 21.9999 4.25414 21.9999 7.01556Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
              <path d="M6.98053 13.0156H10.9805" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
              <path d="M6.98053 17.0156H14.9805" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
            </svg>
            Rezepte
          </h2>
          <p className="text-sm text-gray-600 dark:text-neutral-400">
            Zuletzt ausgestellte Verschreibungen
          </p>
        </div>
        <div className="inline-flex gap-x-2">
          <div id="table-search" className="relative inline-flex items-center overflow-hidden text-xs rounded-md border border-gray-200 bg-white text-gray-800">
            <div className="text-gray-400 absolute inset-y-0 start-0 flex items-center ps-2 pointer-events-none">
              <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
                  <path d="M17.5 17.5L22 22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
              </svg>
            </div>
            <input
              type="text"
              placeholder="Suche"
              className="pr-3 pl-8 h-full text-sm"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
      </div>
      
      <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th
              className="px-6 py-3 text-start cursor-pointer"
              onClick={() => {
                setSortField("date");
                setSortOrder(sortOrder === "desc" ? "asc" : "desc");
              }}
            >
              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800">
                Datum
                {sortField === "date" && (
                  <span>
                    {sortOrder === "desc" ? " ↓" : " ↑"}
                  </span>
                )}
              </span>
            </th>
            {/* Spalte: Status */}
            <th
              className="px-6 py-3 text-start cursor-pointer"
              onClick={() => {
                setSortField("status");
                setSortOrder(sortOrder === "desc" ? "asc" : "desc");
              }}
            >
              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800">
                Status
                {sortField === "status" && (sortOrder === "desc" ? " ↓" : " ↑")}
              </span>
            </th>
            <th scope="col" className="px-6 py-3 text-start">
              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800"></span>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {isLoading ? (
            renderSkeleton(3, 3)
          ) : paginatedPrescriptions.length > 0 ? (
            paginatedPrescriptions.map((item, index) => (
              <tr key={index}>
                <td className="px-6 py-2 align-middle whitespace-nowrap">
                  <span className="text-sm text-gray-600">{formatDate(item.name)}</span>
                </td>
                <td className="px-6 py-2 align-middle whitespace-nowrap">
                  <span
                    className="py-1 px-2 inline-flex items-center gap-x-2 text-xs font-medium rounded-full bg-gray-100 text-primary-800"
                  >
                    <div
                      className="flex items-center justify-center w-3 h-3 p-0.5 rounded-full bg-primary-800"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#ffffff"} fill={"none"}>
                          <path d="M5 14L8.5 17.5L19 6.5" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </div>
                    Versendet
                  </span>
                </td>
                <td className="px-6 py-2 align-middle whitespace-nowrap">
                  <button
                    onClick={() => handleDownload(item.path)}
                    className="py-1 px-2 align-middle inline-flex items-center gap-x-2 text-xs font-medium rounded border border-gray-200 bg-white text-gray-800 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 24 24" width={20} height={20} color={"currentColor"} fill={"none"}>
                        <path d="M12 14.5L12 4.5M12 14.5C11.2998 14.5 9.99153 12.5057 9.5 12M12 14.5C12.7002 14.5 14.0085 12.5057 14.5 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M20 16.5C20 18.982 19.482 19.5 17 19.5H7C4.518 19.5 4 18.982 4 16.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>Herunterladen
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3" className="text-center p-2">
                <div className="max-w-sm w-full min-h-[250px] flex flex-col items-center justify-center mx-auto px-6 py-4">
                  <div className="flex justify-center items-center size-[46px] bg-gray-100 rounded-lg dark:bg-neutral-800">
                    <svg className="shrink-0 size-6 text-gray-600 dark:text-neutral-400" xmlns="http://www.w3.org/2000/svg" width="24" height="24" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="transparent" viewBox="0 0 24 24">
                      <path d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z" stroke="currentColor" strokeWidth="1.5" />
                      <path d="M12.2422 17V12C12.2422 11.5286 12.2422 11.2929 12.0957 11.1464C11.9493 11 11.7136 11 11.2422 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M11.992 8H12.001" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>

                  <h2 className="mt-5 font-semibold text-lg text-gray-800 dark:text-white">Keine Rezepte gefunden</h2>
                  <p className="mt-2 text-sm text-gray-600 dark:text-neutral-400">Wir konnten keine Rezepte finden, die den Suchkriterien entsprechen.</p>

                  <div className="mt-5 flex flex-col sm:flex-row gap-2">
                    <button
                      type="button"
                      className="py-2 px-3 inline-flex items-center gap-x-2 text-xs font-medium rounded border border-transparent bg-gray-800 text-white shadow-sm hover:bg-gray-700 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-700 dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                      onClick={() => alert("Termin buchen")}
                    >
                      <svg className="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M5 12h14"/><path d="M12 5v14"/>
                      </svg>
                      Rezept anfragen
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      </div>

      {/* Pagination */}
      <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-4 sm:px-6">
        <div className="flex flex-1 items-center justify-between">
          <div>
            <p className="text-sm text-gray-700">
              Zeige{" "}
              <span className="font-medium">
                {(currentPage - 1) * ITEMS_PER_PAGE + 1}
              </span>{" "}
              bis{" "}
              <span className="font-medium">
                {Math.min(currentPage * ITEMS_PER_PAGE, totalItems)}
              </span>{" "}
              von <span className="font-medium">{totalItems}</span> Einträgen
            </p>
          </div>
          <div>
            <nav
              aria-label="Pagination"
              className="isolate inline-flex -space-x-px rounded-md"
            >
              <button
                onClick={() => handlePageChange(1)}
                className="relative inline-flex items-center rounded-l-md px-1.5 py-1.5 text-gray-800 bg-white ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:z-20 focus:outline-offset-0"
                disabled={currentPage === 1}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
                    <path d="M15 6C15 6 9.00001 10.4189 9 12C8.99999 13.5812 15 18 15 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </button>
              {[...Array(totalPages)].map((_, index) => (
                <button
                  key={index}
                  onClick={() => handlePageChange(index + 1)}
                  className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                    currentPage === index + 1
                      ? "bg-gray-800 text-white ring-1 ring-inset ring-gray-300"
                      : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  }`}
                >
                  {index + 1}
                </button>
              ))}
              <button
                onClick={() => handlePageChange(totalPages)}
                className="relative inline-flex items-center rounded-r-md px-1.5 py-1.5 text-gray-800 bg-white ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:z-20 focus:outline-offset-0"
                disabled={currentPage === totalPages}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
                  <path d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              </button>
            </nav>
          </div>
        </div>
      </div>


    </div>
  );
};

export default AdvancedPrescriptionsTable;