import React, { useState } from "react";
import { filterAndSortData } from "../../utils/helpers";

const ITEMS_PER_PAGE = 10;

const AdvancedPrescriptionRequestsTable = ({
  isLoading,
  prescriptionRequests,
  renderSkeleton,
  formatDate,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [sortField, setSortField] = useState("date");
  const [sortOrder, setSortOrder] = useState("desc");
  const [currentPage, setCurrentPage] = useState(1);

  const filteredAndSortedRequests = () => {
    return filterAndSortData({
      data: { prescriptionRequests },
      type: "prescriptionRequests",
      searchQuery,
      sortField,
      sortOrder,
      formatDate,
    });
  };

  const filteredRequests = filteredAndSortedRequests();
  const totalItems = filteredRequests.length;
  const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE);

  const paginatedRequests = filteredRequests.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to first page
  };

  return (
    <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-x-hidden">
      <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200">
        <div>
          <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200 inline-flex items-center gap-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
                <path d="M19.7502 11V10C19.7502 6.22876 19.7502 4.34315 18.5786 3.17157C17.407 2 15.5214 2 11.7502 2H10.7503C6.97907 2 5.09346 2 3.92189 3.17156C2.75032 4.34312 2.7503 6.22872 2.75027 9.99993L2.75024 14C2.7502 17.7712 2.75019 19.6568 3.92172 20.8284C5.09329 21.9999 6.97897 22 10.7502 22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7.25024 7H15.2502M7.25024 12H15.2502" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                <path d="M13.2502 20.8268V22H14.4236C14.833 22 15.0377 22 15.2217 21.9238C15.4058 21.8475 15.5505 21.7028 15.84 21.4134L20.6636 16.5894C20.9366 16.3164 21.0731 16.1799 21.1461 16.0327C21.285 15.7525 21.285 15.4236 21.1461 15.1434C21.0731 14.9961 20.9366 14.8596 20.6636 14.5866C20.3905 14.3136 20.254 14.1771 20.1067 14.1041C19.8265 13.9653 19.4975 13.9653 19.2173 14.1041C19.0701 14.1771 18.9335 14.3136 18.6605 14.5866L18.6605 14.5866L13.8369 19.4106C13.5474 19.7 13.4027 19.8447 13.3265 20.0287C13.2502 20.2128 13.2502 20.4174 13.2502 20.8268Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
            </svg>
            Rezeptanfragen
          </h2>
          <p className="text-sm text-gray-600 dark:text-neutral-400">
            Alle gestellten Rezeptanfragen
          </p>
        </div>
        <div className="inline-flex gap-x-2">
          <div id="table-search" className="relative inline-flex items-center overflow-hidden text-xs rounded-md border border-gray-200 bg-white text-gray-800">
            <div className="text-gray-400 absolute inset-y-0 start-0 flex items-center ps-2 pointer-events-none">
              <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
                  <path d="M17.5 17.5L22 22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
              </svg>
            </div>
            <input
              type="text"
              placeholder="Suche"
              className="pr-3 pl-8 h-full text-sm"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                className="px-6 py-3 text-start cursor-pointer"
                onClick={() => {
                  setSortField("date");
                  setSortOrder(sortOrder === "desc" ? "asc" : "desc");
                }}
              >
                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800">
                  Datum
                  {sortField === "date" && (
                    <span>{sortOrder === "desc" ? " ↓" : " ↑"}</span>
                  )}
                </span>
              </th>
              <th
                className="px-6 py-3 text-start cursor-pointer"
                onClick={() => {
                  setSortField("status");
                  setSortOrder(sortOrder === "desc" ? "asc" : "desc");
                }}
              >
                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800">
                  Status
                  {sortField === "status" && (
                    <span>{sortOrder === "desc" ? " ↓" : " ↑"}</span>
                  )}
                </span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {isLoading ? (
              renderSkeleton(3, 2)
            ) : paginatedRequests.length > 0 ? (
              paginatedRequests.map((item, index) => (
                <tr key={index}>
                  <td className="px-6 py-2 align-middle whitespace-nowrap">
                    <span className="text-sm text-gray-600">
                      {formatDate(item.name)}
                    </span>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    <span
                      className={`py-1 px-2 inline-flex items-center gap-x-2 text-xs font-medium rounded-full ${
                        item.status === "Eingegangen" ? "bg-gray-100 text-amber-800" : "bg-gray-100 text-primary-800"
                      }`}
                    >
                      <div
                        className={`flex items-center justify-center w-3 h-3 p-0.5 rounded-full ${
                          item.status === "Eingegangen" ? "bg-amber-800" : "bg-primary-800"
                        }`}
                      >
                        {item.status === "Eingegangen" ? (
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#ffffff"} fill={"none"}>
                            <path d="M12 2V12L16 16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        ) : (
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#ffffff"} fill={"none"}>
                            <path d="M5 14L8.5 17.5L19 6.5" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        )}
                      </div>
                      {item.status}
                    </span>
                  </td>
                </tr>
              ))
            ) : (
            <tr>
              <td colSpan="2" className="text-center p-2">
                <div className="max-w-sm w-full min-h-[250px] flex flex-col items-center justify-center mx-auto px-6 py-4">
                  <div className="flex justify-center items-center size-[46px] bg-gray-100 rounded-lg dark:bg-neutral-800">
                    <svg className="shrink-0 size-6 text-gray-600 dark:text-neutral-400" xmlns="http://www.w3.org/2000/svg" width="24" height="24" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="transparent" viewBox="0 0 24 24">
                      <path d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z" stroke="currentColor" strokeWidth="1.5" />
                      <path d="M12.2422 17V12C12.2422 11.5286 12.2422 11.2929 12.0957 11.1464C11.9493 11 11.7136 11 11.2422 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M11.992 8H12.001" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>

                  <h2 className="mt-5 font-semibold text-lg text-gray-800 dark:text-white">Keine Rezeptanfragen gefunden</h2>
                  <p className="mt-2 text-sm text-gray-600 dark:text-neutral-400">Wir konnten keine Rezeptanfragen finden, die den Suchkriterien entsprechen.</p>

                  <div className="mt-5 flex flex-col sm:flex-row gap-2">
                    <button
                      type="button"
                      className="py-2 px-3 inline-flex items-center gap-x-2 text-xs font-medium rounded border border-transparent bg-gray-800 text-white shadow-sm hover:bg-gray-700 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-700 dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                      onClick={() => alert("Termin buchen")}
                    >
                      <svg className="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M5 12h14"/><path d="M12 5v14"/>
                      </svg>
                      Rezept anfragen
                    </button>
                  </div>
                </div>
              </td>
            </tr>
            )}
          </tbody>
        </table>
      </div>


      {/* Pagination */}
      <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-4 sm:px-6">
        <div className="flex flex-1 items-center justify-between">
          <div>
            <p className="text-sm text-gray-700">
              Zeige{" "}
              <span className="font-medium">
                {(currentPage - 1) * ITEMS_PER_PAGE + 1}
              </span>{" "}
              bis{" "}
              <span className="font-medium">
                {Math.min(currentPage * ITEMS_PER_PAGE, totalItems)}
              </span>{" "}
              von <span className="font-medium">{totalItems}</span> Einträgen
            </p>
          </div>
          <div>
            <nav
              aria-label="Pagination"
              className="isolate inline-flex -space-x-px rounded-md"
            >
              <button
                onClick={() => handlePageChange(1)}
                className="relative inline-flex items-center rounded-l-md px-1.5 py-1.5 text-gray-800 bg-white ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:z-20 focus:outline-offset-0"
                disabled={currentPage === 1}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
                    <path d="M15 6C15 6 9.00001 10.4189 9 12C8.99999 13.5812 15 18 15 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </button>
              {[...Array(totalPages)].map((_, index) => (
                <button
                  key={index}
                  onClick={() => handlePageChange(index + 1)}
                  className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                    currentPage === index + 1
                      ? "bg-gray-800 text-white ring-1 ring-inset ring-gray-300"
                      : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  }`}
                >
                  {index + 1}
                </button>
              ))}
              <button
                onClick={() => handlePageChange(totalPages)}
                className="relative inline-flex items-center rounded-r-md px-1.5 py-1.5 text-gray-800 bg-white ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:z-20 focus:outline-offset-0"
                disabled={currentPage === totalPages}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
                  <path d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              </button>
            </nav>
          </div>
        </div>
      </div>


    </div>
  );
};

export default AdvancedPrescriptionRequestsTable;