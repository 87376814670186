import React, { useState, useEffect } from "react";
import TreatmentRequestForm from "../components/Forms/TreatmentRequestForm";
import SessionRequestForm from "../components/Forms/SessionRequestForm";
import IdRequestForm from "../components/Forms/IdRequestForm";
import PrescriptionRequestForm from "../components/Forms/PrescriptionRequestForm";
import SchengenRequestForm from "../components/Forms/SchengenRequestForm";
import AttestationRequestForm from "../components/Forms/AttestationRequestForm";
import {
  fetchPatientDetails,
  fetchOnboardingDocuments,
} from "../services/api"; // Importiere API-Funktion

const Requests = () => {
  const [activeModal, setActiveModal] = useState(null); // Steuert, welches Modal geöffnet ist
  const [patientDetails, setPatientDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false); // Lädt Patientendetails
  const [hasTreatmentRequest, setHasTreatmentRequest] = useState(false); // Flag für AN-Dokument

  useEffect(() => {
    const loadPatientDetails = async () => {
      try {
        setIsLoading(true);
        const response = await fetchPatientDetails();
        if (response && response.data) {
          setPatientDetails(response.data); // Hier sicherstellen, dass `setPatientDetails` richtig gesetzt wird
        }
      } catch (error) {
        console.error("Fehler beim Laden der Patientendetails:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const loadOnboardingDocuments = async () => {
      try {
        const response = await fetchOnboardingDocuments();

        // Sicherstellen, dass die Daten ein Array enthalten
        const onboardingDocuments = Array.isArray(response.data?.onboardingDocuments)
          ? response.data.onboardingDocuments
          : [];

        // Prüfen, ob ein Dokument mit "AN" (Behandlungsanfrage) im Namen existiert
        const hasTreatmentDoc = onboardingDocuments.some((doc) => doc.name.includes("AN"));
        setHasTreatmentRequest(hasTreatmentDoc);
      } catch (error) {
        setHasTreatmentRequest(false); // Standardwert bei Fehlern
      }
    };

    loadPatientDetails();
    loadOnboardingDocuments();
  }, []);

  const openModal = (modalType) => {
    setActiveModal(modalType);
  };

  const closeModal = () => {
    setActiveModal(null);
  };

  const handleFormSubmit = (formData) => {
    closeModal();
  };

  const renderSkeleton = () => (
    <div className="bg-white border border-gray-200 rounded-xl shadow-sm p-6 animate-pulse">
      <div className="h-6 bg-gray-300 w-2/3 rounded mb-4"></div>
      <div className="h-4 bg-gray-300 w-full rounded mb-2"></div>
      <div className="h-4 bg-gray-300 w-3/4 rounded mb-2"></div>
      <div className="h-8 bg-gray-300 w-1/4 rounded mt-4"></div>
    </div>
  );

  const requestItems = [
    {
      type: "treatment",
      title: "Behandlungsanfrage",
      description:
        "Starten Sie Ihre Cannabis-Therapie und lassen Sie sich von unseren Ärzten beraten.",
      buttonLabel: "Anfrage starten",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
            <path d="M11.9955 12H12.0045M8 12H8.00897" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M22 11.5667C22 16.8499 17.5222 21.1334 12 21.1334C11.3507 21.1343 10.7032 21.0742 10.0654 20.9545C9.60633 20.8682 9.37678 20.8251 9.21653 20.8496C9.05627 20.8741 8.82918 20.9949 8.37499 21.2364C7.09014 21.9197 5.59195 22.161 4.15111 21.893C4.69874 21.2194 5.07275 20.4112 5.23778 19.5448C5.33778 19.0148 5.09 18.5 4.71889 18.1231C3.03333 16.4115 2 14.1051 2 11.5667C2 6.28357 6.47778 2 12 2C12.6849 2 13.3538 2.0659 14 2.19142" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20.8386 2.47645L21.5309 3.16882C22.1167 3.7546 22.1167 4.70435 21.5309 5.29013L17.9035 8.9858C17.6182 9.27115 17.2532 9.46351 16.8565 9.53759L14.6084 10.0256C14.2534 10.1027 13.9373 9.78753 14.0134 9.43236L14.4919 7.19703C14.566 6.80035 14.7583 6.43535 15.0437 6.15L18.7173 2.47645C19.303 1.89066 20.2528 1.89066 20.8386 2.47645Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      ),
    },
    {
      type: "session",
      title: "Sprechstunde",
      description:
        "Buchen Sie eine Sprechstunde, um sich individuell beraten zu lassen.",
      buttonLabel: "Termin buchen",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
            <path d="M14 2H10C6.72077 2 5.08116 2 3.91891 2.81382C3.48891 3.1149 3.1149 3.48891 2.81382 3.91891C2 5.08116 2 6.72077 2 10C2 13.2792 2 14.9188 2.81382 16.0811C3.1149 16.5111 3.48891 16.8851 3.91891 17.1862C5.08116 18 6.72077 18 10 18H14C17.2792 18 18.9188 18 20.0811 17.1862C20.5111 16.8851 20.8851 16.5111 21.1862 16.0811C22 14.9188 22 13.2792 22 10C22 6.72077 22 5.08116 21.1862 3.91891C20.8851 3.48891 20.5111 3.1149 20.0811 2.81382C18.9188 2 17.2792 2 14 2Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M14.5 22L14.1845 21.5811C13.4733 20.6369 13.2969 19.1944 13.7468 18M9.5 22L9.8155 21.5811C10.5267 20.6369 10.7031 19.1944 10.2532 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M7 22H17" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M8 14C9.83846 11.4046 14.1188 11.263 16 14M14 8C14 9.10457 13.1046 10 12 10C10.8954 10 10 9.10457 10 8C10 6.89543 10.8954 6 12 6C13.1046 6 14 6.89543 14 8Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
        </svg>
      ),
    },
    {
      type: "prescription",
      title: "Rezeptanfrage",
      description: "Fordern Sie ein Folgerezept für Ihre Therapie an.",
      buttonLabel: "Rezept anfordern",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
          <path d="M19.7502 11V10C19.7502 6.22876 19.7502 4.34315 18.5786 3.17157C17.407 2 15.5214 2 11.7502 2H10.7503C6.97907 2 5.09346 2 3.92189 3.17156C2.75032 4.34312 2.7503 6.22872 2.75027 9.99993L2.75024 14C2.7502 17.7712 2.75019 19.6568 3.92172 20.8284C5.09329 21.9999 6.97897 22 10.7502 22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M7.25024 7H15.2502M7.25024 12H15.2502" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
          <path d="M13.2502 20.8268V22H14.4236C14.833 22 15.0377 22 15.2217 21.9238C15.4058 21.8475 15.5505 21.7028 15.84 21.4134L20.6636 16.5894C20.9366 16.3164 21.0731 16.1799 21.1461 16.0327C21.285 15.7525 21.285 15.4236 21.1461 15.1434C21.0731 14.9961 20.9366 14.8596 20.6636 14.5866C20.3905 14.3136 20.254 14.1771 20.1067 14.1041C19.8265 13.9653 19.4975 13.9653 19.2173 14.1041C19.0701 14.1771 18.9335 14.3136 18.6605 14.5866L18.6605 14.5866L13.8369 19.4106C13.5474 19.7 13.4027 19.8447 13.3265 20.0287C13.2502 20.2128 13.2502 20.4174 13.2502 20.8268Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
        </svg>
      ),
    },
    {
      type: "id",
      title: "Patientenausweis",
      description:
        "Beantragen Sie Ihren Patientenausweis zur Nachweis Ihrer Cannabis-Therapie bei uns.",
      buttonLabel: "Ausweis beantragen",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
            <path d="M14 3.5C17.7712 3.5 19.6569 3.5 20.8284 4.7448C22 5.98959 22 7.99306 22 12C22 16.0069 22 18.0104 20.8284 19.2552C19.6569 20.5 17.7712 20.5 14 20.5L10 20.5C6.22876 20.5 4.34315 20.5 3.17157 19.2552C2 18.0104 2 16.0069 2 12C2 7.99306 2 5.98959 3.17157 4.7448C4.34315 3.5 6.22876 3.5 10 3.5L14 3.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
            <path d="M5 15.5C6.60865 13.3626 10.3539 13.246 12 15.5M10.249 10.2501C10.249 11.2166 9.46552 12.0001 8.49902 12.0001C7.53253 12.0001 6.74902 11.2166 6.74902 10.2501C6.74902 9.28362 7.53253 8.50012 8.49902 8.50012C9.46552 8.50012 10.249 9.28362 10.249 10.2501Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M15 9.5L19 9.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M15 13.5H17" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
        </svg>
      ),
    },
    {
      type: "schengen",
      title: "Schengenformular",
      description: "Beantragen Sie das Formular für Ihre Reise ins Ausland.",
      buttonLabel: "Formular beantragen",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
            <path d="M2.00031 20H18.0003" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.82527 12.1661C3.55027 11.9661 3.30027 11.7161 3.00028 10.8411C2.91891 10.6241 2.61139 9.53619 2.35028 8.54109C2.13003 7.7017 1.93377 6.93555 2.02528 6.74109C2.10029 6.54109 2.20027 6.39109 2.52527 6.19109C2.72527 6.06802 3.75027 5.81609 3.95027 5.76609C4.15027 5.71609 4.42526 5.69109 4.65027 5.76609C5.07527 5.84109 5.95027 7.11609 6.17527 7.26609C6.27526 7.36609 6.60027 7.657 6.97527 7.69109C7.25027 7.71609 7.52527 7.64109 7.82528 7.51609C8.10027 7.40151 13.5253 4.76609 14.0253 4.54109C18.1003 2.84109 21.0603 5.63609 21.5103 6.23609C21.9753 6.81609 22.0753 6.99109 21.9503 7.49109C21.7887 8.01609 21.3503 8.11609 21.1003 8.19109C20.8503 8.26609 17.4003 9.19109 16.0503 9.56609C15.7554 9.6621 15.6114 9.85492 15.5753 9.89109C15.4003 10.1411 14.6053 11.8411 14.3803 12.2161C14.2253 12.6161 13.8003 13.1161 13.2503 13.3161C12.6753 13.5161 11.6753 13.7411 11.4503 13.8161C11.2253 13.8911 10.7003 14.0411 10.5253 13.9911C10.3003 13.9411 10.0853 13.7161 10.1853 13.3661C10.2853 13.0161 10.4753 12.0411 10.5003 11.8911C10.5253 11.7411 10.7753 11.1161 10.5003 11.0911C10.4503 11.0161 9.92527 11.2411 9.15027 11.4161C8.57449 11.5782 7.9715 11.7386 7.55027 11.8411C5.92527 12.3161 5.04521 12.4411 4.85027 12.4411C4.47527 12.4411 4.20027 12.3911 3.82527 12.1661Z" stroke="currentColor" strokeWidth="1.5" />
        </svg>
      ),
    },
    {
      type: "attestation",
      title: "Atteste",
      description: "Fordern Sie ärztliche Bescheinigungen oder Gutachten an.",
      buttonLabel: "Attest anfordern",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
          <path d="M20.4999 10.5V10C20.4999 6.22876 20.4999 4.34315 19.3284 3.17157C18.1568 2 16.2712 2 12.4999 2H11.5C7.72883 2 5.84323 2 4.67166 3.17156C3.50009 4.34312 3.50007 6.22872 3.50004 9.99993L3.5 14.5C3.49997 17.7874 3.49996 19.4312 4.40788 20.5375C4.57412 20.7401 4.75986 20.9258 4.96242 21.0921C6.06877 22 7.71249 22 10.9999 22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M7.5 7H16.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M7.5 12H13.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M20.5 20L20.5 17C20.5 15.5706 19.1569 14 17.5 14C15.8431 14 14.5 15.5706 14.5 17L14.5 20.5C14.5 21.3284 15.1716 22 16 22C16.8284 22 17.5 21.3284 17.5 20.5V17" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      ),
    },
  ];

  return (

    <div className="content-container">
      <div className="page-title my-6 grid gap-3 md:flex md:justify-between md:items-center">
        <div>
          <h2 className="text-xl font-semibold text-gray-800">
            Ihre Anfragen und Formulare
          </h2>
          <p className="text-gray-500 text-sm">
            Auf dieser Seite können Sie alle wichtigen Anfragen und Formulare für
            Ihre Therapie verwalten.
            <br />
            Von Behandlungsanfragen über Terminbuchungen bis hin zur Rezept- und
            Formularanforderung – alles an einem Ort.
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {!isLoading
        ? requestItems
            .filter((item) => {
              if (!hasTreatmentRequest) {
                // Keine Behandlungsanfrage: Nur "treatment" und "session" anzeigen
                return item.type === "treatment" || item.type === "session";
              }
              // Mit Behandlungsanfrage: Alles außer "treatment" anzeigen
              return item.type !== "treatment";
            })
            .map((item) => (
              <div
                key={item.type}
                className="bg-white border border-gray-200 rounded-xl shadow-sm p-6"
              >
                <h3 className="text-lg font-semibold text-gray-800 inline-flex items-center gap-x-3">
                  {item.icon}
                  {item.title}
                </h3>
                <p className="text-sm text-gray-600 mt-3">{item.description}</p>
                <button
                  className="mt-4 py-2 px-4 bg-gray-800 text-white text-sm font-medium rounded-lg hover:bg-gray-700 focus:outline-none"
                  onClick={() => openModal(item.type)}
                >
                  {item.buttonLabel}
                </button>
              </div>
            ))
        : Array.from({ length: 6 }).map((_, index) => (
            <div key={index}>{renderSkeleton()}</div>
          ))}
      </div>

      {/* Modals */}
      {activeModal && (
        <div className="fixed flex items-center justify-center bg-black bg-opacity-50 z-[100] overflow-y-auto overflow-x-hidden top-0 right-0 left-0 w-full md:inset-0 h-[calc(100%-1rem)] h-full">
          <div className="relative p-4 w-full max-w-4xl max-h-full">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h2 className="text-xl font-semibold text-gray-900">
                  {activeModal === "treatment" && "Behandlungsanfrage"}
                  {activeModal === "session" && "Terminbuchung"}
                  {activeModal === "prescription" && "Rezeptanfrage"}
                  {activeModal === "id" && "Patientenausweis beantragen"}
                  {activeModal === "schengen" && "Schengenformular"}
                  {activeModal === "attestation" && "Attest anfordern"}
                </h2>
                <button onClick={closeModal} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="crud-modal">
                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="p-4 md:p-5 space-y-4">
                {/* Formulare hier anzeigen */}
                {activeModal === "treatment" && (
                <TreatmentRequestForm
                  onClose={closeModal}
                  onSubmit={handleFormSubmit}
                  patientDetails={patientDetails}
                />
                )}
                {activeModal === "session" && (
                  <SessionRequestForm
                    onClose={closeModal} // Ändere handleClose zu closeModal
                    onSubmit={handleFormSubmit}
                    apiKey="3zELzqNcaTPnthtcFyDQrb2oZicgVjSVrw4X82bjnP7BB97e"
                    patientDetails={{
                      fullName: patientDetails?.fullName || "Unbekannt", // Falls fullName fehlt, Standardwert "Unbekannt"
                      email: patientDetails?.email || "Unbekannt", // Falls email fehlt, Standardwert "Unbekannt"
                    }} // E-Mail wird an das Formular übergeben
                  />
                )}
                {activeModal === "prescription" && (
                  <PrescriptionRequestForm
                    onClose={closeModal} // Ändere handleClose zu closeModal
                    onSubmit={handleFormSubmit}
                    openModal={openModal}
                    patientDetails={{
                      id: patientDetails?.id || "0", // Falls id fehlt, Standardwert "Unbekannt"
                      lastName: patientDetails?.lastName || "Unbekannt", // Falls lastName fehlt, Standardwert "Unbekannt"
                      firstName: patientDetails?.firstName || "Unbekannt", // Falls firstName fehlt, Standardwert "Unbekannt"
                      fullName: patientDetails?.fullName || "Unbekannt", // Falls fullName fehlt, Standardwert "Unbekannt"
                      email: patientDetails?.email || "Unbekannt", // Falls email fehlt, Standardwert "Unbekannt"
                    }} // E-Mail wird an das Formular übergeben
                  />
                )}
                {activeModal === "id" && (
                  <div className="p-4 text-center">
                    <h2 className="text-xl font-semibold text-gray-900">Anfrage in Arbeit</h2>
                    <p className="mt-2 text-gray-600">
                      Diese Anfrage wird gerade implementiert. Bitte haben Sie etwas Geduld, wir arbeiten daran, sie so bald wie möglich verfügbar zu machen.
                    </p>
                    <button
                      onClick={closeModal}
                      className="mt-4 py-2 px-4 bg-gray-800 text-white text-sm font-medium rounded-lg hover:bg-gray-700 focus:outline-none"
                    >
                      Schließen
                    </button>
                  </div>
                )}
                {activeModal === "schengen" && (
                  <div className="p-4 text-center">
                    <h2 className="text-xl font-semibold text-gray-900">Anfrage in Arbeit</h2>
                    <p className="mt-2 text-gray-600">
                      Diese Anfrage wird gerade implementiert. Bitte haben Sie etwas Geduld, wir arbeiten daran, sie so bald wie möglich verfügbar zu machen.
                    </p>
                    <button
                      onClick={closeModal}
                      className="mt-4 py-2 px-4 bg-gray-800 text-white text-sm font-medium rounded-lg hover:bg-gray-700 focus:outline-none"
                    >
                      Schließen
                    </button>
                  </div>
                )}
                {activeModal === "attestation" && (
                  <div className="p-4 text-center">
                    <h2 className="text-xl font-semibold text-gray-900">Anfrage in Arbeit</h2>
                    <p className="mt-2 text-gray-600">
                      Diese Anfrage wird gerade implementiert. Bitte haben Sie etwas Geduld, wir arbeiten daran, sie so bald wie möglich verfügbar zu machen.
                    </p>
                    <button
                      onClick={closeModal}
                      className="mt-4 py-2 px-4 bg-gray-800 text-white text-sm font-medium rounded-lg hover:bg-gray-700 focus:outline-none"
                    >
                      Schließen
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

</div>
  );
};


export default Requests;