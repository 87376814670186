import React, { useState } from "react";
import { login } from "../services/api";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Input, Button, Alert, Typography } from "@material-tailwind/react";

const Login = ({ setToken }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [alert, setAlert] = useState({ type: "", message: "" });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setAlert({ type: "", message: "" }); // Reset alert before login attempt

    try {
      const { data } = await login({ email, password });
      setToken(data.token);
      localStorage.setItem("token", data.token); // Token im localStorage speichern
    } catch (err) {
      if (err.response?.status === 403) {
        setAlert({
          type: "error",
          message: "Ihr Account wurde noch nicht verifiziert. Bitte überprüfen Sie Ihre E-Mails.",
        });
      } else if (err.response?.status === 401) {
        setAlert({
          type: "error",
          message: "E-Mail oder Passwort falsch. Bitte erneut versuchen.",
        });
      } else {
        setAlert({
          type: "error",
          message: "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.",
        });
      }
    }
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="block mb-6 text-center">
          <img
            className="w-auto h-8 mx-auto mb-6"
            src="img/logo.svg"
            alt="Logo"
          />
          <h2 className="pb-4 text-2xl font-bold text-gray-900 dark:text-white">Anmelden</h2>
        </div>
        <div className="w-full bg-white rounded-lg shadow dark:border sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-6 sm:p-8">
            <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  E-Mail
                </label>
                <Input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="block w-full rounded-md py-1.5 text-gray-900 shadow-sm !border !border-gray-300 bg-white ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:!border-gray-900 focus:!border-t-gray-900"
                  labelProps={{ className: "hidden" }} // Label im Input ausblenden
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                >
                  Passwort
                </label>
                <Input
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  variant="outlined"
                  className="block w-full rounded-md py-1.5 text-gray-900 shadow-sm !border !border-gray-300 bg-white ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:!border-gray-900 focus:!border-t-gray-900"
                  labelProps={{ className: "hidden" }} // Label im Input ausblenden
                />
              </div>
              <button
                type="submit"
                className="w-full text-white bg-gray-800 hover:bg-gray-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Anmelden
              </button>
              {alert.message && (
                <Alert
                  color={alert.type === "error" ? "red" : "green"}
                  icon={
                    alert.type === "error" ? (
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
                          <path d="M14.9994 15L9 9M9.00064 15L15 9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z" stroke="currentColor" strokeWidth="1.5" />
                      </svg>
                    ) : null
                  }
                  className="mt-4"
                >
                  <Typography variant="h6" color="white">
                    {alert.message}
                  </Typography>
                </Alert>
              )}
              <hr className="mt-8 border-gray-200 dark:border-gray-800" />
              {/* Registrieren Link */}
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Neu bei EvoCan?{" "}
                <Link
                  to="/register"
                  className="font-medium text-primary-600 hover:underline dark:text-primary-500"
                >
                  Jetzt registrieren
                </Link>
              </p>
              {/* Passwort zurücksetzen Link */}
              <p className="text-sm text-gray-500 dark:text-gray-400">
                <Link
                  to="/password-reset-request"
                  className="font-medium text-primary-600 hover:underline dark:text-primary-500"
                >
                  Passwort vergessen?
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;