// components/Layout/Layout.js

import React, { useState, useEffect } from "react";
import Sidebar from "../Layout/Sidebar";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { fetchPatientDetails } from "../../services/api";

const Layout = ({ onLogout, children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Ladezustand
  const [patientDetails, setPatientDetails] = useState({});
  const [error, setError] = useState(null); // Fehlerzustand

  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    onLogout();
  };

  useEffect(() => {
    if (isSidebarOpen) {
      document.body.style.overflow = "hidden"; // Verhindert Scrollen, wenn Sidebar geöffnet ist
    } else {
      document.body.style.overflow = "auto"; // Aktiviert Scrollen wieder
    }
  }, [isSidebarOpen]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Ladezustand aktivieren
      try {
        const response = await fetchPatientDetails();
        setPatientDetails(response.data);
      } catch (err) {
        console.error("Fehler beim Abrufen der Patientendaten:", err);
        setError("Daten konnten nicht abgerufen werden.");
      } finally {
        setIsLoading(false); // Ladezustand deaktivieren
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Sidebar
        isSidebarOpen={isSidebarOpen}
        onToggleSidebar={handleToggleSidebar}
        patientDetails={{
          fullName: isLoading ? "Lade Daten..." : patientDetails.fullName || "",
          email: isLoading ? "Lade Daten..." : patientDetails.email || "",
        }}
        onLogout={handleLogout}
      />
            
      <div className="wrapper lg:ps-[260px] w-full flex flex-col min-h-screen">
        {/* Content Wrapper */}
        <Header onToggleSidebar={() => handleToggleSidebar(true)} />

        {/* Main Content */}
        <main id="content" className="flex-grow py-4 px-2 sm:px-2 md:px-4">
          {children}
        </main>

        {/* Footer */}
        <Footer />
     </div>
    </>
  );
};

export default Layout;