import React, { useState, useEffect } from "react";
import axios from "axios";
import { fetchSessions } from "../../services/api";
import { Alert, Typography } from "@material-tailwind/react";

const SessionRequestForm = ({ apiKey, patientDetails, onSubmit, onClose }) => {
  const [step, setStep] = useState(1);
  const [meetingSlug, setMeetingSlug] = useState("");
  const [availableMeetingTypes, setAvailableMeetingTypes] = useState([]);
  const [sessions, setSessions] = useState([]); // Liste der bisherigen Termine
  const [loading, setLoading] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [availableDays, setAvailableDays] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);
  const [slots, setSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);

  const today = new Date(
    new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" })
  );

  const allMeetingTypes = [
    { slug: "eg-online", name: "Erstgespräch Online", description: "Erstgespräch zur Cannabistherapie als telemedizinische Videosprechstunde", duration: "30 Min." },
    { slug: "eg-muenchen", name: "Erstgespräch vor Ort", description: "Erstgespräch zur Cannabistherapie vor Ort in München", duration: "30 Min." },
    { slug: "videosprechstunde-regulaer", name: "Videosprechstunde regulär", description: "Reguläre Videosprechstunde zur quartalsweisen Evaluation und Anpassung der Therapie", duration: "15 Min." },
    { slug: "videosprechstunde-kurz", name: "Videosprechstunde kurz", description: "Kurze Bedarfssprechstunde bei Problemen oder Änderungswünschen. Ersetzt nicht die reguläre Sprechstunde!", duration: "10 Min." },
  ];

  

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Ladezustand aktivieren
      try {
        const sessionsResponse = await fetchSessions();
        console.log("Sitzungen:", sessionsResponse.data); // Debug
        setSessions(sessionsResponse.data);
      } catch (err) {
        console.error("Fehler beim Abrufen der Sitzungen:", err);
      } finally {
        setLoading(false); // Ladezustand beenden
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (!loading) {
      // Meeting-Typen basierend auf geladenen Sitzungen filtern
      setAvailableMeetingTypes(filterMeetingTypes());
    }
  }, [loading, sessions]);

const filterMeetingTypes = () => {
  if (!Array.isArray(sessions) || sessions.length === 0) {
    console.warn("Keine Sitzungen gefunden. Zeige Standard-Meeting-Typen an.");
    return allMeetingTypes.filter((type) =>
      ["eg-online", "eg-muenchen"].includes(type.slug)
    );
  }

  const hasEG = sessions.some((session) => {
    return session.name?.includes("EG") || session.path?.includes("EG");
  });

  const hasVideosprechstunde = sessions.some((session) => {
    return session.name?.includes("VSS") || session.path?.includes("VSS");
  });

  if (!hasEG) {
    return allMeetingTypes.filter((type) =>
      ["eg-online", "eg-muenchen"].includes(type.slug)
    );
  } else {
    return allMeetingTypes.filter((type) =>
      ["videosprechstunde-kurz", "videosprechstunde-regulaer"].includes(type.slug)
    );
  }
};

  useEffect(() => {
    // Termine abrufen, wenn das Formular geladen wird
    fetchSessions();
  }, []);

  useEffect(() => {
    // Meeting-Typen basierend auf den bisherigen Terminen filtern
    setAvailableMeetingTypes(filterMeetingTypes());
  }, [sessions]);

const fetchAvailableDays = async () => {
  if (!meetingSlug) return;
  setLoading(true);
  try {
    const response = await axios.get(
      `https://evocan.neetocal.com/api/external/v1/slots/${meetingSlug}`,
      {
        headers: { "X-Api-Key": apiKey },
        params: {
          time_zone: "Europe/Berlin",
          year: currentMonth.getFullYear(),
          month: currentMonth.getMonth() + 1,
        },
      }
    );

    // Nur Tage mit mindestens einem Slot behalten
    const filteredDays = (response.data.slots || []).filter(
      (day) => Object.keys(day.slots).length > 0
    );

    setAvailableDays(filteredDays);
  } catch (error) {
    console.error("Fehler beim Abrufen der verfügbaren Tage:", error);
  } finally {
    setLoading(false);
  }
};

  const handleDaySelection = (day) => {
    setSelectedDay(day);
    const newSlots = Object.entries(day.slots || {}).map(([_, value]) => value);
    setSlots(newSlots);

    // Reset selected slot and auto-select the first available slot if any
    if (newSlots.length > 0) {
      setSelectedSlot(newSlots[0]); // Automatically select the first slot
    } else {
      setSelectedSlot(null); // Reset slot if no slots are available
    }
  };

  const handleBooking = async () => {
    if (!selectedSlot) {
      alert("Bitte einen Slot auswählen.");
      return;
    }

    // Fallback: Name auf E-Mail setzen, falls Name nicht verfügbar
    const bookingName = patientDetails.fullName || patientDetails.email;

    setLoading(true);
    try {
      await axios.post(
        "https://evocan.neetocal.com/api/external/v1/bookings",
        {
          meeting_slug: meetingSlug,
          name: bookingName, //  entweder  Name oder E-Mail als fallback
          email: patientDetails.email,
          slot_date: selectedDay.date,
          slot_start_time: selectedSlot.start_time,
          time_zone: "Europe/Berlin",
        },
        { headers: { "X-Api-Key": apiKey, "Content-Type": "application/json" } }
      );
      setSuccessMessageVisible(true); // Erfolgsmeldung anzeigen
    } catch (error) {
      console.error("Fehler beim Buchen", error);
      alert("Buchung fehlgeschlagen.");
    } finally {
      setLoading(false);
    }
  };

  const handleMonthChange = (direction) => {
    setCurrentMonth(
      new Date(
        currentMonth.getFullYear(),
        currentMonth.getMonth() + direction,
        1
      )
    );
  };

  const formatDate = (date) => {
    const [year, month, day] = date.split("-");
    return `${day}.${month}.${year}`;
  };

  const formatTime24h = (time) => {
    const isPM = time.toLowerCase().includes("pm");
    let [hour, minute] = time.replace(/(am|pm)/i, "").trim().split(":").map(Number);

    if (isPM && hour !== 12) hour += 12;
    if (!isPM && hour === 12) hour = 0;

    return `${hour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}`;
  };

  const generateCalendarDays = () => {
    const firstDayOfMonth = new Date(
      Date.UTC(currentMonth.getFullYear(), currentMonth.getMonth(), 1)
    );
    const lastDayOfMonth = new Date(
      Date.UTC(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0)
    );

    const daysInMonth = Array.from(
      { length: lastDayOfMonth.getDate() },
      (_, index) =>
        new Date(
          Date.UTC(currentMonth.getFullYear(), currentMonth.getMonth(), index + 1)
        )
    );

    const startPadding = (firstDayOfMonth.getUTCDay() + 6) % 7; // Montag = 0
    const endPadding = (7 - ((lastDayOfMonth.getUTCDay() + 1) % 7)) % 7;

    const calendarDays = [
      ...Array(startPadding).fill(null),
      ...daysInMonth,
      ...Array(endPadding).fill(null),
    ];

    return calendarDays;
  };

  const [successMessageVisible, setSuccessMessageVisible] = useState(false);

  useEffect(() => {
    if (step === 2) fetchAvailableDays();
  }, [step, meetingSlug, currentMonth]);

  return (
    <div>
      {successMessageVisible ? (
        <Alert className="bg-primary-800">
          <Typography variant="h5" color="white">
            Erfolgreich gebucht!
          </Typography>
          <Typography color="white" className="mt-2 mb-5 font-normal">
            Ihre Terminbuchung ist eingegangen. Sie erhalten eine Bestätigung mit Link zur Sprechstunde per E-Mail!
          </Typography>
          <div className="mt-4">
            <button
              onClick={onClose}
              className="py-2 px-4 bg-gray-800 text-white rounded-lg shadow-sm hover:bg-gray-700"
            >
              Schließen
            </button>
          </div>
        </Alert>
      ) : (
        <>

{step === 1 && (
  <div className="space-y-6 overflow-hidden">
    {!loading && (
      <h3 className="flex items-start mb-1 text-md font-semibold text-gray-900 dark:text-white">
        Welche Sprechstunde möchten Sie buchen?
      </h3>
    )}
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 overflow-auto">
      {loading ? (
        // Ladeanzeige
        <div className="col-span-2 min-h-[200px] flex justify-center items-center text-gray-500">
          <p>Lade verfügbare Termine...</p>
        </div>
      ) : availableMeetingTypes.length > 0 ? (
        // Meeting-Typen anzeigen
        availableMeetingTypes.map((type) => (
          <button
            key={type.slug}
            onClick={() => setMeetingSlug(type.slug)}
            className={`p-4 text-gray-800 border-2 rounded-lg flex items-start space-x-4 ${
              meetingSlug === type.slug ? "border-primary-600" : "border-gray-300"
            }`}
          >
            {/* SVG Icon */}
            <div className="flex-shrink-0">
              {type.slug === "eg-online" && (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
                    <path d="M14 2H10C6.72077 2 5.08116 2 3.91891 2.81382C3.48891 3.1149 3.1149 3.48891 2.81382 3.91891C2 5.08116 2 6.72077 2 10C2 13.2792 2 14.9188 2.81382 16.0811C3.1149 16.5111 3.48891 16.8851 3.91891 17.1862C5.08116 18 6.72077 18 10 18H14C17.2792 18 18.9188 18 20.0811 17.1862C20.5111 16.8851 20.8851 16.5111 21.1862 16.0811C22 14.9188 22 13.2792 22 10C22 6.72077 22 5.08116 21.1862 3.91891C20.8851 3.48891 20.5111 3.1149 20.0811 2.81382C18.9188 2 17.2792 2 14 2Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                    <path d="M14.5 22L14.1845 21.5811C13.4733 20.6369 13.2969 19.1944 13.7468 18M9.5 22L9.8155 21.5811C10.5267 20.6369 10.7031 19.1944 10.2532 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                    <path d="M7 22H17" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                    <path d="M8 14C9.83846 11.4046 14.1188 11.263 16 14M14 8C14 9.10457 13.1046 10 12 10C10.8954 10 10 9.10457 10 8C10 6.89543 10.8954 6 12 6C13.1046 6 14 6.89543 14 8Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
              )}
              {type.slug === "eg-muenchen" && (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
                  <path d="M14 22V8C14 5.17157 14 3.75736 13.1213 2.87868C12.2426 2 10.8284 2 8 2C5.17157 2 3.75736 2 2.87868 2.87868C2 3.75736 2 5.17157 2 8V16C2 18.8284 2 20.2426 2.87868 21.1213C3.75736 22 5.17157 22 8 22H14Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                  <path d="M6.5 11H5.5M10.5 11H9.5M6.5 7H5.5M6.5 15H5.5M10.5 7H9.5M10.5 15H9.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                  <path d="M18.5 15H17.5M18.5 11H17.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                  <path d="M18 8H14V22H18C19.8856 22 20.8284 22 21.4142 21.4142C22 20.8284 22 19.8856 22 18V12C22 10.1144 22 9.17157 21.4142 8.58579C20.8284 8 19.8856 8 18 8Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
              </svg>
              )}
              {type.slug === "videosprechstunde-kurz" && (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
                    <path d="M8.62814 12.6736H8.16918C6.68545 12.6736 5.94358 12.6736 5.62736 12.1844C5.31114 11.6953 5.61244 11.0138 6.21504 9.65083L8.02668 5.55323C8.57457 4.314 8.84852 3.69438 9.37997 3.34719C9.91142 3 10.5859 3 11.935 3H14.0244C15.6632 3 16.4826 3 16.7916 3.53535C17.1007 4.0707 16.6942 4.78588 15.8811 6.21623L14.8092 8.10188C14.405 8.81295 14.2029 9.16849 14.2057 9.45952C14.2094 9.83775 14.4105 10.1862 14.7354 10.377C14.9854 10.5239 15.3927 10.5239 16.2074 10.5239C17.2373 10.5239 17.7523 10.5239 18.0205 10.7022C18.3689 10.9338 18.5513 11.3482 18.4874 11.7632C18.4382 12.0826 18.0918 12.4656 17.399 13.2317L11.8639 19.3523C10.7767 20.5545 10.2331 21.1556 9.86807 20.9654C9.50303 20.7751 9.67833 19.9822 10.0289 18.3962L10.7157 15.2896C10.9826 14.082 11.1161 13.4782 10.7951 13.0759C10.4741 12.6736 9.85877 12.6736 8.62814 12.6736Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
                </svg>
              )}
              {type.slug === "videosprechstunde-regulaer" && (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
                    <path d="M16.5 2V5.5M7.5 2V5.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M21 12.5V11.5C21 7.72876 21 5.84315 19.8284 4.67157C18.6568 3.5 16.7712 3.5 13 3.5H11C7.22876 3.5 5.34315 3.5 4.17157 4.67157C3 5.84315 3 7.72876 3 11.5V13.5C3 17.2712 3 19.1569 4.17157 20.3284C5.34315 21.5 7.22876 21.5 11 21.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M3.5 9H20.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <circle cx="17" cy="18" r="4" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M18 19L17 18V16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              )}
            </div>

            {/* Text Content */}
            <div className="text-left">
              <h3 className="mb-1 text-md font-semibold text-gray-900 dark:text-white">
                {type.name}
              </h3>
              <p className="leading-relaxed text-gray-500 dark:text-gray-500">
                {type.description}
              </p>
              <p className="text-base leading-relaxed text-gray-500 dark:text-gray-500">
                Dauer ca. {type.duration}
              </p>
            </div>
          </button>
        ))
      ) : (
        // Wenn keine Meeting-Typen verfügbar sind
        <div className="col-span-2 text-center text-gray-500">
          <p>Keine verfügbaren Termine gefunden.</p>
        </div>
      )}
    </div>
  </div>
)}
        {step === 2 && (
          <div className="space-y-4">
            <h3 className="text-md font-semibold text-gray-800">
              Wann soll der Termin stattfinden?
            </h3>
            <div className="flex flex-wrap lg:flex-nowrap justify-between">
              {/* Linke Seite */}
              <div className="w-full lg:w-1/2 my-4 lg:mx-10 p-6 shadow-md rounded-xl border-1">
                <div className="flex justify-between items-center mb-4">
                  <button
                    type="button"
                    className="text-gray-500 hover:text-black"
                    onClick={() => handleMonthChange(-1)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width={24}
                      height={24}
                      color={"currentColor"}
                      fill={"none"}
                    >
                      <path
                        d="M15 6C15 6 9.00001 10.4189 9 12C8.99999 13.5812 15 18 15 18"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <span className="font-semibold text-gray-700">
                    {currentMonth.toLocaleString("de-DE", {
                      month: "long",
                      year: "numeric",
                    })}
                  </span>
                  <button
                    type="button"
                    className="text-gray-500 hover:text-black"
                    onClick={() => handleMonthChange(1)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width={24}
                      height={24}
                      color={"currentColor"}
                      fill={"none"}
                    >
                      <path
                        d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <div className="grid grid-cols-7 gap-2 text-center">
                  {["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"].map((day, index) => (
                    <div key={index} className="text-sm font-bold">
                      {day}
                    </div>
                  ))}
                  {generateCalendarDays().map((day, index) => (
                    <button
                      key={index}
                      className={`p-2 h-10 ${
                        day &&
                        (day.toISOString().split("T")[0] === today.toISOString().split("T")[0]
                          ? "rounded-xl text-primary-500 ring-1 ring-primary-500"
                          : selectedDay?.date === day?.toISOString().split("T")[0]
                          ? "bg-primary-500 text-white rounded-xl"
                          : availableDays.some(
                              (d) => d.date === day?.toISOString().split("T")[0]
                            )
                          ? "text-gray-800"
                          : "pointer-events-none text-gray-300")
                      }`}
                      onClick={() => {
                        const selected = availableDays.find(
                          (d) => d.date === day?.toISOString().split("T")[0]
                        );
                        if (selected) handleDaySelection(selected);
                      }}
                      disabled={!day}
                    >
                      {day?.getDate()}
                    </button>
                  ))}
                </div>
              </div>

              {/* Rechte Seite */}
              <div className="w-full lg:w-1/2 my-4 lg:mx-10">
                {slots.length > 0 && (
                  <>
                    <h3 className="mb-1 text-md font-semibold text-gray-900 dark:text-white">
                      {selectedDay
                        ? new Date(selectedDay.date).toLocaleDateString("de-DE", {
                            weekday: "long",
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          })
                        : "Termin wählen"}
                    </h3>
                    <p className="mb-5 leading-relaxed text-gray-500 dark:text-gray-500">
                      Bitte wählen Sie eine gewünschte Uhrzeit
                    </p>
                  </>
                )}
                <div className="grid grid-cols-4 gap-2">
                  {slots.length > 0 ? (
                    slots.map((slot, index) => (
                      <button
                        key={index}
                        className={`p-2 rounded-lg border ${
                          selectedSlot?.start_time === slot.start_time
                            ? "bg-primary-600 text-white border-primary-600"
                            : "text-gray-500 border-gray-300"
                        }`}
                        onClick={() => setSelectedSlot(slot)}
                      >
                        {formatTime24h(slot.start_time)}
                      </button>
                    ))
                  ) : (
                    <div className="col-span-4">
                      <div className="flex justify-center items-center size-[46px] bg-gray-100 rounded-lg dark:bg-neutral-800">
                        <svg
                          className="shrink-0 size-6 text-gray-600 dark:text-neutral-400"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          fill="transparent"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z"
                            stroke="currentColor"
                            strokeWidth="1.5"
                          />
                          <path
                            d="M12.2422 17V12C12.2422 11.5286 12.2422 11.2929 12.0957 11.1464C11.9493 11 11.7136 11 11.2422 11"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M11.992 8H12.001"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <h2 className="mt-5 font-semibold text-lg text-gray-800 dark:text-white">
                        Keine Termine gefunden
                      </h2>
                      <p className="mt-2 text-sm text-gray-600 dark:text-neutral-400">
                        Am ausgewählten Datum sind keine Zeiten verfügbar.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {step === 3 && (
            <div className="space-y-8">
            <h3 className="text-md font-semibold text-gray-800">
              Zusammenfassung Ihrer Terminbuchung
            </h3>
            <div className="my-4 grid grid-cols-2 sm:grid-cols-2 gap-6">
              <div>
                <span className="block text-xs uppercase text-gray-500">Typ</span>
                <span className="block text-md font-medium text-gray-800">
                  {allMeetingTypes.find((t) => t.slug === meetingSlug)?.name}
                </span>
              </div>
              <div>
                <span className="block text-xs uppercase text-gray-500">Datum</span>
                <span className="block text-md font-medium text-gray-800">
                  {selectedDay
                    ? new Date(selectedDay.date).toLocaleDateString("de-DE", {
                        weekday: "long",
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      })
                    : "Nicht verfügbar"}
                </span>
              </div>
              <div>
                <span className="block text-xs uppercase text-gray-500">Uhrzeit</span>
                <span className="block text-md font-medium text-gray-800">
                  {selectedSlot ? formatTime24h(selectedSlot.start_time) : "Nicht verfügbar"} Uhr
                </span>
              </div>
              <div>
                <span className="block text-xs uppercase text-gray-500">Dauer</span>
                <span className="block text-md font-medium text-gray-800">
                  ca. {allMeetingTypes.find((t) => t.slug === meetingSlug)?.duration}
                </span>
              </div>
            </div>

            {/* Hinweis zur Verbindlichkeit */}
            <div className="mt-8">
              <p className="text-sm text-gray-800 font-semibold">Hinweis</p>
              <p className="text-sm text-gray-500">
                Mit der Buchung des Termins erkennen Sie an, dass es sich um eine verbindliche Vereinbarung handelt. 
                Bitte beachten Sie, dass bei einer Absage innerhalb von weniger als 24 Stunden vor dem Termin ein Ausfallhonorar 
                gemäß den hinterlegten Kosteninformationen berechnet werden muss.
              </p>
            </div>
          </div>
        )}
        <div className="flex items-center mt-12">
          {step > 1 && (
            <button
              type="button"
              onClick={() => setStep(step - 1)}
              className="py-2 px-3 text-sm font-medium border border-gray-200 bg-white text-gray-800 rounded-lg shadow-sm hover:bg-gray-50"
            >
              Zurück
            </button>
          )}
            <div className={`ml-auto ${step > 1 ? "ml-4" : ""}`}>
              {step === 1 ? (
                <button
                  type="button"
                  onClick={() => setStep(2)}
                  disabled={!meetingSlug}
                  className={`py-2 px-3 text-sm font-medium bg-gray-800 text-white rounded-lg ${
                    !meetingSlug ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-700"
                  }`}
                >
                  Weiter
                </button>
              ) : step === 2 ? (
                <button
                  type="button"
                  onClick={() => setStep(3)}
                  disabled={!selectedSlot}
                  className={`py-2 px-3 text-sm font-medium bg-gray-800 text-white rounded-lg ${
                    !selectedSlot ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-700"
                  }`}
                >
                  Weiter
                </button>
              ) : (
                <button
                  type="button"
                  onClick={handleBooking} // Ensure the booking is handled here
                  disabled={!selectedSlot}
                  className={`py-2 px-3 text-sm font-medium bg-gray-800 text-white rounded-lg ${
                    !selectedSlot ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-700"
                  }`}
                >
                  Absenden
                </button>
              )}
            </div>
          </div>
        </>
      )}
    </div>

  );
};

export default SessionRequestForm;