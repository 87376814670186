import React from "react";
import { Link } from "react-router-dom";

const PrescriptionRequestsTable = ({
  isLoading,
  prescriptionRequests,
  renderSkeleton,
  formatDate,
}) => {

  // Limit the number of displayed entries
  const limitedprescriptionRequests = prescriptionRequests.slice(0, 4);

  return (

      <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden">
        <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-neutral-700">
          <div>
            <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200 inline-flex items-center gap-x-2">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
                  <path d="M19.7502 11V10C19.7502 6.22876 19.7502 4.34315 18.5786 3.17157C17.407 2 15.5214 2 11.7502 2H10.7503C6.97907 2 5.09346 2 3.92189 3.17156C2.75032 4.34312 2.7503 6.22872 2.75027 9.99993L2.75024 14C2.7502 17.7712 2.75019 19.6568 3.92172 20.8284C5.09329 21.9999 6.97897 22 10.7502 22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M7.25024 7H15.2502M7.25024 12H15.2502" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                  <path d="M13.2502 20.8268V22H14.4236C14.833 22 15.0377 22 15.2217 21.9238C15.4058 21.8475 15.5505 21.7028 15.84 21.4134L20.6636 16.5894C20.9366 16.3164 21.0731 16.1799 21.1461 16.0327C21.285 15.7525 21.285 15.4236 21.1461 15.1434C21.0731 14.9961 20.9366 14.8596 20.6636 14.5866C20.3905 14.3136 20.254 14.1771 20.1067 14.1041C19.8265 13.9653 19.4975 13.9653 19.2173 14.1041C19.0701 14.1771 18.9335 14.3136 18.6605 14.5866L18.6605 14.5866L13.8369 19.4106C13.5474 19.7 13.4027 19.8447 13.3265 20.0287C13.2502 20.2128 13.2502 20.4174 13.2502 20.8268Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
              </svg>
              Rezeptanfragen
            </h2>
            <p className="text-sm text-gray-600 dark:text-neutral-400">
              Ihre aktuellen Rezeptanfragen
            </p>
          </div>

          <div>
            <div className="inline-flex gap-x-2">
              <Link
                to="/prescriptions"
                className="py-2 px-3 inline-flex items-center gap-x-2 text-xs font-medium rounded border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
              >
                Alle anzeigen
              </Link>
            </div>
          </div>
        </div>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="ps-6 py-3 text-start">
                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800">Datum</span>
              </th>
              <th scope="col" className="px-6 py-3 text-start">
                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800">Status</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
              {isLoading ? (
                  renderSkeleton(3, 3)
                ) : limitedprescriptionRequests.length > 0 ? (
                  limitedprescriptionRequests.map((item, index) => (
                <tr key={index}>
                  <td className="px-6 py-2 align-middle whitespace-nowrap">
                    <span className="text-sm text-gray-600">{formatDate(item.name)}</span>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    <span
                      className={`py-1 px-2 inline-flex items-center gap-x-2 text-xs font-medium rounded-full ${
                        item.status === "Eingegangen" ? "bg-gray-100 text-amber-800" : "bg-gray-100 text-primary-800"
                      }`}
                    >
                      <div
                        className={`flex items-center justify-center w-3 h-3 p-0.5 rounded-full ${
                          item.status === "Eingegangen" ? "bg-amber-800" : "bg-primary-800"
                        }`}
                      >
                        {item.status === "Eingegangen" ? (
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#ffffff"} fill={"none"}>
                            <path d="M12 2V12L16 16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        ) : (
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#ffffff"} fill={"none"}>
                            <path d="M5 14L8.5 17.5L19 6.5" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        )}
                      </div>
                      {item.status}
                    </span>
                  </td>
                </tr>
              ))
            ) : (
            <tr>
              <td colSpan="3" className="text-center p-2">
                <p className="text-sm text-gray-600 dark:text-neutral-400">Keine Rezeptanfragen gefunden.</p>
              </td>
            </tr>
            )}
          </tbody>
        </table>
      </div>

  );
};

export default PrescriptionRequestsTable;