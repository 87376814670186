import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useOutsideClick } from "../../utils/useOutsideClick";
import { Link } from "react-router-dom";

const ITEMS_PER_PAGE = 10;

const AdvancedUpcomingSessionsTable = ({
  clientEmail,
  formatDate,
  sessionTypeMap,
  renderSkeleton,
}) => {
  const [sessions, setSessions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortField, setSortField] = useState("starts_at");
  const [sortOrder, setSortOrder] = useState("desc");
  const [filterType, setFilterType] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [selectedBookingSid, setSelectedBookingSid] = useState(null);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [cancelMessage, setCancelMessage] = useState(null); // Erfolg oder Fehler Nachricht
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  useOutsideClick(dropdownRef, buttonRef, () => setIsDropdownOpen(false));

  useEffect(() => {
    const fetchBookings = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        return;
      }
      setIsLoading(true);
      try {
        const response = await axios.get(`${backendUrl}/patient/bookings`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        // Sicherstellen, dass response.data ein Array ist
        setSessions(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
        console.error("Fehler beim Abrufen der Buchungen:", error);
        setSessions([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBookings(); // Entfernen der Abhängigkeit von clientEmail
  }, []); // Leeres Abhängigkeitsarray, da clientEmail nicht mehr benötigt wird

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const filteredAndSortedSessions = () => {
    const searchFiltered = sessions.filter((session) =>
      session.meeting.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return searchFiltered.sort((a, b) => {
      if (sortField === "starts_at") {
        const dateA = new Date(a.starts_at);
        const dateB = new Date(b.starts_at);
        return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
      }
      return 0;
    });
  };

const handleCancel = async () => {
  const token = localStorage.getItem("token");
  if (!token) {
    alert("Kein Token gefunden. Bitte melden Sie sich erneut an.");
    return;
  }

  if (!cancelReason.trim()) {
    alert("Bitte geben Sie einen Grund für die Stornierung an.");
    return;
  }

  if (!selectedBookingSid) {
    alert("Keine Buchungs-ID ausgewählt.");
    return;
  }

  try {
    const response = await axios.post(
      `${backendUrl}/patient/bookings/${selectedBookingSid}/cancel`,
      {
        bookingSid: selectedBookingSid,
        cancelReason: cancelReason,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // Erfolgsmeldung setzen und Modal schließen
    setCancelMessage(response.data.message); // Setze die Erfolgsmeldung
    setTimeout(() => {
      closeCancelModal(); // Schließe das Modal nach einer kurzen Verzögerung
    }, 1500); // Warte 1,5 Sekunden, damit die Nachricht angezeigt wird
  } catch (error) {
    console.error("Fehler beim Stornieren der Buchung:", error);

    // Fehlernachricht setzen
    setCancelMessage(
      `Es gab ein Problem beim Stornieren der Buchung: ${
        error.response?.data?.message || "Unbekannter Fehler"
      }`
    );
  }
};

  const openCancelModal = (sid) => {
    setSelectedBookingSid(sid); // ID des Termins setzen
    setIsCancelModalOpen(true); // Modal öffnen
  };

  const closeCancelModal = () => {
    setIsCancelModalOpen(false);
    setCancelReason("");
    setSelectedBookingSid(null);
  };

  const filteredSessions = filteredAndSortedSessions();
  const totalItems = filteredSessions.length;
  const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE);

  const paginatedSessions = filteredSessions.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const formatDateTime = (dateString) => {
    return new Date(dateString).toLocaleString("de-DE", {
      dateStyle: "medium",
      timeStyle: "short",
    });
  };

  return (
    <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-x-hidden">
      <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-neutral-700">
        <div>
          <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200 inline-flex items-center gap-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
              <path d="M16.5 2V5.5M7.5 2V5.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M21 12.5V11.5C21 7.72876 21 5.84315 19.8284 4.67157C18.6568 3.5 16.7712 3.5 13 3.5H11C7.22876 3.5 5.34315 3.5 4.17157 4.67157C3 5.84315 3 7.72876 3 11.5V13.5C3 17.2712 3 19.1569 4.17157 20.3284C5.34315 21.5 7.22876 21.5 11 21.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M3.5 9H20.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <circle cx="17" cy="18" r="4" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M18 19L17 18V16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            Geplante Termine
          </h2>
          <p className="text-sm text-gray-600 dark:text-neutral-400">
            Ihre gebuchten Sprechstunden
          </p>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {/* Datum & Uhrzeit-Spalte */}
                  <th
                    className="px-6 py-3 text-start cursor-pointer"
                    onClick={() => {
                      setSortField("starts_at");
                      setSortOrder(sortOrder === "desc" ? "asc" : "desc");
                    }}
                  >
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800">
                      Datum & Uhrzeit
                      {sortField === "starts_at" && (sortOrder === "desc" ? " ↓" : " ↑")}
                    </span>
                  </th>

                  {/* Typ-Spalte */}
                  <th
                    className="px-6 py-3 text-start cursor-pointer"
                    onClick={() => {
                      setSortField("type");
                      setSortOrder(sortOrder === "desc" ? "asc" : "desc");
                    }}
                  >
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800">
                      Typ
                      {sortField === "type" && (sortOrder === "desc" ? " ↓" : " ↑")}
                    </span>
                  </th>

                  {/* Gebucht am-Spalte */}
                  <th
                    className="px-6 py-3 text-start cursor-pointer"
                    onClick={() => {
                      setSortField("created_at");
                      setSortOrder(sortOrder === "desc" ? "asc" : "desc");
                    }}
                  >
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800">
                      Gebucht am
                      {sortField === "created_at" && (sortOrder === "desc" ? " ↓" : " ↑")}
                    </span>
                  </th>

                  {/* Link-Spalte */}
                  <th className="px-6 py-3 text-start">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800">

                    </span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {isLoading ? (
                  renderSkeleton(4, 5) // 5 Spalten
                ) : paginatedSessions.length > 0 ? (
                  paginatedSessions.map((item, index) => (
                    <tr key={index}>
                      {/* Datum & Uhrzeit */}
                      <td className="px-6 py-3 whitespace-nowrap">
                        <span className="text-sm text-gray-600">
                          {new Date(item.starts_at).toLocaleString("de-DE", {
                            dateStyle: "medium",
                            timeStyle: "short",
                          })}
                        </span>
                      </td>

                      {/* Typ */}
                      <td className="px-6 py-3 whitespace-nowrap">
                        <span className="text-sm text-gray-600">{item.meeting.name}</span>
                      </td>

                      {/* Gebucht am */}
                      <td className="px-6 py-3 whitespace-nowrap">
                        <span className="text-sm text-gray-600">
                          {new Date(item.created_at).toLocaleString("de-DE", {
                            dateStyle: "medium",
                            timeStyle: "short",
                          })}
                        </span>
                      </td>

                      {/* Link */}
                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="flex gap-x-2">
                          {/* Raum betreten */}
                          <button
                            onClick={() => window.open(item.room_url, "_blank")}
                            className="py-1 px-2 inline-flex items-center gap-x-2 text-xs font-medium rounded border border-gray-200 bg-white text-gray-800 hover:bg-gray-50 focus:outline-none focus:bg-gray-50 dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-5 h-5"
                              viewBox="0 0 24 24"
                              width={20}
                              height={20}
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M14 2H10C6.72 2 5.08 2 3.92 2.81C3.49 3.11 3.11 3.49 2.81 3.92C2 5.08 2 6.72 2 10C2 13.28 2 14.92 2.81 16.08C3.11 16.51 3.49 16.88 3.92 17.19C5.08 18 6.72 18 10 18H14C17.28 18 18.92 18 20.08 17.19C20.51 16.88 20.88 16.51 21.19 16.08C22 14.92 22 13.28 22 10C22 6.72 22 5.08 21.19 3.92C20.88 3.49 20.51 3.11 20.08 2.81C18.92 2 17.28 2 14 2Z" />
                              <path d="M14.5 22L14.18 21.58C13.47 20.64 13.3 19.19 13.75 18M9.5 22L9.81 21.58C10.53 20.64 10.7 19.19 10.25 18" />
                              <path d="M7 22H17" />
                              <path d="M8 14C9.83 11.4 14.12 11.26 16 14M14 8C14 9.1 13.1 10 12 10C10.9 10 10 9.1 10 8C10 6.9 10.9 6 12 6C13.1 6 14 6.9 14 8Z" />
                            </svg>
                            Raum betreten
                          </button>

                          {/* Stornieren */}
                          <button
                            onClick={() => openCancelModal(item.sid)}
                            className="py-1 px-2 inline-flex items-center gap-x-2 text-xs font-medium rounded border border-gray-200 bg-white text-red-800 hover:bg-gray-50 focus:outline-none focus:bg-gray-50 dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
                                <path d="M19.5 5.5L18.8803 15.5251C18.7219 18.0864 18.6428 19.3671 18.0008 20.2879C17.6833 20.7431 17.2747 21.1273 16.8007 21.416C15.8421 22 14.559 22 11.9927 22C9.42312 22 8.1383 22 7.17905 21.4149C6.7048 21.1257 6.296 20.7408 5.97868 20.2848C5.33688 19.3626 5.25945 18.0801 5.10461 15.5152L4.5 5.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                                <path d="M3 5.5H21M16.0557 5.5L15.3731 4.09173C14.9196 3.15626 14.6928 2.68852 14.3017 2.39681C14.215 2.3321 14.1231 2.27454 14.027 2.2247C13.5939 2 13.0741 2 12.0345 2C10.9688 2 10.436 2 9.99568 2.23412C9.8981 2.28601 9.80498 2.3459 9.71729 2.41317C9.32164 2.7167 9.10063 3.20155 8.65861 4.17126L8.05292 5.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                                <path d="M9.5 16.5L9.5 10.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                                <path d="M14.5 16.5L14.5 10.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                            </svg>
                            Termin stornieren
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="text-center p-2">
                      <div className="max-w-sm w-full min-h-[250px] flex flex-col items-center justify-center mx-auto px-6 py-4">
                        <div className="flex justify-center items-center size-[46px] bg-gray-100 rounded-lg dark:bg-neutral-800">
                          <svg
                            className="shrink-0 size-6 text-gray-600 dark:text-neutral-400"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            fill="transparent"
                            viewBox="0 0 24 24"
                          >
                            <path d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z" />
                            <path d="M12.2422 17V12C12.2422 11.5286 12.2422 11.2929 12.0957 11.1464C11.9493 11 11.7136 11 11.2422 11" />
                            <path d="M11.992 8H12.001" strokeWidth="2" />
                          </svg>
                        </div>
                        <h2 className="mt-5 font-semibold text-lg text-gray-800 dark:text-white">
                          Keine Termine gefunden
                        </h2>
                        <p className="mt-2 text-sm text-gray-600 dark:text-neutral-400">
                          Wir konnten keine Termine finden, die den Suchkriterien entsprechen.
                        </p>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
      </div>
      {/* Pagination */}
      <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-4 sm:px-6">
        <div className="flex flex-1 items-center justify-between">
          <div>
            <p className="text-sm text-gray-700">
              Zeige{" "}
              <span className="font-medium">
                {(currentPage - 1) * ITEMS_PER_PAGE + 1}
              </span>{" "}
              bis{" "}
              <span className="font-medium">
                {Math.min(currentPage * ITEMS_PER_PAGE, totalItems)}
              </span>{" "}
              von <span className="font-medium">{totalItems}</span> Einträgen
            </p>
          </div>
          <div>
            <nav
              aria-label="Pagination"
              className="isolate inline-flex -space-x-px rounded-md"
            >
              <button
                onClick={() => handlePageChange(1)}
                className="relative inline-flex items-center rounded-l-md px-1.5 py-1.5 text-gray-800 bg-white ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:z-20 focus:outline-offset-0"
                disabled={currentPage === 1}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
                    <path d="M15 6C15 6 9.00001 10.4189 9 12C8.99999 13.5812 15 18 15 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </button>
              {[...Array(totalPages)].map((_, index) => (
                <button
                  key={index}
                  onClick={() => handlePageChange(index + 1)}
                  className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                    currentPage === index + 1
                      ? "bg-gray-800 text-white ring-1 ring-inset ring-gray-300"
                      : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  }`}
                >
                  {index + 1}
                </button>
              ))}
              <button
                onClick={() => handlePageChange(totalPages)}
                className="relative inline-flex items-center rounded-r-md px-1.5 py-1.5 text-gray-800 bg-white ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:z-20 focus:outline-offset-0"
                disabled={currentPage === totalPages}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
                  <path d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              </button>
            </nav>
          </div>
        </div>
      </div>



{isCancelModalOpen && (
  <div className="fixed inset-0 z-[100] flex items-center justify-center bg-black bg-opacity-50">
    <div className="relative bg-white rounded-lg shadow-lg w-full max-w-md p-6">
      {/* Modal Header */}
      <div className="flex items-start justify-between mb-4">
        <h3 className="text-md font-semibold text-gray-900 dark:text-white">
          Termin stornieren
        </h3>
        <button
          onClick={closeCancelModal}
          className="text-gray-400 hover:bg-gray-200 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-5 h-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
          <span className="sr-only">Schließen</span>
        </button>
      </div>

{/* Modal Body */}
<p className="text-base leading-relaxed text-gray-500 mb-4">
  Bitte geben Sie einen Grund für die Stornierung an. Ihre Eingabe hilft uns, unsere Dienstleistungen zu verbessern.
</p>
<textarea
  className="w-full p-3 border rounded-md text-sm text-gray-800"
  placeholder="Grund für die Stornierung..."
  value={cancelReason}
  onChange={(e) => setCancelReason(e.target.value)}
></textarea>

{/* Erfolgs- oder Fehlermeldung anzeigen */}
{cancelMessage && (
  <div className={`mt-4 text-sm font-bold ${cancelMessage.includes("erfolgreich") ? "text-green-500" : "text-red-500"}`}>
    {cancelMessage}
  </div>
)}

{/* Modal Actions */}
<div className="flex items-center mt-6">
  <button
    onClick={closeCancelModal}
    className="py-2 px-3 text-sm font-medium border border-gray-200 bg-white text-gray-800 rounded-lg shadow-sm hover:bg-gray-50"
  >
    Abbrechen
  </button>
  <button
    onClick={handleCancel}
    disabled={!cancelReason}
    className={`ml-auto py-2 px-3 text-sm font-medium bg-red-600 text-white rounded-lg ${
      !cancelReason ? "opacity-50 cursor-not-allowed" : "hover:bg-red-700"
    }`}
  >
    Stornieren
  </button>
</div>

    </div>
  </div>
)}


    </div>
  );
};

export default AdvancedUpcomingSessionsTable;