// PasswordReset.js

import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { resetPassword } from "../services/api";
import { Input, Button, Alert, Typography } from "@material-tailwind/react";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const PasswordReset = () => {
  const query = useQuery();
  const token = query.get("token");

  const [newPassword, setNewPassword] = useState("");
  const [alert, setAlert] = useState({ type: "", message: "" });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!token) {
      setAlert({ type: "error", message: "Ungültiger oder fehlender Token." });
    }
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setAlert({ type: "", message: "" });

    if (!token) {
      setAlert({ type: "error", message: "Ungültiger oder fehlender Token." });
      return;
    }

    setIsSubmitting(true);

    try {
      await resetPassword(token, newPassword);
      setAlert({ type: "success", message: "Passwort erfolgreich zurückgesetzt." });
    } catch (err) {
      setAlert({
        type: "error",
        message: err.response?.data?.message || "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900 min-h-screen flex items-center justify-center">
      <div className="w-full max-w-md bg-white rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 p-6">
        <div className="block mb-6 text-center">
          <img
            className="w-auto h-8 mx-auto mb-6"
            src="img/logo.svg"
            alt="Logo"
          />
          <h2 className="pb-4 text-2xl font-bold text-gray-900 dark:text-white">Passwort zurücksetzen</h2>
        </div>
        <form className="space-y-4" onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor="newPassword"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Neues Passwort
            </label>
            <Input
              id="newPassword"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              minLength={8}
              className="block w-full rounded-md py-1.5 text-gray-900 shadow-sm !border !border-gray-300 bg-white ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:!border-gray-900 focus:!border-t-gray-900"
              labelProps={{ className: "hidden" }} // Label im Input ausblenden
            />
          </div>
          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full text-white bg-gray-800 hover:bg-gray-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          >
            {isSubmitting ? "Zurücksetzen..." : "Passwort zurücksetzen"}
          </button>
          {alert.message && (
            <Alert
              color={alert.type === "error" ? "red" : "green"}
              icon={
                alert.type === "error" ? (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
                      <path d="M14.9994 15L9 9M9.00064 15L15 9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z" stroke="currentColor" strokeWidth="1.5" />
                  </svg>
                ) : null
              }
              className="mt-4"
            >
              <Typography variant="h6" color="white">
                {alert.message}
              </Typography>
            </Alert>
          )}
          <hr className="mt-8 border-gray-200 dark:border-gray-800" />
          <p className="text-sm text-gray-500 dark:text-gray-400">
            Erinnern Sie sich wieder?{" "}
            <Link
              to="/login"
              className="font-medium text-primary-600 hover:underline dark:text-primary-500"
            >
              Jetzt anmelden
            </Link>
          </p>
        </form>
      </div>
    </section>
  );
};

export default PasswordReset;