import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Register from "./pages/Register";
import Invoices from "./pages/Invoices";
import Sessions from "./pages/Sessions";
import Prescriptions from "./pages/Prescriptions";
import Requests from "./pages/Requests";
import PasswordResetRequest from "./pages/PasswordResetRequest";
import PasswordReset from "./pages/PasswordReset";
import VerificationSuccess from "./pages/VerificationSuccess";
import VerificationError from "./pages/VerificationError";
import Layout from "./components/Layout/Layout";
import Account from "./pages/Account";
import Links from "./pages/Links";
import Files from "./pages/Files";

const App = () => {
  const [token, setToken] = useState(() => {
    const storedToken = localStorage.getItem("token");
    return storedToken || null;
  });

  useEffect(() => {
  }, [token]);

  const handleLogout = () => {
    setToken(null);
    localStorage.removeItem("token");  // Entfernen des Tokens aus localStorage
  };

  const handleLogin = (newToken) => {
    setToken(newToken);
    localStorage.setItem("token", newToken); // Persistiere das Token
  };

  useEffect(() => {
    const tokenCheckInterval = setInterval(() => {
      const storedToken = localStorage.getItem("token");
      if (!storedToken) {
        handleLogout(); // Logge den Nutzer aus, wenn kein Token vorhanden ist
      }
    }, 5000); // Überprüfe alle 5 Sekunden
    return () => clearInterval(tokenCheckInterval);
  }, []);

// Routes
  return (
    <Router>
      {!token ? (
        <Routes>
          <Route path="/login" element={<Login setToken={handleLogin} />} />
          <Route path="/register" element={<Register />} />
          <Route path="/password-reset-request" element={<PasswordResetRequest />} />
          <Route path="/password-reset" element={<PasswordReset />} />
          <Route path="/verify/success" element={<VerificationSuccess />} />
          <Route path="/verify/error" element={<VerificationError />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      ) : (
        <Layout onLogout={handleLogout}>
          <Routes>
            <Route path="/" element={<Dashboard token={token} />} />
            <Route path="/invoices" element={<Invoices token={token} />} />
            <Route path="/sessions" element={<Sessions token={token} />} />
            <Route path="/prescriptions" element={<Prescriptions token={token} />} />
            <Route path="/requests" element={<Requests token={token} />} />
            <Route path="/files" element={<Files />} />
            <Route path="/account" element={<Account />} />
            <Route path="/links" element={<Links />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Layout>
      )}
    </Router>
  );
};

export default App;