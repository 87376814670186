import React, { useState, useEffect } from "react";
import { Alert, Typography } from "@material-tailwind/react";
import axios from "axios";

const SessionInfoCard = ({ clientEmail, nextSession, sessions, isLoading }) => {
  const [isAlertOpen, setAlertOpen] = useState(true);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const validSessions = Array.isArray(sessions) ? sessions : [];

  // Filter und Berechnung
  const calculateNextVSS = () => {
    const vssSessions = validSessions
      .filter((session) => {
        const parts = session.name.split(" ");
        return parts[1] === "VSS"; // Nur reguläre "VSS"
      })
      .map((session) => {
        const dateStr = session.name.split(" ")[0];
        const year = `20${dateStr.slice(0, 2)}`;
        const month = dateStr.slice(2, 4) - 1; // Monat beginnt bei 0
        const day = dateStr.slice(4, 6);
        return new Date(year, month, day);
      })
      .sort((a, b) => b - a); // Sortiere absteigend

    return vssSessions[0]; // Nimm die letzte reguläre VSS
  };

  const lastVSS = calculateNextVSS();
  const nextVSS = lastVSS
    ? new Date(lastVSS.getTime() + 90 * 24 * 60 * 60 * 1000)
    : null;

  const today = new Date();
  const daysUntilNextVSS = Math.ceil((nextVSS - today) / (1000 * 60 * 60 * 24));

  // Warten, bis beide Ladezustände abgeschlossen sind
  if (isLoading) {
    return null; // Zeige nichts, solange Daten geladen werden
  }

  // Dynamische Nachricht basierend auf Bedingungen
  let message;
  if (nextSession) {
    return null; // Wenn ein Termin gebucht ist, zeige nichts
  } else if (!sessions || sessions.length === 0) {
    message = "Bitte buchen Sie ein Erstgespräch, um die Therapie zu starten.";
  } else if (!lastVSS) {
    message = "Es hat noch keine Videosprechstunde stattgefunden, bitte buchen Sie eine reguläre Videosprechstunde.";
  } else if (daysUntilNextVSS > 10) {
    message = `In spätestens ${daysUntilNextVSS} Tagen sollte Ihre nächste Videosprechstunde stattfinden. Bitte buchen Sie rechtzeitig Ihren Termin.`;
  } else if (daysUntilNextVSS <= 10) {
    message = "Zur Fortsetzung Ihrer Therapie ist eine reguläre Videosprechstunde notwendig. Bitte buchen Sie einen Termin.";
  } else {
    message = "Ihre nächste Videosprechstunde ist geplant. Bitte überprüfen Sie Ihren Kalender.";
  }

  return (
    <Alert
      open={isAlertOpen}
      className="border-[#2196F3] bg-[#E3F2FD] text-md font-medium"
      variant="outlined"
      color="blue"
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="#2196F3"
          className="h-6 w-6"
        >
          <path
            d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z"
            stroke="#2196F3"
            strokeWidth="1.5"
          />
          <path
            d="M12.2422 17V12C12.2422 11.5286 12.2422 11.2929 12.0957 11.1464C11.9493 11 11.7136 11 11.2422 11"
            stroke="#2196F3"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.992 8H12.001"
            stroke="#2196F3"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      onClose={() => setAlertOpen(false)}
    >
      <Typography variant="h5" color="blue" className="text-md text-[#0D47A1]">
        Hinweis
      </Typography>
      <Typography color="blue" className="mt-2 font-normal text-[#1565C0] text-sm">
        {message}
      </Typography>
    </Alert>
  );
};

export default SessionInfoCard;