import React, { useState, useRef } from "react";

const UploadModal = ({ isOpen, onClose, onUpload }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileType, setFileType] = useState("BE");
  const [alertMessage, setAlertMessage] = useState(null); // Einheitlicher Alert-Zustand
  const [alertType, setAlertType] = useState(null); // Alert-Typ ("success" oder "error")
  const [isUploading, setIsUploading] = useState(false); // Zustand für den Spinner
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    validateFile(file);
  };

  const validateFile = (file) => {
    setAlertMessage(null);
    setAlertType(null);

    if (!file) return;
    const allowedTypes = ["application/pdf", "image/jpeg"];
    if (!allowedTypes.includes(file.type)) {
      setAlertMessage("Nur PDF- oder JPG-Dateien sind erlaubt.");
      setAlertType("error");
      setSelectedFile(null);
    } else if (file.size > 8 * 1024 * 1024) {
      setAlertMessage("Die Datei darf nicht größer als 8 MB sein.");
      setAlertType("error");
      setSelectedFile(null);
    } else {
      setSelectedFile(file);
      setAlertMessage(null);
      setAlertType(null);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      setAlertMessage("Bitte wählen Sie eine gültige Datei aus.");
      setAlertType("error");
      return;
    }

    setAlertMessage(null);
    setAlertType(null);
    setIsUploading(true);

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("type", fileType);

    try {
      await onUpload(formData);
      setAlertMessage("Datei erfolgreich hochgeladen!");
      setAlertType("success");
      setSelectedFile(null);
    } catch (err) {
      setAlertMessage("Fehler beim Hochladen der Datei.");
      setAlertType("error");
    } finally {
      setIsUploading(false);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    validateFile(file);
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  if (!isOpen) return null;

  const FileTypeButtons = ({ fileType, setFileType }) => {
    const types = [
      {
        id: "BE",
        name: "Befunde",
        description: "Behandlungsunterlagen, Arztbriefe oder andere medizinische Berichte",
        icon: (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
              <path d="M20.5 16.9286V10C20.5 6.22876 20.5 4.34315 19.3284 3.17157C18.1569 2 16.2712 2 12.5 2H11.5C7.72876 2 5.84315 2 4.67157 3.17157C3.5 4.34315 3.5 6.22876 3.5 10V19.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
              <path d="M20.5 17H6C4.61929 17 3.5 18.1193 3.5 19.5C3.5 20.8807 4.61929 22 6 22H20.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
              <path d="M20.5 22C19.1193 22 18 20.8807 18 19.5C18 18.1193 19.1193 17 20.5 17" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
              <path d="M15 7L9 7" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M12 11L9 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        ),
      },
      {
        id: "UN",
        name: "Einverständniserklärung",
        description:
          "Unterschriebene Einverständniserklärung zu Kosten, Datenschutz und der Aufklärung zur Therapie",
        icon: (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
              <path d="M22 12.6344C18 16.1465 17.4279 10.621 15.3496 11.0165C13 11.4637 11.5 16.4445 13 16.4445C14.5 16.4445 12.5 10.5 10.5 12.5556C8.5 14.6111 7.85936 17.2946 6.23526 15.3025C-1.5 5.81446 4.99998 -1.14994 8.16322 3.45685C10.1653 6.37256 6.5 16.9769 2 22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M9 21H19" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        ),
      },
    ];

  return (
    <div className="grid grid-cols-2 gap-4">
      {types.map((type) => (
        <button
          key={type.id}
          onClick={() => setFileType(type.id)}
          className={`p-4 border-2 rounded-lg flex items-start space-x-4 ${
            fileType === type.id ? "border-primary-600" : "border-gray-300"
          }`}
        >
          <div className="flex-shrink-0 text-gray-800">{type.icon}</div>
          <div className="text-left">
            <h3 className="mb-1 text-md font-semibold text-gray-900 dark:text-white">
              {type.name}
            </h3>
            <p className="leading-relaxed text-gray-500 dark:text-gray-500">
              {type.description}
            </p>
          </div>
        </button>
      ))}
    </div>
  );
};


  return (
    <div className="fixed flex items-center justify-center bg-black bg-opacity-50 z-[100] overflow-y-auto overflow-x-hidden top-0 right-0 left-0 w-full md:inset-0 h-[calc(100%-1rem)] h-full">
      <div className="relative p-4 w-full max-w-4xl max-h-full">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          {/* Header */}
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h2 className="text-xl font-semibold text-gray-900">Datei hochladen</h2>

            <button
              onClick={onClose}
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Schließen</span>
            </button>
          </div>

          {/* Body */}
          <div className="p-4 md:p-5 space-y-4">
            {alertMessage && (
              <div
                className={`mb-4 p-3 rounded-lg ${
                  alertType === "error" ? "bg-red-100 text-red-700" : "bg-green-100 text-green-700"
                }`}
              >
                {alertMessage}
              </div>
            )}

            <h3 className="text-lg font-semibold text-gray-800 mb-4">
              Dokumenttyp auswählen
            </h3>

            <FileTypeButtons fileType={fileType} setFileType={setFileType} />


            <h3 className="text-lg font-semibold text-gray-800 mb-4 mt-6">
              Datei auswählen
            </h3>
            <div
              onClick={handleClick}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              className="flex flex-col items-center justify-center w-full h-48 border-2 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
            >
              <p className="text-sm text-gray-500">Datei hierher ziehen oder klicken</p>
              <p className="text-xs text-gray-500">Nur PDF- oder JPG-Dateien (max. 8 MB)</p>
              <input
                id="dropzone-file"
                type="file"
                className="hidden"
                ref={fileInputRef}
                onChange={handleFileChange}
              />
              {selectedFile && (
                <p className="mt-4 text-sm text-gray-600">
                  <strong>Ausgewählte Datei:</strong> {selectedFile.name}
                </p>
              )}
            </div>
          </div>

          {/* Footer */}
          <div className="flex items-center justify-between p-4 border-t">
            <button
              onClick={onClose}
              className="py-2 px-3 text-sm font-medium bg-gray-800 text-white rounded-lg hover:bg-gray-700"
            >
              Schließen
            </button>
            <button
              onClick={handleUpload}
              disabled={isUploading || !selectedFile}
              className={`py-2 px-3 text-sm font-medium bg-gray-800 text-white rounded-lg ${
                isUploading || !selectedFile
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:bg-gray-700"
              }`}
            >
              {isUploading ? "Lädt..." : "Hochladen"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadModal;