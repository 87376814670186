import React from "react";
import { Link } from "react-router-dom";

const PrescriptionsTable = ({
  isLoading,
  prescriptions,
  renderSkeleton,
  formatDate,
  handleDownload,
}) => {

  // Limit the number of displayed entries
  const limitedPrescriptions = prescriptions.slice(0, 4);

  return (

      <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden">
        <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-neutral-700">
          <div>
            <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200 inline-flex items-center gap-x-2">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"currentColor"} fill={"none"}>
                <path d="M14.9805 7.01556C14.9805 7.01556 15.4805 7.51556 15.9805 8.51556C15.9805 8.51556 17.5687 6.01556 18.9805 5.51556" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M9.99491 2.02134C7.49644 1.91556 5.56618 2.20338 5.56618 2.20338C4.34733 2.29053 2.01152 2.97385 2.01154 6.96454C2.01156 10.9213 1.9857 15.7993 2.01154 17.7439C2.01154 18.932 2.74716 21.7033 5.29332 21.8518C8.38816 22.0324 13.9628 22.0708 16.5205 21.8518C17.2052 21.8132 19.4847 21.2757 19.7732 18.7956C20.0721 16.2263 20.0126 14.4407 20.0126 14.0157" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M21.9999 7.01556C21.9999 9.77698 19.7592 12.0156 16.9951 12.0156C14.231 12.0156 11.9903 9.77698 11.9903 7.01556C11.9903 4.25414 14.231 2.01556 16.9951 2.01556C19.7592 2.01556 21.9999 4.25414 21.9999 7.01556Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                <path d="M6.98053 13.0156H10.9805" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                <path d="M6.98053 17.0156H14.9805" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
              </svg>
              Rezepte
            </h2>
            <p className="text-sm text-gray-600 dark:text-neutral-400">
              Zuletzt ausgestellte Verschreibungen
            </p>
          </div>

          <div>
            <div className="inline-flex gap-x-2">
              <Link
                to="/prescriptions"
                className="py-2 px-3 inline-flex items-center gap-x-2 text-xs font-medium rounded border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
              >
                Alle anzeigen
              </Link>
            </div>
          </div>
        </div>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="ps-6 py-3 text-start">
                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800">Datum</span>
              </th>
              <th scope="col" className="px-6 py-3 text-start">
                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800">Status</span>
              </th>
              <th scope="col" className="px-6 py-3 text-start">
                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800"></span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
              {isLoading ? (
                  renderSkeleton(3, 3)
                ) : limitedPrescriptions.length > 0 ? (
                  limitedPrescriptions.map((item, index) => (
                <tr key={index}>
                  <td className="px-6 py-2 align-middle whitespace-nowrap">
                    <span className="text-sm text-gray-600">{formatDate(item.name)}</span>
                  </td>
                  <td className="px-6 py-2 align-middle whitespace-nowrap">
                    <span
                      className="py-1 px-2 inline-flex items-center gap-x-2 text-xs font-medium rounded-full bg-gray-100 text-primary-800"
                    >
                      <div
                        className="flex items-center justify-center w-3 h-3 p-0.5 rounded-full bg-primary-800"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#ffffff"} fill={"none"}>
                            <path d="M5 14L8.5 17.5L19 6.5" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </div>
                      Versendet
                    </span>
                  </td>
                  <td className="px-6 py-2 align-middle whitespace-nowrap">
                    <button
                      onClick={() => handleDownload(item.path)}
                      className="py-1 px-2 align-middle inline-flex items-center gap-x-2 text-xs font-medium rounded border border-gray-200 bg-white text-gray-800 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                      >
                      <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 24 24" width={20} height={20} color={"currentColor"} fill={"none"}>
                          <path d="M12 14.5L12 4.5M12 14.5C11.2998 14.5 9.99153 12.5057 9.5 12M12 14.5C12.7002 14.5 14.0085 12.5057 14.5 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M20 16.5C20 18.982 19.482 19.5 17 19.5H7C4.518 19.5 4 18.982 4 16.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
            <tr>
              <td colSpan="3" className="text-center p-2">
                  <p className="text-sm text-gray-600 dark:text-neutral-400">Keine Rezepte gefunden.</p>
              </td>
            </tr>
            )}
          </tbody>
        </table>
      </div>

  );
};

export default PrescriptionsTable;